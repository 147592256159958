/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const VNotificationStatusEnum = {
    Unread: 'Unread',
    Read: 'Read',
    Dismissed: 'Dismissed',
    Archived: 'Archived'
} as const;
export type VNotificationStatusEnum = typeof VNotificationStatusEnum[keyof typeof VNotificationStatusEnum];


export function VNotificationStatusEnumFromJSON(json: any): VNotificationStatusEnum {
    return VNotificationStatusEnumFromJSONTyped(json, false);
}

export function VNotificationStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): VNotificationStatusEnum {
    return json as VNotificationStatusEnum;
}

export function VNotificationStatusEnumToJSON(value?: VNotificationStatusEnum | null): any {
    return value as any;
}

