/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VQueryUsersRequestParams
 */
export interface VQueryUsersRequestParams {
    /**
     * Clinic Id
     * @type {string}
     * @memberof VQueryUsersRequestParams
     */
    clinicId: string;
    /**
     * Flag to indicate this is display
     * @type {boolean}
     * @memberof VQueryUsersRequestParams
     */
    isDisplay?: boolean;
}

/**
 * Check if a given object implements the VQueryUsersRequestParams interface.
 */
export function instanceOfVQueryUsersRequestParams(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clinicId" in value;

    return isInstance;
}

export function VQueryUsersRequestParamsFromJSON(json: any): VQueryUsersRequestParams {
    return VQueryUsersRequestParamsFromJSONTyped(json, false);
}

export function VQueryUsersRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VQueryUsersRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clinicId': json['clinicId'],
        'isDisplay': !exists(json, 'isDisplay') ? undefined : json['isDisplay'],
    };
}

export function VQueryUsersRequestParamsToJSON(value?: VQueryUsersRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clinicId': value.clinicId,
        'isDisplay': value.isDisplay,
    };
}

