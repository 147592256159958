import React from 'react';

const MinimalLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <main
    style={{
      height: '100vh',
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
    }}
  >
    {children}
  </main>
);

export default MinimalLayout;
