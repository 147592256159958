/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  VAddCaddyRequestParams,
  VCaddy,
  VQueryCaddiesRequestParams,
  VUpdateCaddyRequestParams,
  VUpdateFirmwareRequestParams,
} from '../models/index';
import {
    VAddCaddyRequestParamsFromJSON,
    VAddCaddyRequestParamsToJSON,
    VCaddyFromJSON,
    VCaddyToJSON,
    VQueryCaddiesRequestParamsFromJSON,
    VQueryCaddiesRequestParamsToJSON,
    VUpdateCaddyRequestParamsFromJSON,
    VUpdateCaddyRequestParamsToJSON,
    VUpdateFirmwareRequestParamsFromJSON,
    VUpdateFirmwareRequestParamsToJSON,
} from '../models/index';

export interface AddCaddyRequest {
    vAddCaddyRequestParams: VAddCaddyRequestParams;
}

export interface GetCaddyRequest {
    caddyId: string;
}

export interface QueryCaddiesRequest {
    vQueryCaddiesRequestParams: VQueryCaddiesRequestParams;
}

export interface UpdateCaddyRequest {
    caddyId: string;
    vUpdateCaddyRequestParams: VUpdateCaddyRequestParams;
}

export interface UpdateFirmwareRequest {
    vUpdateFirmwareRequestParams: VUpdateFirmwareRequestParams;
}

/**
 * 
 */
export class CaddyApi extends runtime.BaseAPI {

    /**
     * Add a new caddy
     * Add a new caddy
     */
    async addCaddyRaw(requestParameters: AddCaddyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VCaddy>> {
        if (requestParameters.vAddCaddyRequestParams === null || requestParameters.vAddCaddyRequestParams === undefined) {
            throw new runtime.RequiredError('vAddCaddyRequestParams','Required parameter requestParameters.vAddCaddyRequestParams was null or undefined when calling addCaddy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/caddy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VAddCaddyRequestParamsToJSON(requestParameters.vAddCaddyRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VCaddyFromJSON(jsonValue));
    }

    /**
     * Add a new caddy
     * Add a new caddy
     */
    async addCaddy(requestParameters: AddCaddyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VCaddy> {
        const response = await this.addCaddyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of caddies
     * Get a list of caddies
     */
    async getCaddiesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VCaddy>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/caddy`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VCaddyFromJSON));
    }

    /**
     * Get a list of caddies
     * Get a list of caddies
     */
    async getCaddies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VCaddy>> {
        const response = await this.getCaddiesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a caddy by ID
     * Get a caddy
     */
    async getCaddyRaw(requestParameters: GetCaddyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VCaddy>> {
        if (requestParameters.caddyId === null || requestParameters.caddyId === undefined) {
            throw new runtime.RequiredError('caddyId','Required parameter requestParameters.caddyId was null or undefined when calling getCaddy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/caddy/{caddyId}`.replace(`{${"caddyId"}}`, encodeURIComponent(String(requestParameters.caddyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VCaddyFromJSON(jsonValue));
    }

    /**
     * Get a caddy by ID
     * Get a caddy
     */
    async getCaddy(requestParameters: GetCaddyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VCaddy> {
        const response = await this.getCaddyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Query caddies by all kinds of params
     * Query caddies
     */
    async queryCaddiesRaw(requestParameters: QueryCaddiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VCaddy>>> {
        if (requestParameters.vQueryCaddiesRequestParams === null || requestParameters.vQueryCaddiesRequestParams === undefined) {
            throw new runtime.RequiredError('vQueryCaddiesRequestParams','Required parameter requestParameters.vQueryCaddiesRequestParams was null or undefined when calling queryCaddies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/caddy/queryCaddies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VQueryCaddiesRequestParamsToJSON(requestParameters.vQueryCaddiesRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VCaddyFromJSON));
    }

    /**
     * Query caddies by all kinds of params
     * Query caddies
     */
    async queryCaddies(requestParameters: QueryCaddiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VCaddy>> {
        const response = await this.queryCaddiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a caddy by ID
     * Update a caddy
     */
    async updateCaddyRaw(requestParameters: UpdateCaddyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VCaddy>> {
        if (requestParameters.caddyId === null || requestParameters.caddyId === undefined) {
            throw new runtime.RequiredError('caddyId','Required parameter requestParameters.caddyId was null or undefined when calling updateCaddy.');
        }

        if (requestParameters.vUpdateCaddyRequestParams === null || requestParameters.vUpdateCaddyRequestParams === undefined) {
            throw new runtime.RequiredError('vUpdateCaddyRequestParams','Required parameter requestParameters.vUpdateCaddyRequestParams was null or undefined when calling updateCaddy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/caddy/{caddyId}`.replace(`{${"caddyId"}}`, encodeURIComponent(String(requestParameters.caddyId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VUpdateCaddyRequestParamsToJSON(requestParameters.vUpdateCaddyRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VCaddyFromJSON(jsonValue));
    }

    /**
     * Update a caddy by ID
     * Update a caddy
     */
    async updateCaddy(requestParameters: UpdateCaddyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VCaddy> {
        const response = await this.updateCaddyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a list of caddy serials and updates their firmware version
     * Update Caddy Firmware
     */
    async updateFirmwareRaw(requestParameters: UpdateFirmwareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.vUpdateFirmwareRequestParams === null || requestParameters.vUpdateFirmwareRequestParams === undefined) {
            throw new runtime.RequiredError('vUpdateFirmwareRequestParams','Required parameter requestParameters.vUpdateFirmwareRequestParams was null or undefined when calling updateFirmware.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/caddy/updateFirmware`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VUpdateFirmwareRequestParamsToJSON(requestParameters.vUpdateFirmwareRequestParams),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Takes a list of caddy serials and updates their firmware version
     * Update Caddy Firmware
     */
    async updateFirmware(requestParameters: UpdateFirmwareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateFirmwareRaw(requestParameters, initOverrides);
    }

}
