import React, { useCallback, useEffect } from 'react';
import {
  useId,
  Button,
  Card,
  Text,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  TabList,
  Tab,
  InfoLabel,
} from '@fluentui/react-components';
import { useCopyToClipboard } from 'usehooks-ts';
import { useAppSelector, useAppDispatch } from '../../hook';
import {
  fetchClinics,
  fetchUsers,
  changeClinic,
  changeClinicAddress,
  changeClinicName,
  selectUser,
  unselectUser,
  reset,
  addClinic,
  updateClinic,
  generateToken,
} from './_slice';
import { Result } from '../../common/models';
import Layout1 from '../../layouts/layout1';
import { VUserClinicRoleEnum } from '../../services';
import SelectBar from '../../components/selectBar';
import SelectMultipleEntities from '../../components/selectMultipleEntities';
import { mapVUsersToEntity } from '../../common/helper';
import Breadcrumbs from '../../components/breadcrumbs';
import LabelInput from '../../components/labelInput';
import appConfig from '../../config.app';
import IconInfo from '../../components/iconInfo';

const EnumManageClinicType = {
  UpdateDetails: 'Details',
  GenerateToken: 'Access Token',
} as const;
type EnumManageClinicType = typeof EnumManageClinicType[keyof typeof EnumManageClinicType];

interface IManageClinicProps {
  isUpdate: boolean;
}

const ClinicManage: React.FC<IManageClinicProps> = ({ isUpdate }) => {
  const clinicState = useAppSelector((state) => state.clinicManage);
  const dispatch = useAppDispatch();
  const toasterId = useId('toaster');
  const { dispatchToast } = useToastController(toasterId);
  const [manageType, setManageType] = React.useState<
    EnumManageClinicType
  >(EnumManageClinicType.UpdateDetails);
  const [copiedValue, copy] = useCopyToClipboard();

  const refreshClinics = useCallback(() => {
    dispatch(reset());
    dispatch(fetchClinics());
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    refreshClinics();
  }, [refreshClinics]);

  const onSelectSiteAdmin = (userId: string) => {
    dispatch(selectUser({ role: VUserClinicRoleEnum.SiteAdmin, userId }));
  };

  const onUnselectSiteAdmin = (userId: string) => {
    dispatch(unselectUser({ role: VUserClinicRoleEnum.SiteAdmin, userId }));
  };

  const onSelectUser = (userId: string) => {
    dispatch(selectUser({ role: VUserClinicRoleEnum.User, userId }));
  };

  const onUnselectUser = (userId: string) => {
    dispatch(unselectUser({ role: VUserClinicRoleEnum.User, userId }));
  };

  const showToast = (result: Result) => {
    dispatchToast(
      <Toast>
        <ToastTitle>{ result.message }</ToastTitle>
      </Toast>,
      {
        timeout: appConfig.toastTimeoutSeconds * 1000,
        intent: result.result ? 'success' : 'warning',
      },
    );
  };

  const onSave = async () => {
    if (isUpdate) { // For updating
      const dispatchResult = await dispatch(updateClinic());
      if (dispatchResult) showToast(dispatchResult.payload as Result);
      refreshClinics();
    } else { // For adding
      const dispatchResult = await dispatch(addClinic());
      if (dispatchResult) showToast(dispatchResult.payload as Result);
      dispatch(reset());
    }
  };

  const onGenerateToken = async () => {
    const dispatchResult = await dispatch(generateToken());
    if (dispatchResult) showToast(dispatchResult.payload as Result);
    refreshClinics();
  };

  const manageContent = () => {
    if (manageType === EnumManageClinicType.UpdateDetails) {
      return <>
        <Card style={{
          padding: 20,
        }}>
          <LabelInput
            id='name'
            label='Name *'
            value={clinicState.clinicInput.name}
            placeholder={appConfig.message.NAME_PLACEHOLDER.replace('{0}', appConfig.nameMaxLen.toString())}
            onChange={(newValue) => dispatch(changeClinicName(newValue))}
            disabled={isUpdate && !clinicState.selectedClinicId}
            maxLength={appConfig.nameMaxLen}
          />
          <LabelInput
            id='address'
            label='Location *'
            value={clinicState.clinicInput.address}
            placeholder={appConfig.message.ADDR_PLACEHOLDER.replace('{0}', appConfig.addrMaxLen.toString())}
            onChange={(newValue) => dispatch(changeClinicAddress(newValue))}
            disabled={isUpdate && !clinicState.selectedClinicId}
            maxLength={appConfig.addrMaxLen}
          />
        </Card>
        <SelectMultipleEntities
          title='Site Admins'
          entities={mapVUsersToEntity(
            clinicState.users,
            [...clinicState.selectedSiteAdmins, ...clinicState.selectedUsers],
          )}
          selectedEntities={mapVUsersToEntity(clinicState.selectedSiteAdmins, [])}
          disabled={isUpdate && !clinicState.selectedClinicId}
          onSelectEntity={onSelectSiteAdmin}
          onUnselectEntity={onUnselectSiteAdmin}
          placeholder='Select a user for this role'
          tooltip='Site Admins have full access to the clinic and can manage associated tanks and users.'
        />
        <SelectMultipleEntities
          title='Users'
          entities={mapVUsersToEntity(
            clinicState.users,
            [...clinicState.selectedSiteAdmins, ...clinicState.selectedUsers],
          )}
          selectedEntities={mapVUsersToEntity(clinicState.selectedUsers, [])}
          disabled={isUpdate && !clinicState.selectedClinicId}
          onSelectEntity={onSelectUser}
          onUnselectEntity={onUnselectUser}
          placeholder='Select a user for this role'
          tooltip='Users have limited access to the clinic, like viewing tanks, caddies, readings and notifications.'
        />
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
          <div style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 6,
          }}>
            <InfoLabel info={appConfig.message.TIP_MANAGE_CLINIC}></InfoLabel>
          </div>
          <div>
            <Button
              onClick={onSave}
              appearance="primary"
              disabled={!(( // For updating
                clinicState.selectedClinicId
                && clinicState.clinicInput.name
                && clinicState.clinicInput.address
                && clinicState.savable
              ) || ( // For adding
                !isUpdate
                && clinicState.clinicInput.name
                && clinicState.clinicInput.address
              )
              )}
            >
              Save
            </Button>
          </div>
        </div>
      </>;
    }

    return <>
      <Card style={{
        padding: 20,
      }}>
        <LabelInput
          id='accessToken'
          label='Access token'
          value={clinicState.clinicInput.accessToken}
          disabled={isUpdate && !clinicState.selectedClinicId}
          readonly={true}
        />
      </Card>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: 20,
      }}>
        <div style={{ flex: 1 }}>
          <IconInfo info={appConfig.message.CLINIC_ACCESS_TOKEN} />
        </div>
        <Button
          onClick={onGenerateToken}
          appearance="primary"
          disabled={!clinicState.selectedClinicId}
        >
          Generate token
        </Button>
        {
          clinicState.clinicInput.accessToken
            && <Button
            onClick={() => copy(clinicState.clinicInput.accessToken)}
            disabled={!clinicState.selectedClinicId}
          >
            { copiedValue === clinicState.clinicInput.accessToken ? 'Copied' : 'Copy' }
          </Button>
        }
      </div>
    </>;
  };

  return (
    <Layout1
      breadcrumbsSlot={
        <Breadcrumbs
          breadcrumbs={[{ label: 'Clinics', path: '/clinics' }]}
          title={isUpdate ? 'Manage Clinics' : 'Add Clinic'}
        />
      }
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
        gap: 20,
      }}>
        <div>
          <Text weight="bold" size={400}>
            { isUpdate ? 'Manage Clinics' : 'Add Clinic' }
          </Text>
        </div>
        {
          isUpdate && <>
            <SelectBar
              title='Clinic *'
              placeholder='Select a clinic to update'
              options={clinicState.clinics.map((x) => ({ id: x.id, label: x.name }))}
              selectedId={clinicState.selectedClinicId}
              onChange={(id) => dispatch(changeClinic(id))}
              noneOption='No clinic selected'
            />
            <TabList
              selectedValue={manageType}
              onTabSelect={(_, d) => setManageType(d.value as EnumManageClinicType)}
            >
              <Tab value={EnumManageClinicType.UpdateDetails}>
                <Text size={400} weight='bold'>{EnumManageClinicType.UpdateDetails}</Text>
              </Tab>
              <Tab value={EnumManageClinicType.GenerateToken} disabled>
                <Text size={400} weight='bold'>{EnumManageClinicType.GenerateToken}</Text>
              </Tab>
            </TabList>
          </>
        }
        { manageContent() }
        <div>
          <Toaster toasterId={toasterId} />
        </div>
      </div>
    </Layout1>
  );
};

export default ClinicManage;
