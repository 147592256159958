/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  VAddTankRequestParams,
  VQueryTanksRequestParams,
  VTank,
  VUpdateTankRequestParams,
} from '../models/index';
import {
    VAddTankRequestParamsFromJSON,
    VAddTankRequestParamsToJSON,
    VQueryTanksRequestParamsFromJSON,
    VQueryTanksRequestParamsToJSON,
    VTankFromJSON,
    VTankToJSON,
    VUpdateTankRequestParamsFromJSON,
    VUpdateTankRequestParamsToJSON,
} from '../models/index';

export interface AddTankRequest {
    vAddTankRequestParams: VAddTankRequestParams;
}

export interface GetTankRequest {
    tankId: string;
}

export interface QueryTanksRequest {
    vQueryTanksRequestParams: VQueryTanksRequestParams;
}

export interface UpdateTankRequest {
    tankId: string;
    vUpdateTankRequestParams: VUpdateTankRequestParams;
}

/**
 * 
 */
export class TankApi extends runtime.BaseAPI {

    /**
     * Add a new tank
     * Add a new tank
     */
    async addTankRaw(requestParameters: AddTankRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VTank>> {
        if (requestParameters.vAddTankRequestParams === null || requestParameters.vAddTankRequestParams === undefined) {
            throw new runtime.RequiredError('vAddTankRequestParams','Required parameter requestParameters.vAddTankRequestParams was null or undefined when calling addTank.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tank`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VAddTankRequestParamsToJSON(requestParameters.vAddTankRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VTankFromJSON(jsonValue));
    }

    /**
     * Add a new tank
     * Add a new tank
     */
    async addTank(requestParameters: AddTankRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VTank> {
        const response = await this.addTankRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a tank by ID
     * Get a tank
     */
    async getTankRaw(requestParameters: GetTankRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VTank>> {
        if (requestParameters.tankId === null || requestParameters.tankId === undefined) {
            throw new runtime.RequiredError('tankId','Required parameter requestParameters.tankId was null or undefined when calling getTank.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tank/{tankId}`.replace(`{${"tankId"}}`, encodeURIComponent(String(requestParameters.tankId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VTankFromJSON(jsonValue));
    }

    /**
     * Get a tank by ID
     * Get a tank
     */
    async getTank(requestParameters: GetTankRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VTank> {
        const response = await this.getTankRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Query tanks by all kinds of params
     * Query tanks
     */
    async queryTanksRaw(requestParameters: QueryTanksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VTank>>> {
        if (requestParameters.vQueryTanksRequestParams === null || requestParameters.vQueryTanksRequestParams === undefined) {
            throw new runtime.RequiredError('vQueryTanksRequestParams','Required parameter requestParameters.vQueryTanksRequestParams was null or undefined when calling queryTanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tank/queryTanks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VQueryTanksRequestParamsToJSON(requestParameters.vQueryTanksRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VTankFromJSON));
    }

    /**
     * Query tanks by all kinds of params
     * Query tanks
     */
    async queryTanks(requestParameters: QueryTanksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VTank>> {
        const response = await this.queryTanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a tank by ID
     * Update a tank
     */
    async updateTankRaw(requestParameters: UpdateTankRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VTank>> {
        if (requestParameters.tankId === null || requestParameters.tankId === undefined) {
            throw new runtime.RequiredError('tankId','Required parameter requestParameters.tankId was null or undefined when calling updateTank.');
        }

        if (requestParameters.vUpdateTankRequestParams === null || requestParameters.vUpdateTankRequestParams === undefined) {
            throw new runtime.RequiredError('vUpdateTankRequestParams','Required parameter requestParameters.vUpdateTankRequestParams was null or undefined when calling updateTank.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tank/{tankId}`.replace(`{${"tankId"}}`, encodeURIComponent(String(requestParameters.tankId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VUpdateTankRequestParamsToJSON(requestParameters.vUpdateTankRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VTankFromJSON(jsonValue));
    }

    /**
     * Update a tank by ID
     * Update a tank
     */
    async updateTank(requestParameters: UpdateTankRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VTank> {
        const response = await this.updateTankRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
