import { IBetterBadgeProps } from '../components/betterBadge';
import { IStatWidgetProps } from '../components/statWidget';
import appConfig from '../config.app';
import {
  VCaddy,
  VCurrentUser,
  VTank,
  VUser,
} from '../services';
import {
  IStatusWidget,
  IWidgetName,
} from './models';

export function assertThrowError(isCorrect: boolean, message: string) {
  if (!isCorrect) throw new Error(message);
}

/**
 * Takes a message, possibly of the format: "Validation failed: something:
 * Path `weightLossCriticalRate` (-100) is less than minimum allowed value (0)."
 * and reformats it or does not modify it if that format is not the same.
 */
export function formatErrorMsg(errorMsg?: string | null): string {
  if (!errorMsg) return '';
  const validationPrefix = 'Validation failed:';
  if (errorMsg.startsWith(validationPrefix)) {
    const startIndex = validationPrefix.length;
    const endIndex = errorMsg.indexOf(':', startIndex);
    if (endIndex !== -1) {
      const fieldError = errorMsg.substring(endIndex + 1).trim();
      const quotedFieldName = fieldError.split(' ')[1];
      const fieldName = quotedFieldName.split('`')[1];
      if (fieldName) {
        const formattedFieldName = fieldName
          .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space between camelCase
          .toLowerCase(); // Convert to lowercase
        const finalFieldName = formattedFieldName.charAt(0).toUpperCase()
          + formattedFieldName.slice(1);
        return `${finalFieldName} ${fieldError}`.replace(`Path ${quotedFieldName} `, '');
      }
      return fieldError;
    }
  }
  return errorMsg;
}

export function toDisplayName(currentUser: VCurrentUser | null) {
  let displayName: string | undefined;
  if (currentUser) {
    displayName = currentUser.firstName
      ? `${currentUser.firstName} ${currentUser.surname}`
      : currentUser.name;
  }
  return displayName ?? 'Unknown';
}

export function mapVUsersToEntity(users: VUser[], excludeUsers: VUser[]) {
  const excludeUserIds: string[] = excludeUsers.map((x) => x.id);
  const retUsers: { id: string, label: string }[] = [];
  users.forEach((x) => {
    if (!excludeUserIds.includes(x.id)) {
      retUsers.push({
        id: x.id,
        label: x.firstName && x.surname
          ? `${x.firstName} ${x.surname}` // A normal user
          : x.name, // A display
      });
    }
  });
  return retUsers;
}

export function mapToWidgetProps(
  tankOrCaddy: VTank | VCaddy,
  widgetName: IWidgetName,
): IStatWidgetProps | null {
  if (!(widgetName in appConfig.statusWidgets)) {
    // Not found the status widget configuration
    return null;
  }
  const statusWidget = appConfig.statusWidgets[widgetName] as IStatusWidget;
  if (!statusWidget) {
    // The status widget is invalid
    return null;
  }
  return statusWidget.check(tankOrCaddy);
}

export function mapToWidgetPropsList(tank: VTank, caddy?: VCaddy | null) {
  const items: IStatWidgetProps[] = [];
  // Tank
  const weight = mapToWidgetProps(tank, 'weight');
  if (weight) items.push(weight);
  const evaporationRate = mapToWidgetProps(tank, 'evaporationRate');
  if (evaporationRate) items.push(evaporationRate);
  const lastReadingTime = mapToWidgetProps(tank, 'lastReadingTime');
  if (lastReadingTime) items.push(lastReadingTime);
  // Caddy
  if (caddy) {
    const batteryWidgetProps = mapToWidgetProps(caddy, 'batteryVoltage');
    if (batteryWidgetProps) items.push(batteryWidgetProps);

    const temperatureWidgetProps = mapToWidgetProps(caddy, 'temperature');
    if (temperatureWidgetProps) items.push(temperatureWidgetProps);
  }
  return items;
}

export function mapToBadgePropsList(tank: VTank): IBetterBadgeProps[] {
  const propsList: IBetterBadgeProps[] = [];
  if (!tank.active) {
    propsList.push({
      label: appConfig.statusCategories.inactive.desc,
      icon: appConfig.statusCategories.inactive.icon,
      color: appConfig.statusCategories.inactive.color,
      extended: true,
    });
    return propsList;
  }

  if (!tank.isAlarming && !tank.isWarning) {
    propsList.push({
      label: appConfig.statusCategories.normal.desc,
      icon: appConfig.statusCategories.normal.icon,
      color: appConfig.statusCategories.normal.color,
    });
  }
  if (tank.isAlarming) {
    propsList.push({
      label: appConfig.statusCategories.alarming.desc,
      icon: appConfig.statusCategories.alarming.icon,
      color: appConfig.statusCategories.alarming.color,
    });
  }
  if (tank.isWarning) {
    propsList.push({
      label: appConfig.statusCategories.warning.desc,
      icon: appConfig.statusCategories.warning.icon,
      color: appConfig.statusCategories.warning.color,
    });
  }
  if (tank.isMuted) {
    propsList.push({
      label: appConfig.statusCategories.muted.desc,
      icon: appConfig.statusCategories.muted.icon,
      color: appConfig.statusCategories.muted.color,
    });
  }
  return propsList;
}
