/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VQueryCaddiesRequestParams
 */
export interface VQueryCaddiesRequestParams {
    /**
     * Clinic Id
     * @type {string}
     * @memberof VQueryCaddiesRequestParams
     */
    clinicId: string;
}

/**
 * Check if a given object implements the VQueryCaddiesRequestParams interface.
 */
export function instanceOfVQueryCaddiesRequestParams(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clinicId" in value;

    return isInstance;
}

export function VQueryCaddiesRequestParamsFromJSON(json: any): VQueryCaddiesRequestParams {
    return VQueryCaddiesRequestParamsFromJSONTyped(json, false);
}

export function VQueryCaddiesRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VQueryCaddiesRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clinicId': json['clinicId'],
    };
}

export function VQueryCaddiesRequestParamsToJSON(value?: VQueryCaddiesRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clinicId': value.clinicId,
    };
}

