/* tslint:disable */
/* eslint-disable */
/* All in the `core` folder excpet for `index.ts` was generated by openapi-generate-cli */
/* Do not try to modify them unless you have no choice and know how to do. */
import appConfig from '../config.app';
import { Configuration,
  AuthApi,
  UserApi,
  TankApi,
  CaddyApi,
  NotificationApi,
  ReadingApi,
  ResponseError,
  ClinicApi,
  VCurrentUser,
  FileApi,
  VUserClinicRoleEnum,
} from './core/v1';
import { assertThrowError } from '../common/helper';

// Currently, we use decorator to enhande original. Middleware may be another
// way, but I am worried about the middleware solution couldn't solve try-catched
// errors properly, so I am using decorator.
function decorateApi(_target: any, _key: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value;

  // To catch exceptions in the function below, need to `await` the API original
  // async functions, like AuthApi.login etc.
  descriptor.value = async function (...args: any[]) {
    try {
      const result = await originalMethod.apply(this, args);
      return result;
    } catch (error) {
      if (error instanceof ResponseError) {
        if (error.response.status === appConfig.httpCode.UNAUTHORIZED) {
          // Retry
          return apiServices.refreshTokenAndRepeatRequest(() => {
            return originalMethod.apply(this, args);
          });
        } else {
          // Throw error from backend
          const errMsg = (await error.response.json()).message;
          console.error(errMsg);
          throw Error(errMsg);
        }
      } else {
        // Throw unknown error
        console.error(error);
        throw Error(appConfig.errMsg.UNEXPECTED_API_ERROR);
      }
    }
  };
  return descriptor;
}

class ApiServices {
  private m_configuration: Configuration;
  private m_authApi: AuthApi;
  private m_userApi: UserApi;
  private m_clinicApi: ClinicApi;
  private m_tankApi: TankApi;
  private m_caddyApi: CaddyApi;
  private m_readingApi: ReadingApi;
  private m_notificationApi: NotificationApi;
  private m_fileApi:FileApi;
  private m_refreshPromise!: Promise<VCurrentUser>;
  private m_isRefreshing: boolean = false;

  constructor() {
    // This is a singleton and only initialize one time in the whole app
    console.log('Initialize apiServices');
    
    // Decorate functions for each service
    this._decorate(AuthApi);
    this._decorate(UserApi);
    this._decorate(ClinicApi);
    this._decorate(TankApi);
    this._decorate(CaddyApi);
    this._decorate(ReadingApi);
    this._decorate(NotificationApi);
    this._decorate(FileApi);

    // Set configuration for all these API services
    this.m_configuration = new Configuration({
      basePath: process.env.REACT_APP_API_ADDRESS,
      accessToken: () => this._getAccessToken(),
      credentials: 'include',
    });

    // Create instances for these API services
    this.m_authApi = new AuthApi(this.m_configuration);
    this.m_userApi = new UserApi(this.m_configuration);
    this.m_clinicApi = new ClinicApi(this.m_configuration);
    this.m_tankApi = new TankApi(this.m_configuration);
    this.m_caddyApi = new CaddyApi(this.m_configuration);
    this.m_readingApi = new ReadingApi(this.m_configuration);
    this.m_notificationApi = new NotificationApi(this.m_configuration);
    this.m_fileApi = new FileApi(this.m_configuration);
  }

  public getAuthApi() {
    return this.m_authApi;
  }

  public getUserApi() {
    return this.m_userApi;
  }

  public getClinicApi() {
    return this.m_clinicApi;
  }

  public getTankApi() {
    return this.m_tankApi;
  }

  public getCaddyApi() {
    return this.m_caddyApi;
  }

  public getReadingApi() {
    return this.m_readingApi;
  }

  public getNotificationApi() {
    return this.m_notificationApi;
  }

  public getFileApi() {
    return this.m_fileApi;
  }

  public async login(username: string, password: string) {
    assertThrowError(!!(username && password), appConfig.errMsg.ERR_USERNAME_PW);
    try {
      const currentUser = await this.m_authApi.login({
        vLoginRequestParams: {username, password}
      });
      this._setCurrentUser(currentUser);
    } catch (error) {
      if (error instanceof ResponseError) {
        throw Error((await error.response.json()).message);
      } else {
        throw Error(appConfig.errMsg.UNEXPECTED_API_ERROR);
      }
    }
  }

  public logout(returnToHome: boolean = true) {
    localStorage.removeItem('currentUser');
    if (returnToHome)
      window.location.href = '/';
    else
      window.location.reload();
  }

  public getCurrentUser(): VCurrentUser | null {
    const currentUserJson = localStorage.getItem('currentUser');
    if (!currentUserJson) {
      return null;
    }

    try {
      return JSON.parse(currentUserJson);
    } catch (error) {
      this.logout();
    }
    return null;
  }

  public isCloudRuntime(): boolean {
    return this.getCurrentUser()?.isCloudRuntime ?? false;
  }

  public getServerLabel(): string {
    return this.getCurrentUser()?.serverLabel ?? '';
  }

  public getApiVersion(): string {
    return this.getCurrentUser()?.apiVersion ?? '';
  }

  public isSuperAdmin(): boolean {
    return this.getCurrentUser()?.isSuperAdmin ?? false;
  }

  public isSiteAdmin(): boolean {
    const user = this.getCurrentUser();
    const result = user?.clinics.find((x) => x.role === VUserClinicRoleEnum.SiteAdmin);
    return !!result;
  }

  public isUser(): boolean {
    const user = this.getCurrentUser();
    const result = user?.clinics.find((x) => x.role === VUserClinicRoleEnum.User);
    return !!result;
  }

  public getUserRoles(): string {
    const user = this.getCurrentUser();
    const roles: string[] = [];
    if (user?.isSuperAdmin) roles.push(VUserClinicRoleEnum.SuperAdmin);
    if (user?.isDisplay) roles.push(VUserClinicRoleEnum.Display);
    if (user?.clinics.find((x) => x.role === VUserClinicRoleEnum.SiteAdmin))
      roles.push(VUserClinicRoleEnum.SiteAdmin);
    if (user?.clinics.find((x) => x.role === VUserClinicRoleEnum.User))
      roles.push(VUserClinicRoleEnum.User);
    return roles.length ? roles.join(' | ') : 'No assigned clinics';
  }

  // The most important thing here is to avoid repeating refreshing tokens.
  // If multiple requests fail at the same time because of the expiration of
  // accessToken, we cannot let each of them refresh token at the same time.
  // We can only let one do refresh and others wait for the refresh result and
  // then continue do request, after getting the new accessToken.
  public async refreshTokenAndRepeatRequest(requestCallback: any): Promise<any> {
    // If being refreshing tokens, don't repeat refreshing
    if (!this.m_isRefreshing) {
      this.m_isRefreshing = true;
      const refreshToken = this._getRefreshToken();
      if (!refreshToken) throw Error(appConfig.errMsg.ERR_REFRESH_TOKEN);
      this.m_refreshPromise = this.m_authApi.refresh({ vRefreshRequestParams: { refreshToken } });
    }

    // Wait for the refresh result until getting it.
    // TODO: Once getting the new tokens, in the current logic it will set multiple
    // time for the same tokens, but this is good for logic clarity, but I can optimize
    // it once I got a satisfied idea. 
    try {
      const currentUser: VCurrentUser = await this.m_refreshPromise;
      this.m_isRefreshing = false;
      this._setCurrentUser(currentUser);
    } catch (error) {
      throw Error(appConfig.errMsg.ERR_REFRESH_TOKEN);
    }

    // Repeat the original request one time
    return requestCallback();
  }

  private _setCurrentUser(currentUser: VCurrentUser) {
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  // The function is called by each API request, so when updating localStorage,
  // each api can get the updated token.
  private _getAccessToken(): string {
    return this.getCurrentUser()?.accessToken ?? '';
  }

  private _getRefreshToken(): string {
    return this.getCurrentUser()?.refreshToken ?? '';
  }

  // Filter some functions I don't want to decorate
  // IMPORTANT: Filter `refresh` to avoid circular callings
  private _needDecorate(funcName: string) {
    return (
      funcName !== 'constructor' &&
      funcName.slice(-3) !== 'Raw' &&
      funcName !== 'login' &&
      funcName !== 'refresh'
    );
  }

  // Decorate classes of APIs, like AuthApi, UserApi
  private _decorate(apiClass: any) {
    const funcNames = Object.getOwnPropertyNames(apiClass.prototype);
    funcNames.filter(this._needDecorate).forEach((funcName) => {
      const descriptor = Object.getOwnPropertyDescriptor(apiClass.prototype, funcName);
      if (descriptor && typeof descriptor.value === 'function') {
        Object.defineProperty(
          apiClass.prototype,
          funcName,
          decorateApi(apiClass.prototype, funcName, descriptor),
        );
      }
    });
  }
}

const apiServices = new ApiServices();

export default apiServices;
export * from './core/v1';
