import * as React from 'react';
import { Text } from '@fluentui/react-components';
import BetterIcon from './betterIcon';

export interface IIconInfoProps {
  info: string;
  size?: 'small' | 'medium' | 'large';
}

const IconInfo: React.FC<IIconInfoProps> = ({
  info,
  size,
}) => {
  let iconSize = '18px';
  let fontSize: 200 | 300 | 400 = 300;
  if (size === 'small') {
    iconSize = '14px';
    fontSize = 200;
  } else if (size === 'large') {
    iconSize = '24px';
    fontSize = 400;
  }
  return (
    <div style={{ display: 'flex', gap: 4 }}>
      <BetterIcon name='Info24Regular' style={{ height: iconSize, width: iconSize }} />
      <Text size={fontSize}>{info}</Text>
    </div>
  );
};

export default IconInfo;
