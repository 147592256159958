import React from 'react';
import { DateTime } from 'luxon';
import {
  Card, CardHeader, Divider,
} from '@fluentui/react-components';
import { VNotification, VNotificationStatusEnum } from '../../../services';
import appConfig from '../../../config.app';
import NotificationCard from './notificationCard';
import Message from '../../../components/message';

function areSameDay(date1: DateTime, date2: DateTime) {
  return date1.hasSame(date2, 'day')
    && date1.hasSame(date2, 'month')
    && date1.hasSame(date2, 'year');
}

interface NotificationListProps {
  onDismiss: (notificationId: string) => void;
  onSwitchStatus: (notificationId: string, status: VNotificationStatusEnum) => void;
  notifications: VNotification[];
  style: React.CSSProperties | undefined;
}

const NotificationList: React.FC<NotificationListProps> = ({
  onDismiss,
  onSwitchStatus,
  notifications,
  style,
}) => {
  let previousTime: DateTime = DateTime.local();

  return <Card style={{
    ...style,
    height: '100%',
    padding: 'unset',
  }}>
    {
      notifications.length
        ? (
          <div style={{
            overflow: 'hidden auto',
            padding: 20,
          }}>
            {
              notifications.map((notification, index) => {
                let isSameDay = false;
                let displayDate = 'N/A';
                isSameDay = areSameDay(
                  DateTime.fromISO(notification.time),
                  previousTime,
                );
                if (isSameDay && index === 0) {
                  // If the first notification is the same as the
                  // initial previousTime value then it is today
                  isSameDay = false;
                  displayDate = 'Today';
                } else {
                  displayDate = DateTime.fromISO(notification.time).toFormat('EEEE, LLLL d') || 'N/A';
                }
                previousTime = DateTime.fromISO(notification.time);
                return <div key={notification.id}>
                  {
                    !isSameDay
                      ? <CardHeader
                        style={{ marginBottom: 10 }}
                        header={
                          <Divider>{displayDate}</Divider>
                        }
                      />
                      : <></>
                  }
                  <NotificationCard
                    key={notification.id}
                    notification={notification}
                    onSwitchStatus={
                      () => onSwitchStatus(
                        notification.id,
                        notification.status === VNotificationStatusEnum.Read
                          ? VNotificationStatusEnum.Unread : VNotificationStatusEnum.Read,
                      )
                    }
                    onDismiss={ () => onDismiss(notification.id) }
                    isToast={false}
                  />
                </div>;
              })
            }
          </div>
        )
        : (
          <Message message={appConfig.errMsg.NOT_FOUND_NOTIFICATIONS} status='info' />
        )
    }
  </Card>;
};

export default NotificationList;
