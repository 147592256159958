import {
  IChartCategory,
  IEventCategory,
  ILevel,
  IStatusCategory,
  IStatusWidget,
} from './common/models';
import { lightThemeColors } from './common/themes';
import { strDateToBriefLocal } from './common/utils';
import { BetterIconName } from './components/betterIcon';

/**
 * The configuration object is used everywhere in this app
 */
const appConfig = {
  version: '1.3.0',
  pollIntervalSeconds: 30,
  toastTimeoutSeconds: 8,
  nameMaxLen: 64,
  serialMaxLen: 64,
  versionMaxLen: 64,
  addrMaxLen: 100,
  noDataSign: 'N/A',
  locale: 'en-NZ',
  precisionLow: 1,
  precisionMid: 2,
  precisionHigh: 3,
  weightColor: 'rgba(5, 155, 255, 1)',
  evaporationRateColor: 'rgba(76, 192, 192, 1)',

  statusCategories: {} as { [key: string]: IStatusCategory },
  eventCategories: {} as { [key: string]: IEventCategory },
  statusWidgets: {} as { [key: string]: IStatusWidget },

  chartThresholdColor: '#ff9020',
  chartApexResetThresholdColor: '#cccccc',
  chartCategories: [
    {
      name: 'Tank Weight', label: 'Tank Weight (kg)', color: '#6c92a1', icon: 'Scales24Regular',
    },
    {
      name: 'Evaporation Rate', label: 'Evaporation Rate (kg/h)', color: '#4bc0c0', icon: 'EraserTool24Filled',
    },
    {
      name: 'Ambient Temperature', label: 'Ambient Temperature (°C)', color: '#059bff', icon: 'Temperature24Regular',
    },
  ] as IChartCategory[],

  signalLevels: [
    {
      level: 0,
      icon: 'WifiWarning24Regular',
      color: lightThemeColors.normal,
    },
    {
      level: 0.1,
      icon: 'Wifi424Regular',
      color: lightThemeColors.normal,
    },
    {
      level: 0.4,
      icon: 'Wifi324Regular',
      color: lightThemeColors.normal,
    },
    {
      level: 0.7,
      icon: 'Wifi224Regular',
      color: lightThemeColors.normal,
    },
    {
      level: 1,
      icon: 'Wifi124Regular',
      color: lightThemeColors.normal,
    },
  ] as ILevel[],

  // HTTP status codes shared with backend
  // https://en.wikipedia.org/wiki/List_of_HTTP_status_codes
  httpCode: {
    SUCCESS: 200,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 403,
    INTERNAL_ERROR: 500,
  },

  label: {
    NOT_SELECTED: 'None',
  },

  // Normal messages
  message: {
    CLINIC_ADDED: 'Added clinic successfully',
    CLINIC_UPDATED: 'Updated clinic successfully',
    CLINIC_TOKEN_GENERATED: 'Generated access token successfully',
    CLINIC_ACCESS_TOKEN: 'Access token is utilized to authorize clinics\' access to APIs. It is essential to update the corresponding environment variable on the onsite server.',
    CADDY_ADDED: 'Added caddy successfully',
    CADDY_UPDATED: 'Updated caddy successfully',
    TANK_ADDED: 'Added tank successfully',
    TANK_UPDATED: 'Updated tank successfully',
    CONF_SYNCING: 'Syncing settings to caddy...',
    NAME_PLACEHOLDER: 'Less than {0} characters',
    ADDR_PLACEHOLDER: 'Less than {0} characters such as street, suburb and city',
    SERIAL_PLACEHOLDER: 'Less than {0} characters and globally unique',
    WEIGHT_PLACEHOLDER: 'Greater than zero',
    RATE_PLACEHOLDER: 'Greater than zero (5 dp)',
    MIN_RATE_PLACEHOLDER: 'Number (5 dp)',
    TIP_MANAGE_CLINIC: 'Super admins can add and update any clinic, while site admins can update only the clinics associated with them. Others cannot add and update any clinic.',
    TIP_MANAGE_CADDY: 'Super admins can add and update any caddy, while site admins can update only the caddies in the clinics associated with them. Others cannot add and update any caddy.',
    TIP_MANAGE_TANK: 'Super admins can add and update any tank, while site admins can update only the tanks in the clinics associated with them. Others cannot add and update any tank.',
  },

  // Error messages
  errMsg: {
    UNEXPECTED_ERROR: 'Unexpected error',
    UNEXPECTED_API_ERROR: 'Unexpected API error',
    ERR_REFRESH_TOKEN: 'Failed to refresh token',
    ERR_USERNAME_PW: 'Input username and password',
    INVALID_PARAMS: 'Invalid parameters',
    NOT_FOUND_TANK: 'Tank not found',
    NOT_FOUND_TANKS: 'Tanks not found',
    NOT_FOUND_NOTIFICATIONS: 'Notifications not found',
    NOT_FOUND_CLINICS: 'Clinics not found',
    NOT_FOUND_FILES: 'Files not found',
    NOT_RECEIVED_READINGS: 'No readings received from the tank',
  },
};

appConfig.statusCategories = {
  normal: {
    name: 'Normal',
    icon: 'CheckmarkCircle24Filled',
    color: lightThemeColors.normal,
    desc: 'Normal',
    check: (tank: {isAlarming?: boolean, isWarning?: boolean, active?: boolean}) => {
      if (!tank.isAlarming && !tank.isWarning && tank.active) return true;
      return false;
    },
  },
  alarming: {
    name: 'Alarming',
    icon: 'ErrorCircle24Filled',
    color: lightThemeColors.alarm,
    desc: 'Alarming',
    check: (tank: {isAlarming?: boolean, active?: boolean}) => {
      if (tank.isAlarming && tank.active) return true;
      return false;
    },
  },
  warning: {
    name: 'Warning',
    icon: 'Warning24Filled',
    color: lightThemeColors.warning,
    desc: 'Warning',
    check: (tank: {isWarning?: boolean, active?: boolean}) => {
      if (tank.isWarning && tank.active) return true;
      return false;
    },
  },
  muted: {
    name: 'Muted',
    icon: 'SpeakerMute24Filled',
    color: lightThemeColors.service,
    desc: 'Muted',
    check: (tank: {isMuted?: boolean, active?: boolean}) => {
      if (tank.isMuted && tank.active) return true;
      return false;
    },
  },
  inactive: {
    name: 'Inactive',
    icon: 'DismissCircle24Filled',
    color: lightThemeColors.unavailable,
    desc: 'Inactive',
    check: (tank: {active?: boolean}) => {
      if (!tank.active) return true;
      return false;
    },
  },
};

appConfig.eventCategories = {
  Normal: appConfig.statusCategories.normal,
  Alarming: appConfig.statusCategories.alarming,
  Warning: appConfig.statusCategories.warning,
  Muted: appConfig.statusCategories.muted,
  Inactive: appConfig.statusCategories.inactive,
  Config: {
    name: 'Config',
    icon: 'ArrowSyncCheckmark24Filled',
    color: lightThemeColors.normal,
    desc: 'Config',
  },
};

appConfig.statusWidgets = {
  weight: {
    title: 'Weight',
    unit: 'kg',
    check: (obj: {
      weight?: number;
      isWeightLow?: boolean;
      isWeightHigh?: boolean;
    }) => {
      if (obj.weight === undefined || obj.weight === null) return null;
      let label = '';
      let statusIcon = '';
      let color = '';
      if (obj.isWeightLow) {
        label = 'Weight Low';
        statusIcon = appConfig.statusCategories.alarming.icon;
        color = appConfig.statusCategories.alarming.color;
      } else if (obj.isWeightHigh) {
        label = 'Weight High';
        statusIcon = appConfig.statusCategories.warning.icon;
        color = appConfig.statusCategories.warning.color;
      } else {
        label = appConfig.statusCategories.normal.name;
        statusIcon = appConfig.statusCategories.normal.icon;
        color = appConfig.statusCategories.normal.color;
      }
      return {
        title: appConfig.statusWidgets.weight.title,
        value: parseFloat(obj.weight.toFixed(1)),
        unit: appConfig.statusWidgets.weight.unit,
        status: label,
        statusIcon: statusIcon as BetterIconName,
        color,
      };
    },
  },
  evaporationRate: {
    title: 'Evaporation Rate',
    unit: 'kg/h',
    check: (obj: {
      evaporationRate?: number;
      isEvaporationRateLow?: boolean;
      isEvaporationRateHigh?: boolean;
      isWeightLossCritical?: boolean;
      isWeightLossFast?: boolean;
    }) => {
      if (obj.evaporationRate === undefined || obj.evaporationRate === null) return null;
      let label = '';
      let statusIcon = '';
      let color = '';
      if (obj.isWeightLossCritical) {
        label = 'Weight Loss Critical';
        statusIcon = appConfig.statusCategories.alarming.icon;
        color = appConfig.statusCategories.alarming.color;
      } else if (obj.isWeightLossFast) {
        label = 'Weight Loss Fast';
        statusIcon = appConfig.statusCategories.alarming.icon;
        color = appConfig.statusCategories.alarming.color;
      } else if (obj.isEvaporationRateHigh) {
        label = 'Evaporation Rate High';
        statusIcon = appConfig.statusCategories.alarming.icon;
        color = appConfig.statusCategories.alarming.color;
      } else if (obj.isEvaporationRateLow) {
        label = 'Evaporation Rate Low';
        statusIcon = appConfig.statusCategories.warning.icon;
        color = appConfig.statusCategories.warning.color;
      } else {
        label = appConfig.statusCategories.normal.name;
        statusIcon = appConfig.statusCategories.normal.icon;
        color = appConfig.statusCategories.normal.color;
      }
      return {
        title: appConfig.statusWidgets.evaporationRate.title,
        value: obj.evaporationRate,
        unit: appConfig.statusWidgets.evaporationRate.unit,
        status: label,
        statusIcon: statusIcon as BetterIconName,
        color,
      };
    },
  },
  batteryVoltage: {
    title: 'Battery Status',
    check: (obj: {
      batteryVoltage?: number;
      isBatteryLow?: boolean;
      isBatteryCritical?: boolean;
    }) => {
      if (obj.batteryVoltage === undefined || obj.batteryVoltage === null) return null;
      let icon = 'BatteryWarning24Regular';
      let label = '';
      let statusIcon = '';
      let color = '';
      if (obj.isBatteryCritical) {
        label = 'Battery Critical';
        statusIcon = appConfig.statusCategories.alarming.icon;
        color = appConfig.statusCategories.alarming.color;
      } else if (obj.isBatteryLow) {
        label = 'Battery Low';
        statusIcon = appConfig.statusCategories.warning.icon;
        color = appConfig.statusCategories.warning.color;
      } else {
        icon = 'BatteryCheckmark24Regular';
        label = appConfig.statusCategories.normal.name;
        statusIcon = appConfig.statusCategories.normal.icon;
        color = appConfig.statusCategories.normal.color;
      }
      return {
        title: appConfig.statusWidgets.batteryVoltage.title,
        icon: icon as BetterIconName,
        status: label,
        statusIcon: statusIcon as BetterIconName,
        color,
      };
    },
  },
  lastReadingTime: {
    title: 'Last Reading',
    check: (obj: {
      lastReadingTime?: string;
      isOffline?: boolean;
    }) => {
      const readingTime = strDateToBriefLocal(obj.lastReadingTime);
      let label = '';
      let statusIcon = '';
      let color = '';
      if (obj.isOffline) {
        label = 'Offline';
        statusIcon = appConfig.statusCategories.alarming.icon;
        color = appConfig.statusCategories.alarming.color;
      } else {
        label = appConfig.statusCategories.normal.name;
        statusIcon = appConfig.statusCategories.normal.icon;
        color = appConfig.statusCategories.normal.color;
      }
      return {
        title: appConfig.statusWidgets.lastReadingTime.title,
        value: readingTime || appConfig.noDataSign,
        status: label,
        statusIcon: statusIcon as BetterIconName,
        color,
      };
    },
  },
  temperature: {
    title: 'Temperature',
    unit: '°C',
    check: (obj: {
      temperature?: number;
      isTemperatureLow?: boolean;
    }) => {
      if (obj.isTemperatureLow === undefined || obj.isTemperatureLow === null
        || obj.temperature === undefined || obj.temperature === null) {
        return null;
      }
      let label = '';
      let statusIcon = '';
      let color = '';
      if (obj.isTemperatureLow) {
        label = 'Caddy temperature Low';
        statusIcon = appConfig.statusCategories.alarming.icon;
        color = appConfig.statusCategories.alarming.color;
      } else {
        label = appConfig.statusCategories.normal.name;
        statusIcon = appConfig.statusCategories.normal.icon;
        color = appConfig.statusCategories.normal.color;
      }
      return {
        title: appConfig.statusWidgets.temperature.title,
        value: obj.temperature.toFixed(1) || appConfig.noDataSign,
        unit: appConfig.statusWidgets.temperature.unit,
        status: label,
        statusIcon: statusIcon as BetterIconName,
        color,
      };
    },
  },
};

export default appConfig;
