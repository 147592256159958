import { FluentProvider } from '@fluentui/react-components';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import ProtectedRoutes from './features/protectedRoutes';
import DefaultRoute from './features/defaultRoute';
import Login from './pages/login';
import { ScreenDetailsProvider } from './layouts/screenSizeContext';
import { lightTheme, lightAppStyle } from './common/themes';
import ClinicSelect from './pages/clinicSelect';
import ClinicManage from './pages/clinicManage';
import ClinicDashboard from './pages/dashboard/clinicDashboard';
import TankDashboard from './pages/dashboard/tankDashboard';
import CaddyManage from './pages/caddyManage';
import TankManage from './pages/tankManage';
import DocumentManage from './pages/documentManage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <FluentProvider theme={lightTheme}>
    <ScreenDetailsProvider>
      <div style={lightAppStyle} >
        <Provider store={store}>
          <BrowserRouter>
              <Routes>
                <Route element={<ProtectedRoutes />}>
                  <Route path="/clinics" element={<ClinicSelect />} />
                  <Route path="/clinic-dashboard/:clinicId" element={<ClinicDashboard />} />
                  <Route path="/add-clinic" element={<ClinicManage isUpdate={false} />} />
                  <Route path="/manage-clinics" element={<ClinicManage isUpdate={true} />} />
                  <Route path="/add-caddy" element={<CaddyManage isUpdate={false} />} />
                  <Route path="/manage-caddies" element={<CaddyManage isUpdate={true} />} />
                  <Route path="/manage-caddies/:caddyId" element={<CaddyManage isUpdate={true} />} />
                  <Route path="/tank-dashboard/:tankId" element={<TankDashboard />} />
                  <Route path="/clinic/:clinicId/add-tank" element={<TankManage isUpdate={false} />} />
                  <Route path="/tank/:tankId/manage-tank" element={<TankManage isUpdate={true} />} />
                  <Route path="/documents" element={<DocumentManage />} />
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<DefaultRoute />} />
              </Routes>
            </BrowserRouter>
        </Provider>
      </div>
    </ScreenDetailsProvider>
  </FluentProvider>,
);
