import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  Text,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@fluentui/react-components';
import { DocumentArrowDown20Regular, Delete20Regular } from '@fluentui/react-icons';
import { FetchFunction } from '../../common/usePoll';
import Layout1 from '../../layouts/layout1';
import apiServices, { VResponse, VFile, VFileTypeEnum } from '../../services';
import Breadcrumbs from '../../components/breadcrumbs';
import appConfig from '../../config.app';
import Message, { MessageStatus } from '../../components/message';
import { strDateToBetterLocal } from '../../common/utils';

const DocumentCard: React.FC<{
  file: VFile,
  onRemoveFile?: (fileId: string) => void,
}> = ({ file, onRemoveFile }) => {
  const downloadFile = async (fileId: string) => {
    const fileBlob = await apiServices.getFileApi().downloadFile({ fileId });
    const url = window.URL.createObjectURL(fileBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${file.title}-${file.filename}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  return (
    <Card
      style={{
        width: 327, height: 120, margin: '10px',
      }}
    >
      <div style={{
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        padding: '8px 0px',
        height: '100%',
      }}>
        <CardHeader
          style={{ display: 'flex', justifyContent: 'center' }}
          header={
            <Text
              weight='semibold'
              size={500}
              style={{
                width: 260,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textAlign: 'center',
              }}
            >
              {file.title}
            </Text>
          }
          />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Text
            style={{
              width: 260,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: 'center',
            }}>
            Updated at {strDateToBetterLocal(file.time)}
          </Text>
        </div>
        <div style={{ marginTop: 10, display: 'flex', gap: 10 }}>
          <Button
            icon={<DocumentArrowDown20Regular />}
            appearance='primary'
            onClick={() => downloadFile(file.id)}
            style={{ width: '100%' }}
          >
            Download
          </Button>
          {
            onRemoveFile && (
              <Dialog>
                <DialogTrigger disableButtonEnhancement>
                  <Button
                  icon={<Delete20Regular />}
                  appearance='secondary'
                  style={{ width: '100%' }}
                >
                  Delete
                </Button>
                </DialogTrigger>
                <DialogSurface>
                  <DialogBody>
                    <DialogTitle>Delete Document</DialogTitle>
                    <DialogContent>
                      Are you sure delete the document?
                    </DialogContent>
                    <DialogActions>
                      <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">Cancel</Button>
                      </DialogTrigger>
                      <DialogTrigger disableButtonEnhancement>
                        <Button appearance="primary" onClick={() => onRemoveFile(file.id)}>Confirm</Button>
                      </DialogTrigger>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog>
            )
          }
        </div>
      </div>
    </Card>
  );
};

const DocumentManage: React.FC = () => {
  const [status, setStatus] = useState('loading');
  const [files, setFiles] = useState<VFile[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const fetchFiles: FetchFunction<void> = async () => {
    try {
      setErrorMessage(null);
      const retFiles = await apiServices.getFileApi().queryFiles({
        vQueryFilesRequestParams: { type: VFileTypeEnum.Manual },
      });
      setFiles(retFiles);
      setStatus('success');
    } catch (error) {
      const { message } = error as VResponse;
      setErrorMessage(message || 'An error occurred while retrieving the data');
      setStatus('error');
    }
  };

  const removeFile = async (fileId: string) => {
    await apiServices.getFileApi().removeFile({ fileId });
    fetchFiles();
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  return (
    <Layout1
      breadcrumbsSlot={
        <Breadcrumbs
          breadcrumbs={[]}
          title='Documents'
        />
      }
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
        height: '80%',
        gap: 20,
      }}>
        <Card style={{
          height: '100%',
          padding: 20,
        }}>
          <CardHeader header={
            <Text weight="bold">Documents</Text>
          } />
          {
            files.length
              ? (
                <div style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexGrow: 'unset',
                  overflowY: 'auto',
                  padding: 40,
                }}>
                  {files.map((file) => (
                    <DocumentCard
                      key={file.id}
                      file={file}
                      onRemoveFile={ apiServices.isSuperAdmin() ? removeFile : undefined }
                    />
                  ))}
                </div>
              )
              : (
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  gap: 4,
                }}>
                  <Message
                    message={ status !== 'loading' ? appConfig.errMsg.NOT_FOUND_FILES : '' }
                    description={errorMessage}
                    status={status as MessageStatus}
                  />
                </div>
              )
          }
        </Card>
      </div>
    </Layout1>
  );
};

export default DocumentManage;
