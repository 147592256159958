/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VNotificationStatusEnum } from './VNotificationStatusEnum';
import {
    VNotificationStatusEnumFromJSON,
    VNotificationStatusEnumFromJSONTyped,
    VNotificationStatusEnumToJSON,
} from './VNotificationStatusEnum';

/**
 * 
 * @export
 * @interface VUpdateNotificationRequestParams
 */
export interface VUpdateNotificationRequestParams {
    /**
     * 
     * @type {VNotificationStatusEnum}
     * @memberof VUpdateNotificationRequestParams
     */
    status?: VNotificationStatusEnum;
}

/**
 * Check if a given object implements the VUpdateNotificationRequestParams interface.
 */
export function instanceOfVUpdateNotificationRequestParams(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VUpdateNotificationRequestParamsFromJSON(json: any): VUpdateNotificationRequestParams {
    return VUpdateNotificationRequestParamsFromJSONTyped(json, false);
}

export function VUpdateNotificationRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VUpdateNotificationRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : VNotificationStatusEnumFromJSON(json['status']),
    };
}

export function VUpdateNotificationRequestParamsToJSON(value?: VUpdateNotificationRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': VNotificationStatusEnumToJSON(value.status),
    };
}

