import * as React from 'react';
import { Spinner, Text } from '@fluentui/react-components';
import { CheckmarkCircle12Regular, ErrorCircle12Regular, Info24Filled } from '@fluentui/react-icons';
import { lightThemeColors } from '../common/themes';

export type MessageStatus = 'success' | 'error' | 'loading' | 'info' | 'custom';

export interface MessageProps {
  message: string | undefined | null;
  description?: string | null;
  status?: MessageStatus;
  horizontal?: boolean;
  customImg?: string;
}

const Message: React.FC<MessageProps> = ({
  message,
  description,
  status,
  horizontal,
  customImg,
}) => {
  let imgOrIcon = null;
  const tinyIcon = !description && horizontal;
  if (status === 'custom') {
    imgOrIcon = <img src={customImg} />;
  } else if (status === 'success') {
    const size = tinyIcon ? '24px' : '38px';
    imgOrIcon = <CheckmarkCircle12Regular
        style={{ width: size, height: size }}
        color={ lightThemeColors.primary }
      />;
  } else if (status === 'info') {
    const size = tinyIcon ? '24px' : '38px';
    imgOrIcon = <Info24Filled
        style={{ width: size, height: size }}
        color={ lightThemeColors.primary }
      />;
  } else if (status === 'error') {
    const size = tinyIcon ? '24px' : '38px';
    imgOrIcon = <ErrorCircle12Regular
        style={{ width: size, height: size }}
        color={ lightThemeColors.alarm }
      />;
  } else if (status === 'loading') {
    imgOrIcon = <Spinner size={tinyIcon ? 'tiny' : 'medium'}/>;
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        padding: 10,
        flexDirection: horizontal ? 'row' : 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 6,
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>{imgOrIcon}</div>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
        <div style={{ width: '100%', textAlign: horizontal ? 'left' : 'center' }}>
          <Text weight="bold">{message}</Text>
        </div>
        <div style={{ width: '100%', textAlign: horizontal ? 'left' : 'center' }}>
          <Text>{description}</Text>
        </div>
      </div>
    </div>
  );
};

export default Message;
