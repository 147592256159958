import React from 'react';
import { Navigate } from 'react-router-dom';
import apiServices from '../services';

const DefaultRoute: React.FC = () => {
  const currentUser = apiServices.getCurrentUser();
  // If not found any user, redirect to the login page
  if (!currentUser) {
    return <Navigate to='/login' replace />;
  }

  // If found a user and it only has a clinic, redirect to the clinic
  if (currentUser.clinics.length === 1) {
    return <Navigate to={`/clinic-dashboard/${currentUser.clinics[0].clinicId}`} replace />;
  }

  // Otherwise, redirect to `/clinics`
  return <Navigate to={'/clinics'} replace />;
};

export default DefaultRoute;
