/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  Text,
} from '@fluentui/react-components';
import { useNavigate } from 'react-router-dom';
import usePoll, { FetchFunction, defaultPollInterval } from '../../common/usePoll';
import Layout1 from '../../layouts/layout1';
import apiServices, { VResponse, VClinic } from '../../services';
import Breadcrumbs from '../../components/breadcrumbs';
import appConfig from '../../config.app';
import Message, { MessageStatus } from '../../components/message';

const ClinicCard: React.FC<{ clinic: VClinic }> = ({ clinic }) => {
  const navigate = useNavigate();
  return (
    <Card
      style={{
        width: 327, height: 120, margin: '10px',
      }}
      onClick={() => navigate(`/clinic-dashboard/${clinic.id}`)}
    >
      <div style={{
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        padding: '8px 0px',
        height: '100%',
      }}>
        <CardHeader
          style={{ display: 'flex', justifyContent: 'center' }}
          header={
            <Text
              weight='semibold'
              size={500}
              style={{
                width: 260,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textAlign: 'center',
              }}
            >
              {clinic.name}
            </Text>}
          />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Text
            style={{
              width: 260,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: 'center',
            }}>{clinic.address}</Text>
        </div>
      </div>
    </Card>
  );
};

const ClinicSelect: React.FC = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState('loading');
  const [clinics, setClinics] = useState<VClinic[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const fetchClinics: FetchFunction<VClinic[]> = async () => {
    try {
      setErrorMessage(null);
      const response = await apiServices.getClinicApi().getClinics();
      setStatus('success');
      return response;
    } catch (error) {
      const { message } = error as VResponse;
      setErrorMessage(message || 'An error occurred while retrieving the data');
      setStatus('error');
      return [];
    }
  };

  const startPolling = usePoll(fetchClinics, defaultPollInterval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedStartPolling = useMemo(() => startPolling, []);

  useEffect(() => {
    const stopPolling = memoizedStartPolling(setClinics);

    return () => {
      stopPolling();
    };
  }, [memoizedStartPolling]);

  return (
    <Layout1
      breadcrumbsSlot={
        <Breadcrumbs
          breadcrumbs={[]}
          title='Clinics'
        />
      }
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
        height: '80%',
        gap: 20,
      }}>
        <Card style={{
          height: '100%',
          padding: 20,
        }}>
          <CardHeader header={
            <Text weight="bold">Clinics</Text>
          } />
          {
            clinics.length
              ? (
                <div style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexGrow: 'unset',
                  overflowY: 'auto',
                  padding: 40,
                }}>
                  {clinics.map((clinic) => (
                    <ClinicCard key={clinic.id} clinic={clinic} />
                  ))}
                </div>
              )
              : (
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  gap: 4,
                }}>
                  <Message
                    message={ status !== 'loading' ? appConfig.errMsg.NOT_FOUND_CLINICS : '' }
                    description={errorMessage}
                    status={status as MessageStatus}
                  />
                </div>
              )
          }
        </Card>
        {
          apiServices.isCloudRuntime()
            ? <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 16,
            }}>
              {
                apiServices.isSuperAdmin()
                  ? <>
                      <Button appearance='primary' onClick={() => navigate('/add-caddy')}>Add Caddy</Button>
                    </>
                  : null
              }
              {
                apiServices.isSuperAdmin() || apiServices.isSiteAdmin()
                  ? <>
                      <Button appearance='primary' onClick={() => navigate('/manage-caddies')} >Manage Caddies</Button>
                    </>
                  : null
              }
              {
                apiServices.isSuperAdmin()
                  ? <>
                      <Button appearance='primary' onClick={() => navigate('/add-clinic')}>Add Clinic</Button>
                    </>
                  : null
              }
              {
                // TODO: once we change the way users are managed,
                // they shouldn't be able to manage clinics anymore.
                apiServices.isSuperAdmin() || apiServices.isSiteAdmin()
                  ? <>
                      <Button appearance='primary' onClick={() => navigate('/manage-clinics')} >Manage Clinics</Button>
                    </>
                  : null
              }
            </div> : null
        }
      </div>
      <div style={{ position: 'absolute', bottom: 10, left: 10 }}>
        <Text size={200}>
          API: v{apiServices.getApiVersion()} WebApp: v{appConfig.version}
        </Text>
      </div>
    </Layout1>
  );
};

export default ClinicSelect;
