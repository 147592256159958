import { Card, InfoLabel, Text } from '@fluentui/react-components';
import * as React from 'react';
import BetterIcon, { BetterIconName } from './betterIcon';

const SimpleInfoCard: React.FC<{
  title?: string,
  icon?: BetterIconName,
  items: {
    label: string,
    labelWidth?: number,
    value?: string | number,
    desc?: string,
    tooltip?: string,
  } [],
  defaultShowCount?: number,
  onClickTopBtn?: () => void,
}> = ({
  title,
  icon,
  items,
  defaultShowCount,
  onClickTopBtn,
}) => {
  const [extended, setExtended] = React.useState<boolean>(false);
  const [showDesc, setShowDesc] = React.useState<boolean>(false);
  const showBtn = defaultShowCount && defaultShowCount < items.length;

  const cardHeader = () => {
    if (title || onClickTopBtn) {
      return (
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
          {
            title
              ? <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  { icon ? <BetterIcon name={icon} style={{ width: 24, height: 24 }} /> : null }
                  <Text weight='semibold' size={400}>{ title }</Text>
                </div>
              : null
          }
          </div>
          {
            onClickTopBtn
              ? <div onClick={onClickTopBtn} style={{ cursor: 'pointer' }}>
                <BetterIcon name='EditSettings24Regular' />
              </div> : null
          }
      </div>
      );
    }
    return null;
  };

  return (
    <Card
      style={{
        width: '100%',
        marginBottom: 20,
        padding: 20,
        paddingBottom: showBtn ? 4 : 20,
        overflow: 'auto',
        overflowX: 'hidden',
      }}
    >
      { cardHeader() }
      {
        items.map((item, i) => {
          if (showBtn && !extended && i >= defaultShowCount) return null;
          return (
            <div key={item.label} style={{ display: 'flex' }}>
              <div style={{
                flex: item.labelWidth ? 'unset' : 1,
                width: item.labelWidth ? item.labelWidth : 'auto',
              }}>
                <Text weight='semibold'>
                  { item.label }
                </Text>
                { item.tooltip ? <InfoLabel info={item.tooltip}></InfoLabel> : null }
              </div>
              <div style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: 4,
              }}>
                <Text>{ showDesc && item.desc ? item.desc : item.value }</Text>
                {
                  item.desc
                    ? <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        onMouseEnter={() => setShowDesc(true)}
                        onMouseLeave={() => setShowDesc(false)}
                      >
                        <BetterIcon
                          style={{ width: 14, height: 14 }}
                          name='Info24Regular'
                        />
                      </div> : null
                }
              </div>
            </div>
          );
        })
      }
      {
        showBtn
          ? <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => setExtended(!extended)}
          >
            <BetterIcon
              style={{ width: 20, height: 20 }}
              name={extended ? 'ChevronUp24Regular' : 'ChevronDown24Regular'}
            />
          </div> : null
      }
    </Card>
  );
};

export default SimpleInfoCard;
