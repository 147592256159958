/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VLiteReading
 */
export interface VLiteReading {
    /**
     * Reading ID
     * @type {string}
     * @memberof VLiteReading
     */
    id: string;
    /**
     * Globally uniqiue caddy serial
     * @type {string}
     * @memberof VLiteReading
     */
    caddySerial: string;
    /**
     * Caddy surface temperature (C)
     * @type {number}
     * @memberof VLiteReading
     */
    caddyTemperature: number;
    /**
     * Tank weight (kg)
     * @type {number}
     * @memberof VLiteReading
     */
    tankWeight: number;
    /**
     * Tank evaporation rate (kg/h)
     * @type {number}
     * @memberof VLiteReading
     */
    tankEvaporationRate: number;
    /**
     * Creation time of the reading
     * @type {string}
     * @memberof VLiteReading
     */
    time: string;
}

/**
 * Check if a given object implements the VLiteReading interface.
 */
export function instanceOfVLiteReading(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "caddySerial" in value;
    isInstance = isInstance && "caddyTemperature" in value;
    isInstance = isInstance && "tankWeight" in value;
    isInstance = isInstance && "tankEvaporationRate" in value;
    isInstance = isInstance && "time" in value;

    return isInstance;
}

export function VLiteReadingFromJSON(json: any): VLiteReading {
    return VLiteReadingFromJSONTyped(json, false);
}

export function VLiteReadingFromJSONTyped(json: any, ignoreDiscriminator: boolean): VLiteReading {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'caddySerial': json['caddySerial'],
        'caddyTemperature': json['caddyTemperature'],
        'tankWeight': json['tankWeight'],
        'tankEvaporationRate': json['tankEvaporationRate'],
        'time': json['time'],
    };
}

export function VLiteReadingToJSON(value?: VLiteReading | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'caddySerial': value.caddySerial,
        'caddyTemperature': value.caddyTemperature,
        'tankWeight': value.tankWeight,
        'tankEvaporationRate': value.tankEvaporationRate,
        'time': value.time,
    };
}

