import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Text,
  Spinner,
  InfoLabel,
} from '@fluentui/react-components';
import { useAppSelector, useAppDispatch } from '../../hook';
import {
  fetchData,
  changeCaddySerial,
  changeCaddyClinicId,
  reset,
  addCaddy,
  changeCaddyId,
  updateCaddy,
  changeCaddyFirmwareVersionId,
  changeFilterClinicId,
} from './_slice';
import Layout1 from '../../layouts/layout1';
import appConfig from '../../config.app';
import ToastBox from '../../components/toastBox';
import SelectBar from '../../components/selectBar';
import Breadcrumbs from '../../components/breadcrumbs';
import LabelInput from '../../components/labelInput';
import BetterDropdown from '../../components/betterDropdown';
import apiServices from '../../services';

interface RouteParams {
  caddyId: string;
  [key: string]: string;
}

interface IManageCaddyProps {
  isUpdate: boolean;
}

const CaddyManage: React.FC<IManageCaddyProps> = ({ isUpdate }) => {
  const caddyState = useAppSelector((state) => state.caddyManage);
  const dispatch = useAppDispatch();
  const { caddyId } = useParams<RouteParams>();

  useEffect(() => {
    dispatch(fetchData({ defaultCaddyId: caddyId }));

    return () => {
      dispatch(reset());
    };
  }, [dispatch, caddyId]);

  return (
    <Layout1
      breadcrumbsSlot={
        <Breadcrumbs
          breadcrumbs={[
            { label: 'Clinics', path: '/clinics' },
          ]}
          title={isUpdate ? 'Manage Caddies' : 'Add Caddy'}
        />
      }
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
        gap: 20,
      }}>
        <div>
          <Text weight="bold" size={400}>
            { isUpdate ? 'Manage Caddies' : 'Add Caddy' }
          </Text>
        </div>
        {
          isUpdate && <>
            <SelectBar
              title='Filter caddies'
              placeholder='Select a clinic to filter caddies'
              options={caddyState.clinics.map((x) => ({ id: x.id, label: x.name }))}
              selectedId={caddyState.selectedClinicId}
              onChange={(id) => dispatch(changeFilterClinicId(id))}
              noneOption='No clinic selected'
            />
            <SelectBar
              title='Caddy *'
              placeholder='Select a caddy to update'
              options={
                caddyState.caddies.filter(
                  (x) => !caddyState.selectedClinicId || x.clinicId === caddyState.selectedClinicId,
                ).map(
                  (x) => ({ id: x.id, label: x.serial }),
                )
              }
              selectedId={caddyState.selectedCaddyId}
              onChange={(id) => dispatch(changeCaddyId(id))}
              noneOption='No caddy selected'
            />
          </>
        }
        <Card style={{
          padding: 20,
        }}>
          <LabelInput
            id='serial'
            label='Serial *'
            value={caddyState.caddyInput.serial}
            placeholder={appConfig.message.SERIAL_PLACEHOLDER.replace('{0}', appConfig.serialMaxLen.toString())}
            onChange={(newValue) => dispatch(changeCaddySerial(newValue))}
            disabled={!apiServices.isSuperAdmin() || (isUpdate && !caddyState.selectedCaddyId)}
            maxLength={appConfig.serialMaxLen}
            tooltip='A unique identifier for a caddy. Only super admin can update serial.'
          />
          {
            isUpdate
              ? <LabelInput
              id='firmwareVersion'
              label='Current firmware version'
              value={caddyState.caddyInput.firmwareVersion}
              disabled={true}
              tooltip='The version was reported from caddy and not editable'
            /> : null
          }

          <SelectBar
            title='Target firmware version'
            placeholder='Select a firmware to update'
            options={caddyState.firmwareVersions.map((x) => ({ id: x.id, label: x.title }))}
            selectedId={caddyState.caddyInput.targetFirmwareVersionId}
            defaultValue={caddyState.caddyInput.firmwareVersionTitle}
            onChange={(versionId) => dispatch(changeCaddyFirmwareVersionId(versionId))}
            noneOption='No version selected'
            integrated={true}
            disabled={!apiServices.isSuperAdmin() || (isUpdate && !caddyState.selectedCaddyId)}
            tooltip='Select a firmware version to update the caddy. Only super admin can update firmware.'
          />
          <div style={{
            display: 'flex',
          }}>
            <div style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
            }}>
              <Text weight="bold">Clinic</Text>
            </div>
            <div style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
              <BetterDropdown
                title="clinic"
                placeholder="Select a clinic"
                options={caddyState.clinics.map((x) => ({ id: x.id, label: x.name }))}
                selectedOptionId={caddyState.caddyInput.clinicId}
                onOptionSelect={(x) => dispatch(changeCaddyClinicId(x))}
                disabled={isUpdate && !caddyState.selectedCaddyId}
              />
            </div>
          </div>
        </Card>
        <div style={{ display: 'flex' }}>
          <div style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 6,
          }}>
            {
              caddyState.caddyInput.confTime
                ? <><Spinner size='tiny' /> { appConfig.message.CONF_SYNCING }</>
                : <InfoLabel info={appConfig.message.TIP_MANAGE_CADDY}></InfoLabel>
            }
          </div>
          <div>
            <Button
              onClick={() => dispatch(isUpdate ? updateCaddy() : addCaddy())}
              appearance="primary"
              disabled={!(( // For updating
                caddyState.selectedCaddyId
                && caddyState.caddyInput.serial
                && caddyState.caddyInput.clinicId
                && caddyState.savable
              ) || ( // For adding
                !isUpdate
                && caddyState.caddyInput.clinicId
                && caddyState.caddyInput.serial
              )
              )}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <ToastBox message={caddyState.message} isSuccess={caddyState.isSuccess} />
    </Layout1>
  );
};

export default CaddyManage;
