/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VUserClinic } from './VUserClinic';
import {
    VUserClinicFromJSON,
    VUserClinicFromJSONTyped,
    VUserClinicToJSON,
} from './VUserClinic';

/**
 * 
 * @export
 * @interface VClinic
 */
export interface VClinic {
    /**
     * Name of the clinic
     * @type {string}
     * @memberof VClinic
     */
    name: string;
    /**
     * Address of the clinic
     * @type {string}
     * @memberof VClinic
     */
    address: string;
    /**
     * IP of the clinic
     * @type {string}
     * @memberof VClinic
     */
    ipAddress?: string;
    /**
     * Timezone of the clinic
     * @type {string}
     * @memberof VClinic
     */
    timezone?: string;
    /**
     * If no readings from a tank in this number of seconds, will trigger alarm
     * @type {number}
     * @memberof VClinic
     */
    offlineThreshold?: number;
    /**
     * Mute the tank for this value when clicking the mute button
     * @type {number}
     * @memberof VClinic
     */
    mutePeriod?: number;
    /**
     * Resend a notification if the current time exceeds notificationRetriggerTimeout seconds from RtnModel.sendTime
     * @type {number}
     * @memberof VClinic
     */
    notificationRetriggerTimeout?: number;
    /**
     * When got notification-needed readings more than `notificationTriggerCount`, trigger notifications
     * @type {number}
     * @memberof VClinic
     */
    notificationTriggerCount?: number;
    /**
     * When got notification-not-needed readings more than `notificationResetCount`, reset to the initial state
     * @type {number}
     * @memberof VClinic
     */
    notificationResetCount?: number;
    /**
     * To authorize IoT server and Relay server for each clinic
     * @type {string}
     * @memberof VClinic
     */
    accessToken?: string;
    /**
     * The clinic is active or not
     * @type {boolean}
     * @memberof VClinic
     */
    active: boolean;
    /**
     * Clinic ID
     * @type {string}
     * @memberof VClinic
     */
    id: string;
    /**
     * Users related to the clinic
     * @type {Array<VUserClinic>}
     * @memberof VClinic
     */
    users?: Array<VUserClinic>;
}

/**
 * Check if a given object implements the VClinic interface.
 */
export function instanceOfVClinic(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function VClinicFromJSON(json: any): VClinic {
    return VClinicFromJSONTyped(json, false);
}

export function VClinicFromJSONTyped(json: any, ignoreDiscriminator: boolean): VClinic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'address': json['address'],
        'ipAddress': !exists(json, 'ipAddress') ? undefined : json['ipAddress'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'offlineThreshold': !exists(json, 'offlineThreshold') ? undefined : json['offlineThreshold'],
        'mutePeriod': !exists(json, 'mutePeriod') ? undefined : json['mutePeriod'],
        'notificationRetriggerTimeout': !exists(json, 'notificationRetriggerTimeout') ? undefined : json['notificationRetriggerTimeout'],
        'notificationTriggerCount': !exists(json, 'notificationTriggerCount') ? undefined : json['notificationTriggerCount'],
        'notificationResetCount': !exists(json, 'notificationResetCount') ? undefined : json['notificationResetCount'],
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
        'active': json['active'],
        'id': json['id'],
        'users': !exists(json, 'users') ? undefined : ((json['users'] as Array<any>).map(VUserClinicFromJSON)),
    };
}

export function VClinicToJSON(value?: VClinic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'address': value.address,
        'ipAddress': value.ipAddress,
        'timezone': value.timezone,
        'offlineThreshold': value.offlineThreshold,
        'mutePeriod': value.mutePeriod,
        'notificationRetriggerTimeout': value.notificationRetriggerTimeout,
        'notificationTriggerCount': value.notificationTriggerCount,
        'notificationResetCount': value.notificationResetCount,
        'accessToken': value.accessToken,
        'active': value.active,
        'id': value.id,
        'users': value.users === undefined ? undefined : ((value.users as Array<any>).map(VUserClinicToJSON)),
    };
}

