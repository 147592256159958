import * as React from 'react';
import { Button, Text } from '@fluentui/react-components';
import { VCaddy, VClinic, VTank } from '../../../services';
import {
  isPastTime,
  secondsToMins,
  strDateTimeToHHMM,
} from '../../../common/utils';

const TankMute: React.FC<{
  clinic?: VClinic | null,
  tank?: VTank | null,
  caddy?: VCaddy | null,
  onMute: () => void,
  onUnmute: () => void,
}> = ({
  clinic,
  tank,
  caddy,
  onMute,
  onUnmute,
}) => {
  // Convert seconds to minutes
  const muteSeconds = clinic?.mutePeriod;
  const mutePeriod = (muteSeconds && muteSeconds > 0) ? secondsToMins(muteSeconds) : 0;

  const muteBlock = () => {
    // If the mute period is invalid, there's no caddy, or the tank is inactive,
    // refrain from displaying the mute and unmute buttons
    if (!mutePeriod || !caddy || !tank?.active) return null;
    // If the muteUntilTime indicates a time in the past,
    // the tank is permitted to undergo muting once more
    if (isPastTime(tank?.muteUntilTime)) {
      return (
        <Button
          appearance='primary'
          onClick={() => onMute()}
          style={{
            display: 'block',
            borderRadius: 40,
            height: 74,
            width: 74,
            minWidth: 74,
            padding: 0,
          }}
        >
          <div>Mute</div>
          <div>{mutePeriod} mins</div>
        </Button>
      );
    }
    // If the mute is still active, display the unmute button along with
    // the muted status until the specified time
    return (
      <div>
        {
          tank?.muteUntilTime
            ? <div>
                <div style={{ textAlign: 'center' }}>
                  <Text size={300} weight='bold'>Muted Until</Text>
                </div>
                <div style={{
                  height: 40,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <Text size={500} weight='bold'>{strDateTimeToHHMM(tank?.muteUntilTime)}</Text>
                </div>
            </div> : null
        }
        <div style={{ textAlign: 'center' }}>
          <Button
            onClick={() => onUnmute()}
          >
            Unmute
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div style={{
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }} >
      { muteBlock() }
    </div>
  );
};

export default TankMute;
