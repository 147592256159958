import * as React from 'react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import {
  ChevronDown20Regular,
  ChevronUp20Regular,
  Dismiss20Regular,
} from '@fluentui/react-icons';
import {
  Card, Text,
} from '@fluentui/react-components';
import { VNotification, VNotificationStatusEnum, VNotificationTypeEnum } from '../../../services';
import { lightThemeColors } from '../../../common/themes';
import BetterIcon, { BetterIconName } from '../../../components/betterIcon';
import appConfig from '../../../config.app';

interface NotificationCardProps {
  notification: VNotification;
  onSwitchStatus?: (status: VNotificationStatusEnum) => void;
  onHide?: () => void;
  onDismiss?: () => void;
  isToast: boolean;
}

export const NotificationCard: React.FC<NotificationCardProps> = (
  {
    notification,
    onHide,
    isToast,
  },
) => {
  const [hideDetails, setHideDetails] = React.useState<boolean>(true);
  const notificationDateTime = DateTime.fromISO(notification.time as string);
  return <Card
    style={{
      minWidth: 280,
      maxHeight: 400,
      marginBottom: 12,
      overflowY: 'auto',
    }}
  >
    <div
      style={{
        display: 'flex', alignItems: 'center', gap: 10,
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <BetterIcon
          name={ appConfig.eventCategories[notification.category]?.icon as BetterIconName }
          color={ appConfig.eventCategories[notification.category]?.color }
        />
      </div>
      <div style={{ flex: 1 }}>
        {<Text weight={ 'bold' }>{notification.type}</Text>}
      </div>
      <div>
        { isToast
          ? <Dismiss20Regular onClick={onHide} />
          : <Text size={200}>
            {`${notificationDateTime.isValid ? notificationDateTime.toFormat('h:mm a') : 'N/A'}`}
          </Text>
        }
      </div>
    </div>
    <div>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Link to={`/tank-dashboard/${notification.tankId}`}>
            <Text
              style={{ color: lightThemeColors.primary, fontWeight: '500' }}
            >
              {
                [
                  VNotificationTypeEnum.CaddyConfigSynced as string,
                  VNotificationTypeEnum.CaddyBatteryLow as string,
                  VNotificationTypeEnum.CaddyBatteryCritical as string,
                  VNotificationTypeEnum.CaddyTemperatureLow as string,
                ].includes(notification.type as string)
                  ? notification.caddySerial : notification.tankName }
            </Text>
          </Link>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setHideDetails(!hideDetails)}
        >
          { hideDetails ? <ChevronDown20Regular /> : <ChevronUp20Regular /> }
        </div>
      </div>
      {
        hideDetails ? null : <div style={{
          padding: 10,
          marginTop: 4,
          backgroundColor: lightThemeColors.backgroundColor,
          borderRadius: 4,
        }}>{notification.description}</div>
      }
    </div>
  </Card>;
};

export default NotificationCard;
