import * as React from 'react';
import noCaddies from '../../../assets/images/noCaddies.png';
import { VTank } from '../../../services';
import appConfig from '../../../config.app';
import TankCard from './tankCard';
import Message from '../../../components/message';
import { ILoadingStatus } from '../../../common/models';

interface TankListProps {
  clinicId?: string,
  tanks: VTank[];
  status: ILoadingStatus | null,
}

const TankList: React.FC<TankListProps> = ({ tanks, status }) => {
  if (status === 'loading') {
    return (
      <Message
        message=''
        status='loading'
      />
    );
  }

  if (tanks.length === 0) {
    return (
      <Message
        message={appConfig.errMsg.NOT_FOUND_TANKS}
        status='custom'
        customImg={noCaddies}
      />
    );
  }

  return (
    <div style={{ height: '100%', width: '100%', padding: 10 }}>
    {
      <div style={{
        display: 'flex', flexWrap: 'wrap', flexGrow: 'unset', overflowY: 'auto',
      }}>
        {tanks.map((tank) => (
          <TankCard key={tank.id} tank={tank} />
        ))}
      </div>
    }
    </div>
  );
};

export default TankList;
