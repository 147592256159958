/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VResponse
 */
export interface VResponse {
    /**
     * Successful or not
     * @type {boolean}
     * @memberof VResponse
     */
    isSuccess: boolean;
    /**
     * Message related to the operation
     * @type {string}
     * @memberof VResponse
     */
    message: string;
}

/**
 * Check if a given object implements the VResponse interface.
 */
export function instanceOfVResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isSuccess" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function VResponseFromJSON(json: any): VResponse {
    return VResponseFromJSONTyped(json, false);
}

export function VResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isSuccess': json['isSuccess'],
        'message': json['message'],
    };
}

export function VResponseToJSON(value?: VResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isSuccess': value.isSuccess,
        'message': value.message,
    };
}

