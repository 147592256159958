/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VAddUserRequestParams
 */
export interface VAddUserRequestParams {
    /**
     * Name of the display (Only useful for displays)
     * @type {string}
     * @memberof VAddUserRequestParams
     */
    name?: string;
    /**
     * First name of the user
     * @type {string}
     * @memberof VAddUserRequestParams
     */
    firstName?: string;
    /**
     * Surname of the user
     * @type {string}
     * @memberof VAddUserRequestParams
     */
    surname?: string;
    /**
     * Email of the user
     * @type {string}
     * @memberof VAddUserRequestParams
     */
    email?: string;
    /**
     * The user is an admin or not
     * @type {boolean}
     * @memberof VAddUserRequestParams
     */
    isSuperAdmin: boolean;
    /**
     * The user is a display or not
     * @type {boolean}
     * @memberof VAddUserRequestParams
     */
    isDisplay: boolean;
    /**
     * The user is active or not
     * @type {boolean}
     * @memberof VAddUserRequestParams
     */
    active: boolean;
    /**
     * Plain password of the user
     * @type {string}
     * @memberof VAddUserRequestParams
     */
    password: string;
}

/**
 * Check if a given object implements the VAddUserRequestParams interface.
 */
export function instanceOfVAddUserRequestParams(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isSuperAdmin" in value;
    isInstance = isInstance && "isDisplay" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "password" in value;

    return isInstance;
}

export function VAddUserRequestParamsFromJSON(json: any): VAddUserRequestParams {
    return VAddUserRequestParamsFromJSONTyped(json, false);
}

export function VAddUserRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VAddUserRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'isSuperAdmin': json['isSuperAdmin'],
        'isDisplay': json['isDisplay'],
        'active': json['active'],
        'password': json['password'],
    };
}

export function VAddUserRequestParamsToJSON(value?: VAddUserRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'firstName': value.firstName,
        'surname': value.surname,
        'email': value.email,
        'isSuperAdmin': value.isSuperAdmin,
        'isDisplay': value.isDisplay,
        'active': value.active,
        'password': value.password,
    };
}

