/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VCaddy
 */
export interface VCaddy {
    /**
     * Serial of the caddy
     * @type {string}
     * @memberof VCaddy
     */
    serial: string;
    /**
     * Clinic Id the caddy belongs to
     * @type {string}
     * @memberof VCaddy
     */
    clinicId: string;
    /**
     * Firmware version Id of the caddy
     * @type {string}
     * @memberof VCaddy
     */
    targetFirmwareVersionId?: string;
    /**
     * Low battery threshold
     * @type {number}
     * @memberof VCaddy
     */
    lowBatteryThreshold?: number;
    /**
     * Critical battery threshold
     * @type {number}
     * @memberof VCaddy
     */
    criticalBatteryThreshold?: number;
    /**
     * Caddy Id
     * @type {string}
     * @memberof VCaddy
     */
    id: string;
    /**
     * Caddy firmware version
     * @type {string}
     * @memberof VCaddy
     */
    firmwareVersion?: string;
    /**
     * Caddy battery voltage
     * @type {number}
     * @memberof VCaddy
     */
    batteryVoltage?: number;
    /**
     * Caddy network signal strength
     * @type {number}
     * @memberof VCaddy
     */
    signalStrength?: number;
    /**
     * Caddy surface temperature
     * @type {number}
     * @memberof VCaddy
     */
    temperature?: number;
    /**
     * The caddy battery is low
     * @type {boolean}
     * @memberof VCaddy
     */
    isBatteryLow?: boolean;
    /**
     * The caddy battery is critical
     * @type {boolean}
     * @memberof VCaddy
     */
    isBatteryCritical?: boolean;
    /**
     * The caddy temperature is low
     * @type {boolean}
     * @memberof VCaddy
     */
    isTemperatureLow?: boolean;
    /**
     * Last reading time
     * @type {string}
     * @memberof VCaddy
     */
    lastReadingTime?: string;
    /**
     * Creating or updating time of the caddy (Will be set to null once new config synced to the caddy)
     * @type {string}
     * @memberof VCaddy
     */
    confTime?: string;
    /**
     * Last update time for any attributes
     * @type {string}
     * @memberof VCaddy
     */
    updateTime?: string;
}

/**
 * Check if a given object implements the VCaddy interface.
 */
export function instanceOfVCaddy(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "serial" in value;
    isInstance = isInstance && "clinicId" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function VCaddyFromJSON(json: any): VCaddy {
    return VCaddyFromJSONTyped(json, false);
}

export function VCaddyFromJSONTyped(json: any, ignoreDiscriminator: boolean): VCaddy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serial': json['serial'],
        'clinicId': json['clinicId'],
        'targetFirmwareVersionId': !exists(json, 'targetFirmwareVersionId') ? undefined : json['targetFirmwareVersionId'],
        'lowBatteryThreshold': !exists(json, 'lowBatteryThreshold') ? undefined : json['lowBatteryThreshold'],
        'criticalBatteryThreshold': !exists(json, 'criticalBatteryThreshold') ? undefined : json['criticalBatteryThreshold'],
        'id': json['id'],
        'firmwareVersion': !exists(json, 'firmwareVersion') ? undefined : json['firmwareVersion'],
        'batteryVoltage': !exists(json, 'batteryVoltage') ? undefined : json['batteryVoltage'],
        'signalStrength': !exists(json, 'signalStrength') ? undefined : json['signalStrength'],
        'temperature': !exists(json, 'temperature') ? undefined : json['temperature'],
        'isBatteryLow': !exists(json, 'isBatteryLow') ? undefined : json['isBatteryLow'],
        'isBatteryCritical': !exists(json, 'isBatteryCritical') ? undefined : json['isBatteryCritical'],
        'isTemperatureLow': !exists(json, 'isTemperatureLow') ? undefined : json['isTemperatureLow'],
        'lastReadingTime': !exists(json, 'lastReadingTime') ? undefined : json['lastReadingTime'],
        'confTime': !exists(json, 'confTime') ? undefined : json['confTime'],
        'updateTime': !exists(json, 'updateTime') ? undefined : json['updateTime'],
    };
}

export function VCaddyToJSON(value?: VCaddy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serial': value.serial,
        'clinicId': value.clinicId,
        'targetFirmwareVersionId': value.targetFirmwareVersionId,
        'lowBatteryThreshold': value.lowBatteryThreshold,
        'criticalBatteryThreshold': value.criticalBatteryThreshold,
        'id': value.id,
        'firmwareVersion': value.firmwareVersion,
        'batteryVoltage': value.batteryVoltage,
        'signalStrength': value.signalStrength,
        'temperature': value.temperature,
        'isBatteryLow': value.isBatteryLow,
        'isBatteryCritical': value.isBatteryCritical,
        'isTemperatureLow': value.isTemperatureLow,
        'lastReadingTime': value.lastReadingTime,
        'confTime': value.confTime,
        'updateTime': value.updateTime,
    };
}

