/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VUpdateTankRequestParams
 */
export interface VUpdateTankRequestParams {
    /**
     * Name of the tank
     * @type {string}
     * @memberof VUpdateTankRequestParams
     */
    name?: string;
    /**
     * Serial of the tank
     * @type {string}
     * @memberof VUpdateTankRequestParams
     */
    serial?: string;
    /**
     * Manufacturer of the tank
     * @type {string}
     * @memberof VUpdateTankRequestParams
     */
    manufacturer?: string;
    /**
     * Model of the tank
     * @type {string}
     * @memberof VUpdateTankRequestParams
     */
    modelType?: string;
    /**
     * Capacity (L) of the tank
     * @type {number}
     * @memberof VUpdateTankRequestParams
     */
    capacity?: number;
    /**
     * Empty weight (kg) of the tank
     * @type {number}
     * @memberof VUpdateTankRequestParams
     */
    minWeight?: number;
    /**
     * Full weight (kg) of the tank
     * @type {number}
     * @memberof VUpdateTankRequestParams
     */
    maxWeight?: number;
    /**
     * Minimum evaporation rate (kg/h) of the tank
     * @type {number}
     * @memberof VUpdateTankRequestParams
     */
    minEvaporationRate?: number;
    /**
     * Maximum evaporation rate (kg/h) of the tank
     * @type {number}
     * @memberof VUpdateTankRequestParams
     */
    maxEvaporationRate?: number;
    /**
     * Fast weight loss rate of tank for elevated alarming
     * @type {number}
     * @memberof VUpdateTankRequestParams
     */
    weightLossFastRate?: number;
    /**
     * Critical weight loss rate of tank for elevated alarming
     * @type {number}
     * @memberof VUpdateTankRequestParams
     */
    weightLossCriticalRate?: number;
    /**
     * Apex reset weight (kg) threshold
     * @type {number}
     * @memberof VUpdateTankRequestParams
     */
    apexResetWeight?: number;
    /**
     * If current time is less than muteUntilTime, muteCaddy will be set to true in queryConfiguration api
     * @type {string}
     * @memberof VUpdateTankRequestParams
     */
    muteUntilTime?: string;
    /**
     * Id of clinic the tank belongs to
     * @type {string}
     * @memberof VUpdateTankRequestParams
     */
    clinicId?: string;
    /**
     * Id of caddy the tank is installed
     * @type {string}
     * @memberof VUpdateTankRequestParams
     */
    caddyId?: string;
    /**
     * Ids of displays
     * @type {Array<string>}
     * @memberof VUpdateTankRequestParams
     */
    displayIds?: Array<string>;
    /**
     * The tank is active or not
     * @type {boolean}
     * @memberof VUpdateTankRequestParams
     */
    active?: boolean;
}

/**
 * Check if a given object implements the VUpdateTankRequestParams interface.
 */
export function instanceOfVUpdateTankRequestParams(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VUpdateTankRequestParamsFromJSON(json: any): VUpdateTankRequestParams {
    return VUpdateTankRequestParamsFromJSONTyped(json, false);
}

export function VUpdateTankRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VUpdateTankRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'serial': !exists(json, 'serial') ? undefined : json['serial'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'modelType': !exists(json, 'modelType') ? undefined : json['modelType'],
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'minWeight': !exists(json, 'minWeight') ? undefined : json['minWeight'],
        'maxWeight': !exists(json, 'maxWeight') ? undefined : json['maxWeight'],
        'minEvaporationRate': !exists(json, 'minEvaporationRate') ? undefined : json['minEvaporationRate'],
        'maxEvaporationRate': !exists(json, 'maxEvaporationRate') ? undefined : json['maxEvaporationRate'],
        'weightLossFastRate': !exists(json, 'weightLossFastRate') ? undefined : json['weightLossFastRate'],
        'weightLossCriticalRate': !exists(json, 'weightLossCriticalRate') ? undefined : json['weightLossCriticalRate'],
        'apexResetWeight': !exists(json, 'apexResetWeight') ? undefined : json['apexResetWeight'],
        'muteUntilTime': !exists(json, 'muteUntilTime') ? undefined : json['muteUntilTime'],
        'clinicId': !exists(json, 'clinicId') ? undefined : json['clinicId'],
        'caddyId': !exists(json, 'caddyId') ? undefined : json['caddyId'],
        'displayIds': !exists(json, 'displayIds') ? undefined : json['displayIds'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function VUpdateTankRequestParamsToJSON(value?: VUpdateTankRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'serial': value.serial,
        'manufacturer': value.manufacturer,
        'modelType': value.modelType,
        'capacity': value.capacity,
        'minWeight': value.minWeight,
        'maxWeight': value.maxWeight,
        'minEvaporationRate': value.minEvaporationRate,
        'maxEvaporationRate': value.maxEvaporationRate,
        'weightLossFastRate': value.weightLossFastRate,
        'weightLossCriticalRate': value.weightLossCriticalRate,
        'apexResetWeight': value.apexResetWeight,
        'muteUntilTime': value.muteUntilTime,
        'clinicId': value.clinicId,
        'caddyId': value.caddyId,
        'displayIds': value.displayIds,
        'active': value.active,
    };
}

