import {
  BrandVariants, Theme, createLightTheme, createDarkTheme,
} from '@fluentui/react-components';
import background from '../assets/images/bg.svg';

const weighmedTheme: BrandVariants = {
  10: '#020304',
  20: '#10191F',
  30: '#162936',
  40: '#193548',
  50: '#1B435B',
  60: '#1B506F',
  70: '#1B5E83',
  80: '#196D98',
  90: '#147BAE',
  100: '#0A8AC4',
  110: '#2C99D3',
  120: '#57A6D9',
  130: '#76B3DF',
  140: '#92C1E5',
  150: '#ABCFEB',
  160: '#C4DDF1',
};

const lightTheme: Theme = {
  ...createLightTheme(weighmedTheme),
};

const darkTheme: Theme = {
  ...createDarkTheme(weighmedTheme),
};

const { 110: darkColorBrandForeground1, 120: darkColorBrandForeground2 } = weighmedTheme;
darkTheme.colorBrandForeground1 = darkColorBrandForeground1;
darkTheme.colorBrandForeground2 = darkColorBrandForeground2;

const lightThemeColors = {
  borderColor: '#EFEFEF',
  backgroundColor: '#FAFAFA',
  normal: '#13a10e',
  alarm: '#E95050',
  warning: '#FFA902',
  service: '#0093D0',
  unavailable: '#818181',
  disabled: '#D9D9D9',
  primary: '#0F6CBD',
};

const darkThemeColors = {
  backgroundColor: '#1F1F1F',
};

const lightAppStyle = {
  backgroundColor: lightThemeColors.backgroundColor,
  backgroundImage: `url(${background})`,
  backgroundRepeat: 'repeat',
  backgroundSize: '20% auto',
};

const darkAppStyle = {
  backgroundColor: darkThemeColors.backgroundColor,
  backgroundImage: `url(${background})`,
  backgroundRepeat: 'repeat',
  backgroundSize: '20% auto',
};

export {
  lightTheme,
  lightAppStyle,
  lightThemeColors,
  darkTheme,
  darkThemeColors,
  darkAppStyle,
};
