import React, {
  createContext, useContext, useState, ReactNode, useEffect,
} from 'react';

// eslint-disable-next-line no-shadow
export enum ScreenSize {
  SMALL = 1,
  MEDIUM = 2,
  LARGE = 3,
}

const smallThreshold = 640;
const mediumThreshold = 1008;

interface ScreenSizeContextType {
  screenCategory: ScreenSize;
}

const ScreenSizeContext = createContext<ScreenSizeContextType | undefined>(undefined);

export const useScreenSize = (): ScreenSizeContextType => {
  const context = useContext(ScreenSizeContext);
  if (!context) {
    throw new Error('useScreenSize must be used within a ScreenDetailsProvider');
  }
  return context;
};

export const ScreenDetailsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  let screenCategory = ScreenSize.LARGE;
  if (window.innerWidth <= smallThreshold) screenCategory = ScreenSize.SMALL;
  else if (window.innerWidth <= mediumThreshold) screenCategory = ScreenSize.MEDIUM;

  const [screenDetails, setScreenDetails] = useState<ScreenSizeContextType>({
    screenCategory,
  });

  useEffect(() => {
    const updateScreenDetails = () => {
      setScreenDetails({
        screenCategory,
      });
    };

    const handleResize = () => {
      updateScreenDetails();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screenCategory]);

  return (
    <ScreenSizeContext.Provider value={screenDetails}>
      {children}
    </ScreenSizeContext.Provider>
  );
};
