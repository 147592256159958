import * as React from 'react';
import { Label, Input, InfoLabel } from '@fluentui/react-components';

export interface ILabelInputProps {
  id: string;
  label: string;
  value: string,
  placeholder?: string,
  disabled?: boolean,
  readonly?: boolean,
  type?: 'number' | 'search' | 'time' | 'text' | 'email' | 'password' | 'tel' | 'url' | 'date' | 'datetime-local' | 'month' | 'week',
  maxLength?: number,
  tooltip?: string,
  onChange?: (newValue: string) => void,
}

const LabelInput: React.FC<ILabelInputProps> = ({
  id,
  label,
  value,
  placeholder,
  disabled,
  readonly,
  type,
  maxLength,
  tooltip,
  onChange,
}) => (
  <div>
    <Label
      htmlFor={id}
      size='medium'
      weight='semibold'
      style={{ display: 'flex', fontWeight: 'bold' }}
    >
      {label} { tooltip ? <InfoLabel info={tooltip}></InfoLabel> : null }
    </Label>
    <Input
      type={type}
      id={id}
      size='large'
      appearance='underline'
      style={{ width: '100%' }}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange && onChange(e.target.value)}
      disabled={!!disabled}
      readOnly={!!readonly}
      maxLength={maxLength}
    />
  </div>
);
export default LabelInput;
