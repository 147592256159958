import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './pages/login/_slice';
import manageClinicReducer from './pages/clinicManage/_slice';
import manageCaddyReducer from './pages/caddyManage/_slice';
import clinicDashboardReducer from './pages/dashboard/_slice';
import manageTankReducer from './pages/tankManage/_slice';

const store = configureStore({
  reducer: {
    login: loginReducer,
    clinicManage: manageClinicReducer,
    caddyManage: manageCaddyReducer,
    dashboard: clinicDashboardReducer,
    tankManage: manageTankReducer,
  },
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
