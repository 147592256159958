/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VQueryReadingsRequestParams
 */
export interface VQueryReadingsRequestParams {
    /**
     * Id of the tank that has readings
     * @type {string}
     * @memberof VQueryReadingsRequestParams
     */
    tankId: string;
    /**
     * Start date of a range
     * @type {string}
     * @memberof VQueryReadingsRequestParams
     */
    startDate?: string;
    /**
     * End date of a range
     * @type {string}
     * @memberof VQueryReadingsRequestParams
     */
    endDate?: string;
}

/**
 * Check if a given object implements the VQueryReadingsRequestParams interface.
 */
export function instanceOfVQueryReadingsRequestParams(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tankId" in value;

    return isInstance;
}

export function VQueryReadingsRequestParamsFromJSON(json: any): VQueryReadingsRequestParams {
    return VQueryReadingsRequestParamsFromJSONTyped(json, false);
}

export function VQueryReadingsRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VQueryReadingsRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tankId': json['tankId'],
        'startDate': !exists(json, 'startDate') ? undefined : json['startDate'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
    };
}

export function VQueryReadingsRequestParamsToJSON(value?: VQueryReadingsRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tankId': value.tankId,
        'startDate': value.startDate,
        'endDate': value.endDate,
    };
}

