/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import apiServices, {
  VCaddy, VClinic, VTank, VUser,
} from '../../services';
import { Result } from '../../common/models';
import appConfig from '../../config.app';
import { RootState } from '../../store';
import { numberToString } from '../../common/utils';
import { formatErrorMsg } from '../../common/helper';

export interface TankInput {
  name: string;
  serial: string;
  capacity: string;
  caddyId: string;
  displayIds: Array<string>;
  modelType: string;
  manufacturer: string;
  minWeight: string;
  maxWeight: string;
  minEvaporationRate: string;
  maxEvaporationRate: string;
  weightLossFastRate: string;
  weightLossCriticalRate: string;
  apexResetWeight: string;
  confTime?: string;
  active: boolean;
}

interface TankState {
  clinicId: string;
  clinic: VClinic | null;
  displays: VUser[];
  error: string | null;
  selectedCaddy: VCaddy | null;
  selectedDisplayUsers: VUser[];
  tank: VTank | null;
  tankInput: TankInput;
  caddies: VCaddy[];
  savable: boolean;
}

const initialState: TankState = {
  clinicId: '',
  clinic: null,
  displays: [],
  error: null,
  selectedCaddy: null,
  selectedDisplayUsers: [],
  tank: null,
  tankInput: {
    name: '',
    serial: '',
    modelType: '',
    manufacturer: '',
    capacity: '',
    caddyId: '',
    displayIds: [],
    minWeight: '',
    maxWeight: '',
    minEvaporationRate: '',
    maxEvaporationRate: '',
    weightLossFastRate: '',
    weightLossCriticalRate: '',
    apexResetWeight: '',
    confTime: '',
    active: true,
  },
  caddies: [],
  savable: false,
};

interface IPayloadSelectUser {
  userId: string,
}

// Handle actions in your reducers
const tankSlice = createSlice({
  name: 'tankManage',
  initialState,
  reducers: {
    fetchTank: (state, action: PayloadAction<VTank>) => {
      state.tank = action.payload;
      state.clinicId = state.tank.clinicId;
    },
    fetchCaddies: (state, action: PayloadAction<VCaddy[]>) => {
      state.caddies = action.payload;
    },
    fetchClinic: (state, action: PayloadAction<VClinic>) => {
      state.clinic = action.payload;
    },
    fetchDisplayUsers: (state, action: PayloadAction<VUser[]>) => {
      state.displays = action.payload;
      // Displays
      state.selectedDisplayUsers = state.displays.filter(
        (display) => state.tank?.displayIds?.includes(display.id),
      );
      state.savable = true;
    },
    changeTankName: (state, action: PayloadAction<string>) => {
      state.tankInput.name = action.payload;
      state.savable = true;
    },
    changeTankSerial: (state, action: PayloadAction<string>) => {
      state.tankInput.serial = action.payload;
      state.savable = true;
    },
    changeTankManufacturer: (state, action: PayloadAction<string>) => {
      state.tankInput.manufacturer = action.payload;
      state.savable = true;
    },
    changeTankModel: (state, action: PayloadAction<string>) => {
      state.tankInput.modelType = action.payload;
      state.savable = true;
    },
    changeTankCapacity: (state, action: PayloadAction<string>) => {
      state.tankInput.capacity = action.payload;
      state.savable = true;
    },
    changeTankMinWeight: (state, action: PayloadAction<string>) => {
      state.tankInput.minWeight = action.payload;
      state.savable = true;
    },
    changeTankMaxWeight: (state, action: PayloadAction<string>) => {
      state.tankInput.maxWeight = action.payload;
      state.savable = true;
    },
    changeTankMinEvaporationRate: (state, action: PayloadAction<string>) => {
      state.tankInput.minEvaporationRate = action.payload;
      state.savable = true;
    },
    changeTankMaxEvaporationRate: (state, action: PayloadAction<string>) => {
      state.tankInput.maxEvaporationRate = action.payload;
      state.savable = true;
    },
    changeTankWeightLossFastRate: (state, action: PayloadAction<string>) => {
      state.tankInput.weightLossFastRate = action.payload;
      state.savable = true;
    },
    changeTankWeightLossCriticalRate: (state, action: PayloadAction<string>) => {
      state.tankInput.weightLossCriticalRate = action.payload;
      state.savable = true;
    },
    changeTankApexResetWeight: (state, action: PayloadAction<string>) => {
      state.tankInput.apexResetWeight = action.payload;
      state.savable = true;
    },
    changeTankCaddyId: (state, action: PayloadAction<string>) => {
      state.tankInput.caddyId = action.payload;
      state.savable = true;
    },
    changeTankClinicId: (state, action: PayloadAction<string>) => {
      state.clinicId = action.payload;
      state.savable = true;
    },
    changeTankDisplayIds: (state, action: PayloadAction<string[]>) => {
      state.tankInput.displayIds = action.payload;
      state.selectedDisplayUsers = state.displays.filter(
        (display) => action.payload?.includes(display.id),
      );
      state.savable = true;
    },
    changeTankActive: (state, action: PayloadAction<boolean>) => {
      state.tankInput.active = action.payload;
      state.savable = true;
    },
    selectDisplayUser: (state, action: PayloadAction<IPayloadSelectUser>) => {
      state.savable = true;
      const { userId } = action.payload;
      // Check if the selected user exists in the displays
      const selectedUser = state.displays.find((user) => user.id === userId);
      if (!selectedUser) {
        return;
      }
      // Stops duplicates being added
      if (state.selectedDisplayUsers.find((user) => user.id === userId)) {
        return;
      }
      state.selectedDisplayUsers = [...state.selectedDisplayUsers, selectedUser];
    },
    unselectDisplayUser: (state, action: PayloadAction<IPayloadSelectUser>) => {
      state.savable = true;
      const { userId } = action.payload;
      state.selectedDisplayUsers = [
        ...state.selectedDisplayUsers.filter((user) => user.id !== userId),
      ];
    },
    changeTank(state, action: PayloadAction<VTank>) {
      const tankData = action.payload;
      state.tankInput.active = tankData.active;
      state.tankInput.caddyId = tankData.caddyId;
      state.tankInput.capacity = numberToString(tankData.capacity);
      state.tankInput.displayIds = tankData.displayIds;
      state.tankInput.manufacturer = tankData.manufacturer || '';
      state.tankInput.maxEvaporationRate = numberToString(tankData.maxEvaporationRate);
      state.tankInput.maxWeight = numberToString(tankData.maxWeight);
      state.tankInput.minEvaporationRate = numberToString(tankData.minEvaporationRate);
      state.tankInput.minWeight = numberToString(tankData.minWeight);
      state.tankInput.modelType = tankData.modelType || '';
      state.tankInput.name = tankData.name;
      state.tankInput.serial = tankData.serial;
      state.tankInput.weightLossCriticalRate = numberToString(tankData.weightLossCriticalRate);
      state.tankInput.weightLossFastRate = numberToString(tankData.weightLossFastRate);
      state.tankInput.apexResetWeight = numberToString(tankData.apexResetWeight);
      state.tankInput.confTime = tankData.confTime;
    },
    reset: (state, action: PayloadAction<boolean>) => {
      state.tankInput.active = true;
      state.tankInput.capacity = '';
      state.tankInput.caddyId = '';
      state.tankInput.displayIds = [];
      state.tankInput.manufacturer = '';
      state.tankInput.maxEvaporationRate = '';
      state.tankInput.maxWeight = '';
      state.tankInput.minEvaporationRate = '';
      state.tankInput.minWeight = '';
      state.tankInput.modelType = '';
      state.tankInput.name = '';
      state.tankInput.serial = '';
      state.tankInput.weightLossCriticalRate = '';
      state.tankInput.weightLossFastRate = '';
      state.tankInput.apexResetWeight = '';
      state.tankInput.confTime = '';
      state.savable = false;
      if (action.payload) {
        state.displays = [];
        state.selectedDisplayUsers = [];
      }
      state.error = null;
    },
    fail: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const fetchDisplayUsers = createAsyncThunk<void, { clinicId: string }>(
  'tankManage/fetchDisplayUsers',
  async (params, thunkAPI) => {
    if (params.clinicId) {
      try {
        const displays = await apiServices.getUserApi().queryUsers({
          vQueryUsersRequestParams: {
            clinicId: params.clinicId,
            isDisplay: true,
          },
        });
        thunkAPI.dispatch(tankSlice.actions.fetchDisplayUsers(displays));
      } catch (err) {
        thunkAPI.dispatch(tankSlice.actions.fail(
          (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR,
        ));
      }
    }
  },
);

export const fetchCaddiesByClinicId = createAsyncThunk<void, { clinicId: string }>(
  'tankManage/fetchCaddiesByClinicId',
  async (params, thunkAPI) => {
    if (params.clinicId) {
      try {
        const caddies = await apiServices.getCaddyApi().queryCaddies({
          vQueryCaddiesRequestParams: {
            clinicId: params.clinicId,
          },
        });
        thunkAPI.dispatch(tankSlice.actions.fetchCaddies(caddies));
      } catch (err) {
        thunkAPI.dispatch(tankSlice.actions.fail(
          (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR,
        ));
      }
    }
  },
);

export const fetchAllByClinicId = createAsyncThunk<void, { clinicId: string }>(
  'tankManage/fetchAllByClinicId',
  async (params, thunkAPI) => {
    try {
      const clinic = await apiServices.getClinicApi().getClinic({ clinicId: params.clinicId });
      thunkAPI.dispatch(tankSlice.actions.fetchClinic(clinic));
      thunkAPI.dispatch(tankSlice.actions.changeTankClinicId(clinic.id));
      thunkAPI.dispatch(fetchDisplayUsers({ clinicId: clinic.id }));
      thunkAPI.dispatch(fetchCaddiesByClinicId({ clinicId: clinic.id }));
    } catch (err) {
      thunkAPI.dispatch(tankSlice.actions.fail(
        (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR,
      ));
    }
  },
);

export const fetchAllByTankId = createAsyncThunk<void, { tankId: string }>(
  'tankManage/fetchAllByTankId',
  async (params, thunkAPI) => {
    try {
      const tank = await apiServices.getTankApi().getTank({ tankId: params.tankId });
      thunkAPI.dispatch(tankSlice.actions.fetchTank(tank));
      thunkAPI.dispatch(tankSlice.actions.changeTank(tank));
      if (!tank.clinicId) throw new Error('Tank has no clinic id (mandatory field)');
      const clinic = await apiServices.getClinicApi().getClinic({ clinicId: tank.clinicId });
      thunkAPI.dispatch(tankSlice.actions.fetchClinic(clinic));
      thunkAPI.dispatch(fetchCaddiesByClinicId({ clinicId: tank.clinicId }));
      thunkAPI.dispatch(fetchDisplayUsers({ clinicId: tank.clinicId }));
    } catch (err) {
      thunkAPI.dispatch(tankSlice.actions.fail(
        (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR,
      ));
    }
  },
);

export const addTank = createAsyncThunk<Result>(
  'tankManage/addTank',
  async (_, thunkAPI) => {
    try {
      const tankState = (thunkAPI.getState() as RootState).tankManage as TankState;
      await apiServices.getTankApi().addTank({
        vAddTankRequestParams: {
          clinicId: tankState.clinicId,
          name: tankState.tankInput.name,
          serial: tankState.tankInput.serial,
          manufacturer: tankState.tankInput.manufacturer,
          modelType: tankState.tankInput.modelType,
          capacity: Number(tankState.tankInput.capacity),
          minWeight: Number(tankState.tankInput.minWeight),
          maxWeight: Number(tankState.tankInput.maxWeight),
          minEvaporationRate: Number(tankState.tankInput.minEvaporationRate),
          maxEvaporationRate: Number(tankState.tankInput.maxEvaporationRate),
          weightLossFastRate: Number(tankState.tankInput.weightLossFastRate),
          weightLossCriticalRate: Number(tankState.tankInput.weightLossCriticalRate),
          apexResetWeight: Number(tankState.tankInput.apexResetWeight),
          caddyId: tankState.tankInput.caddyId,
          displayIds: tankState.selectedDisplayUsers.map(
            (displayUser) => (displayUser.id),
          ) || [],
          active: tankState.tankInput.active,
        },
      });
      return { result: true, message: appConfig.message.TANK_ADDED };
    } catch (err) {
      const errMsg = (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR;
      // thunkAPI.dispatch(tankSlice.actions.fail(errMsg));
      return { result: false, message: errMsg };
    }
  },
);

export const updateTank = createAsyncThunk<Result>(
  'tankManage/updateTank',
  async (_, thunkAPI) => {
    try {
      const tankState = (thunkAPI.getState() as RootState).tankManage as TankState;
      if (tankState.tank) {
        await apiServices.getTankApi().updateTank({
          tankId: tankState.tank.id,
          vUpdateTankRequestParams: {
            clinicId: tankState.clinicId,
            name: tankState.tankInput.name,
            serial: tankState.tankInput.serial,
            manufacturer: tankState.tankInput.manufacturer,
            modelType: tankState.tankInput.modelType,
            capacity: Number(tankState.tankInput.capacity),
            minWeight: Number(tankState.tankInput.minWeight),
            maxWeight: Number(tankState.tankInput.maxWeight),
            minEvaporationRate: Number(tankState.tankInput.minEvaporationRate),
            maxEvaporationRate: Number(tankState.tankInput.maxEvaporationRate),
            weightLossFastRate: Number(tankState.tankInput.weightLossFastRate),
            weightLossCriticalRate: Number(tankState.tankInput.weightLossCriticalRate),
            apexResetWeight: Number(tankState.tankInput.apexResetWeight),
            caddyId: tankState.tankInput.caddyId,
            displayIds: tankState.selectedDisplayUsers.map(
              (displayUser) => (displayUser.id),
            ) || [],
            active: tankState.tankInput.active,
          },
        });
      }
      return { result: true, message: appConfig.message.TANK_UPDATED };
    } catch (err) {
      const errMsg = (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR;
      // thunkAPI.dispatch(tankSlice.actions.fail(errMsg));
      return { result: false, message: formatErrorMsg(errMsg) };
    }
  },
);

export const {
  selectDisplayUser,
  unselectDisplayUser,
  changeTankActive,
  changeTankCaddyId,
  changeTankCapacity,
  changeTankDisplayIds,
  changeTankManufacturer,
  changeTankMaxEvaporationRate,
  changeTankMaxWeight,
  changeTankMinEvaporationRate,
  changeTankMinWeight,
  changeTankModel,
  changeTankClinicId,
  changeTankName,
  changeTankSerial,
  changeTankWeightLossCriticalRate,
  changeTankWeightLossFastRate,
  changeTankApexResetWeight,
  changeTank,
  reset,
} = tankSlice.actions;
export default tankSlice.reducer;
