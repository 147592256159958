/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  VFile,
  VFileTypeEnum,
  VQueryFilesRequestParams,
} from '../models/index';
import {
    VFileFromJSON,
    VFileToJSON,
    VFileTypeEnumFromJSON,
    VFileTypeEnumToJSON,
    VQueryFilesRequestParamsFromJSON,
    VQueryFilesRequestParamsToJSON,
} from '../models/index';

export interface DownloadFileRequest {
    fileId: string;
}

export interface QueryFilesRequest {
    vQueryFilesRequestParams: VQueryFilesRequestParams;
}

export interface RemoveFileRequest {
    fileId: string;
}

export interface UploadFileRequest {
    title: string;
    type: VFileTypeEnum;
    uploadedFile: Blob;
    description?: string;
}

/**
 * 
 */
export class FileApi extends runtime.BaseAPI {

    /**
     * Download a file by file Id
     * Download a file
     */
    async downloadFileRaw(requestParameters: DownloadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling downloadFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/file/download/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download a file by file Id
     * Download a file
     */
    async downloadFile(requestParameters: DownloadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Query files by all kinds of params
     * Query files
     */
    async queryFilesRaw(requestParameters: QueryFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VFile>>> {
        if (requestParameters.vQueryFilesRequestParams === null || requestParameters.vQueryFilesRequestParams === undefined) {
            throw new runtime.RequiredError('vQueryFilesRequestParams','Required parameter requestParameters.vQueryFilesRequestParams was null or undefined when calling queryFiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/file/queryFiles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VQueryFilesRequestParamsToJSON(requestParameters.vQueryFilesRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VFileFromJSON));
    }

    /**
     * Query files by all kinds of params
     * Query files
     */
    async queryFiles(requestParameters: QueryFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VFile>> {
        const response = await this.queryFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove a file by file Id
     * Remove a file
     */
    async removeFileRaw(requestParameters: RemoveFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling removeFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/file/remove/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a file by file Id
     * Remove a file
     */
    async removeFile(requestParameters: RemoveFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeFileRaw(requestParameters, initOverrides);
    }

    /**
     * Upload a new file
     * Upload a new file
     */
    async uploadFileRaw(requestParameters: UploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VFile>> {
        if (requestParameters.title === null || requestParameters.title === undefined) {
            throw new runtime.RequiredError('title','Required parameter requestParameters.title was null or undefined when calling uploadFile.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling uploadFile.');
        }

        if (requestParameters.uploadedFile === null || requestParameters.uploadedFile === undefined) {
            throw new runtime.RequiredError('uploadedFile','Required parameter requestParameters.uploadedFile was null or undefined when calling uploadFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.title !== undefined) {
            formParams.append('title', requestParameters.title as any);
        }

        if (requestParameters.type !== undefined) {
            formParams.append('type', new Blob([JSON.stringify(VFileTypeEnumToJSON(requestParameters.type))], { type: "application/json", }));
                    }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.uploadedFile !== undefined) {
            formParams.append('uploadedFile', requestParameters.uploadedFile as any);
        }

        const response = await this.request({
            path: `/file/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VFileFromJSON(jsonValue));
    }

    /**
     * Upload a new file
     * Upload a new file
     */
    async uploadFile(requestParameters: UploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VFile> {
        const response = await this.uploadFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
