import * as React from 'react';
import {
  Text,
  Button,
} from '@fluentui/react-components';
import { ChevronDown20Regular, ChevronRight20Regular } from '@fluentui/react-icons';
import {
  VNotification, VTank,
} from '../../../services';
import { mapToBadgePropsList } from '../../../common/helper';
import BadgeGroup from '../../../components/badgeGroup';
import { EnumRightBarType } from '../../../common/models';

const TankTopBar: React.FC<{
  tank: VTank | undefined | null,
  rightBarType: EnumRightBarType | null,
  notifications?: VNotification[],
  onChangeRightBarType: (t: EnumRightBarType) => void,
}> = ({
  tank,
  rightBarType,
  onChangeRightBarType,
}) => (
  <div style={{
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
  }} >
    <div style={{
      display: 'flex',
      alignItems: 'flex-end',
    }} >
      {
        tank
          ? <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <Text size={500} weight='bold'>{tank?.name}</Text>
            <BadgeGroup badges={mapToBadgePropsList(tank)} extended={true} />
          </div> : null
      }
    </div>
    <div style={{
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      gap: 20,
    }} >
      <Button
        onClick={() => onChangeRightBarType(EnumRightBarType.History)}
        icon={
          rightBarType === EnumRightBarType.History
            ? <ChevronDown20Regular/> : <ChevronRight20Regular/>
        }
        iconPosition="after"
      >
        {EnumRightBarType.History}
      </Button>
      <Button
        onClick={() => onChangeRightBarType(EnumRightBarType.Settings)}
        icon={
          rightBarType === EnumRightBarType.Settings
            ? <ChevronDown20Regular/> : <ChevronRight20Regular/>
        }
        iconPosition="after"
      >
        {EnumRightBarType.Settings}
      </Button>
    </div>
  </div>
);

export default TankTopBar;
