/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const VNotificationCategoryEnum = {
    Alarming: 'Alarming',
    Warning: 'Warning',
    Muted: 'Muted',
    Config: 'Config'
} as const;
export type VNotificationCategoryEnum = typeof VNotificationCategoryEnum[keyof typeof VNotificationCategoryEnum];


export function VNotificationCategoryEnumFromJSON(json: any): VNotificationCategoryEnum {
    return VNotificationCategoryEnumFromJSONTyped(json, false);
}

export function VNotificationCategoryEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): VNotificationCategoryEnum {
    return json as VNotificationCategoryEnum;
}

export function VNotificationCategoryEnumToJSON(value?: VNotificationCategoryEnum | null): any {
    return value as any;
}

