/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  VNotification,
  VQueryNotificationsRequestParams,
  VUpdateNotificationRequestParams,
} from '../models/index';
import {
    VNotificationFromJSON,
    VNotificationToJSON,
    VQueryNotificationsRequestParamsFromJSON,
    VQueryNotificationsRequestParamsToJSON,
    VUpdateNotificationRequestParamsFromJSON,
    VUpdateNotificationRequestParamsToJSON,
} from '../models/index';

export interface QueryNotificationsRequest {
    vQueryNotificationsRequestParams: VQueryNotificationsRequestParams;
}

export interface UpdateNotificationRequest {
    notificationId: string;
    vUpdateNotificationRequestParams: VUpdateNotificationRequestParams;
}

/**
 * 
 */
export class NotificationApi extends runtime.BaseAPI {

    /**
     * Query notifications by all kinds of params
     * Query notifications
     */
    async queryNotificationsRaw(requestParameters: QueryNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VNotification>>> {
        if (requestParameters.vQueryNotificationsRequestParams === null || requestParameters.vQueryNotificationsRequestParams === undefined) {
            throw new runtime.RequiredError('vQueryNotificationsRequestParams','Required parameter requestParameters.vQueryNotificationsRequestParams was null or undefined when calling queryNotifications.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/queryNotifications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VQueryNotificationsRequestParamsToJSON(requestParameters.vQueryNotificationsRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VNotificationFromJSON));
    }

    /**
     * Query notifications by all kinds of params
     * Query notifications
     */
    async queryNotifications(requestParameters: QueryNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VNotification>> {
        const response = await this.queryNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a notification by notification Id
     * Update a notification
     */
    async updateNotificationRaw(requestParameters: UpdateNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VNotification>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling updateNotification.');
        }

        if (requestParameters.vUpdateNotificationRequestParams === null || requestParameters.vUpdateNotificationRequestParams === undefined) {
            throw new runtime.RequiredError('vUpdateNotificationRequestParams','Required parameter requestParameters.vUpdateNotificationRequestParams was null or undefined when calling updateNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/{notificationId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VUpdateNotificationRequestParamsToJSON(requestParameters.vUpdateNotificationRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VNotificationFromJSON(jsonValue));
    }

    /**
     * Update a notification by notification Id
     * Update a notification
     */
    async updateNotification(requestParameters: UpdateNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VNotification> {
        const response = await this.updateNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
