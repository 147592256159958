import { ChevronRight12Regular, Home12Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbsProps {
  breadcrumbs: { label: string, path: string }[],
  title: string,
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbs,
  title,
}) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
    <Home12Regular />
    {
      breadcrumbs.map((link) => (
        <React.Fragment key={link.path} >
          <Link to={link.path} style={{ textDecoration: 'none' }}>{link.label}</Link>
          <ChevronRight12Regular />
        </React.Fragment>
      ))
    }
    {title}
  </div>
);

export default Breadcrumbs;
