/* eslint-disable no-param-reassign */
import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  current,
} from '@reduxjs/toolkit';
import apiServices, { VClinic, VUser, VUserClinicRoleEnum } from '../../services';
import { Result } from '../../common/models';
import appConfig from '../../config.app';
import { RootState } from '../../store';
import { formatErrorMsg } from '../../common/helper';

interface ClinicInput {
  name: string;
  address: string;
  accessToken: string;
}

interface ClinicState {
  users: VUser[];
  mappedUsers: { [key:string]: VUser };
  selectedSiteAdmins: VUser[];
  selectedUsers: VUser[];
  clinics: VClinic[];
  selectedClinicId: string;
  clinicInput: ClinicInput;
  savable: boolean;
  error: string | null;
}

const initialState: ClinicState = {
  users: [],
  mappedUsers: {},
  selectedSiteAdmins: [],
  selectedUsers: [],
  clinics: [],
  selectedClinicId: '',
  clinicInput: {
    name: '',
    address: '',
    accessToken: '',
  },
  savable: false,
  error: null,
};

interface IPayloadSelectUser {
  role: VUserClinicRoleEnum,
  userId: string,
}

// Handle actions in your reducers
const clinicSlice = createSlice({
  name: 'clinicManage',
  initialState,
  reducers: {
    fetchClinics: (state, action: PayloadAction<VClinic[]>) => {
      state.clinics = action.payload;
      state.selectedClinicId = '';
    },
    fetchUsers: (state, action: PayloadAction<VUser[]>) => {
      state.users = action.payload;
      const mappedUsers: { [key:string]: VUser } = {};
      state.users.forEach((x) => {
        mappedUsers[x.id] = x;
      });
      state.mappedUsers = mappedUsers;
    },
    changeClinicName: (state, action: PayloadAction<string>) => {
      state.clinicInput.name = action.payload;
      state.savable = true;
    },
    changeClinicAddress: (state, action: PayloadAction<string>) => {
      state.clinicInput.address = action.payload;
      state.savable = true;
    },
    changeClinic: (state, action: PayloadAction<string>) => {
      state.savable = false;
      const clinicId = action.payload;
      // Check if the clinic exists
      const clinic = current(state.clinics).find((c) => c.id === clinicId);
      if (!clinic) return;
      // Set basic info
      state.selectedClinicId = clinicId;
      state.clinicInput.name = clinic.name ?? '';
      state.clinicInput.address = clinic.address ?? '';
      state.clinicInput.accessToken = clinic.accessToken ?? '';
      // Set users belongs to the clinic, different users may have different role in the clinic
      state.selectedSiteAdmins = [];
      state.selectedUsers = [];
      clinic.users?.forEach((x) => {
        if (x.userId && state.mappedUsers[x.userId]) {
          const userId = x.userId as string;
          const user = state.mappedUsers[userId];
          if (x.role === VUserClinicRoleEnum.SiteAdmin) {
            state.selectedSiteAdmins.push(user);
          } else if (x.role === VUserClinicRoleEnum.User) {
            state.selectedUsers.push(user);
          }
        }
      });
    },
    selectUser: (state, action: PayloadAction<IPayloadSelectUser>) => {
      state.savable = true;
      const { role, userId } = action.payload;
      // Check if the user exists in users
      const selectedUser = state.users.find((user) => user.id === userId);
      if (!selectedUser) {
        return;
      }

      // Check if the user already exists in selected users and then add
      if (role === VUserClinicRoleEnum.SiteAdmin) {
        if (state.selectedSiteAdmins.find((user) => user.id === userId)) {
          return;
        }
        state.selectedSiteAdmins = [...state.selectedSiteAdmins, selectedUser];
      } else if (role === VUserClinicRoleEnum.User) {
        if (state.selectedUsers.find((user) => user.id === userId)) {
          return;
        }
        state.selectedUsers = [...state.selectedUsers, selectedUser];
      }
    },
    unselectUser: (state, action: PayloadAction<IPayloadSelectUser>) => {
      state.savable = true;
      const { role, userId } = action.payload;
      // User shouldn't be able to remove themselves
      if (userId === apiServices.getCurrentUser()?.id) return;
      if (role === VUserClinicRoleEnum.SiteAdmin) {
        state.selectedSiteAdmins = state.selectedSiteAdmins.filter((user) => user.id !== userId);
      } else if (role === VUserClinicRoleEnum.User) {
        state.selectedUsers = state.selectedUsers.filter((user) => user.id !== userId);
      }
    },
    reset: (state) => {
      state.savable = false;
      state.clinicInput.name = '';
      state.clinicInput.address = '';
      state.clinicInput.accessToken = '';
      state.selectedSiteAdmins = [];
      state.selectedUsers = [];
      state.selectedClinicId = '';
    },
    fail: (state, action: PayloadAction<string | null>) => {
      state.error = formatErrorMsg(action.payload);
    },
  },
});

export const fetchUsers = createAsyncThunk(
  'clinicManage/fetchUsers',
  async (_, thunkAPI) => {
    try {
      const users = await apiServices.getUserApi().getNormalUsers();
      thunkAPI.dispatch(clinicSlice.actions.fetchUsers(users));
    } catch (err) {
      thunkAPI.dispatch(clinicSlice.actions.fail(
        (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR,
      ));
    }
  },
);

export const fetchClinics = createAsyncThunk(
  'clinicManage/fetchClinics',
  async (_, thunkAPI) => {
    try {
      const clinics = await apiServices.getClinicApi().getClinics();
      thunkAPI.dispatch(clinicSlice.actions.fetchClinics(clinics));
    } catch (err) {
      thunkAPI.dispatch(clinicSlice.actions.fail(
        (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR,
      ));
    }
  },
);

export const addClinic = createAsyncThunk<Result>(
  'clinicManage/addClinic',
  async (_, thunkAPI) => {
    try {
      const clinicState = (thunkAPI.getState() as RootState).clinicManage as ClinicState;
      await apiServices.getClinicApi().addClinic({
        vAddClinicRequestParams: {
          name: clinicState.clinicInput.name,
          address: clinicState.clinicInput.address,
          active: true,
          siteAdminIds: clinicState.selectedSiteAdmins.map((user) => user.id),
          userIds: clinicState.selectedUsers.map((user) => user.id),
        },
      });
      return { result: true, message: appConfig.message.CLINIC_ADDED };
    } catch (err) {
      const errMsg = (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR;
      thunkAPI.dispatch(clinicSlice.actions.fail(errMsg));
      return { result: false, message: errMsg };
    }
  },
);

export const updateClinic = createAsyncThunk<Result>(
  'clinicManage/updateClinic',
  async (_, thunkAPI) => {
    try {
      const clinicState = (thunkAPI.getState() as RootState).clinicManage as ClinicState;
      await apiServices.getClinicApi().updateClinic({
        clinicId: clinicState.selectedClinicId,
        vUpdateClinicRequestParams: {
          name: clinicState.clinicInput.name,
          address: clinicState.clinicInput.address,
          active: true,
          siteAdminIds: clinicState.selectedSiteAdmins.map((user) => user.id),
          userIds: clinicState.selectedUsers.map((user) => user.id),
        },
      });
      return { result: true, message: appConfig.message.CLINIC_UPDATED };
    } catch (err) {
      const errMsg = (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR;
      thunkAPI.dispatch(clinicSlice.actions.fail(errMsg));
      return { result: false, message: errMsg };
    }
  },
);

export const generateToken = createAsyncThunk<Result>(
  'clinicManage/generateToken',
  async (_, thunkAPI) => {
    try {
      const clinicState = (thunkAPI.getState() as RootState).clinicManage as ClinicState;
      await apiServices.getClinicApi().generateToken({
        clinicId: clinicState.selectedClinicId,
      });
      return { result: true, message: appConfig.message.CLINIC_TOKEN_GENERATED };
    } catch (err) {
      const errMsg = (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR;
      thunkAPI.dispatch(clinicSlice.actions.fail(errMsg));
      return { result: false, message: errMsg };
    }
  },
);

export const {
  selectUser,
  unselectUser,
  changeClinic,
  changeClinicName,
  changeClinicAddress,
  reset,
} = clinicSlice.actions;
export default clinicSlice.reducer;
