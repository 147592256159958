/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VUpdateClinicRequestParams
 */
export interface VUpdateClinicRequestParams {
    /**
     * Name of the clinic
     * @type {string}
     * @memberof VUpdateClinicRequestParams
     */
    name?: string;
    /**
     * Address of the clinic
     * @type {string}
     * @memberof VUpdateClinicRequestParams
     */
    address?: string;
    /**
     * IP of the clinic
     * @type {string}
     * @memberof VUpdateClinicRequestParams
     */
    ipAddress?: string;
    /**
     * Timezone of the clinic
     * @type {string}
     * @memberof VUpdateClinicRequestParams
     */
    timezone?: string;
    /**
     * If no readings from a tank in this number of seconds, will trigger alarm
     * @type {number}
     * @memberof VUpdateClinicRequestParams
     */
    offlineThreshold?: number;
    /**
     * Mute the tank for this value when clicking the mute button
     * @type {number}
     * @memberof VUpdateClinicRequestParams
     */
    mutePeriod?: number;
    /**
     * Resend a notification if the current time exceeds notificationRetriggerTimeout seconds from RtnModel.sendTime
     * @type {number}
     * @memberof VUpdateClinicRequestParams
     */
    notificationRetriggerTimeout?: number;
    /**
     * When got notification-needed readings more than `notificationTriggerCount`, trigger notifications
     * @type {number}
     * @memberof VUpdateClinicRequestParams
     */
    notificationTriggerCount?: number;
    /**
     * When got notification-not-needed readings more than `notificationResetCount`, reset to the initial state
     * @type {number}
     * @memberof VUpdateClinicRequestParams
     */
    notificationResetCount?: number;
    /**
     * To authorize IoT server and Relay server for each clinic
     * @type {string}
     * @memberof VUpdateClinicRequestParams
     */
    accessToken?: string;
    /**
     * The clinic is active or not
     * @type {boolean}
     * @memberof VUpdateClinicRequestParams
     */
    active?: boolean;
    /**
     * Site admin IDs to be added to the clinic
     * @type {Array<string>}
     * @memberof VUpdateClinicRequestParams
     */
    siteAdminIds?: Array<string>;
    /**
     * User IDs to be added to the clinic
     * @type {Array<string>}
     * @memberof VUpdateClinicRequestParams
     */
    userIds?: Array<string>;
    /**
     * Display IDs to be added to the clinic
     * @type {Array<string>}
     * @memberof VUpdateClinicRequestParams
     */
    displayIds?: Array<string>;
}

/**
 * Check if a given object implements the VUpdateClinicRequestParams interface.
 */
export function instanceOfVUpdateClinicRequestParams(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VUpdateClinicRequestParamsFromJSON(json: any): VUpdateClinicRequestParams {
    return VUpdateClinicRequestParamsFromJSONTyped(json, false);
}

export function VUpdateClinicRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VUpdateClinicRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'ipAddress': !exists(json, 'ipAddress') ? undefined : json['ipAddress'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'offlineThreshold': !exists(json, 'offlineThreshold') ? undefined : json['offlineThreshold'],
        'mutePeriod': !exists(json, 'mutePeriod') ? undefined : json['mutePeriod'],
        'notificationRetriggerTimeout': !exists(json, 'notificationRetriggerTimeout') ? undefined : json['notificationRetriggerTimeout'],
        'notificationTriggerCount': !exists(json, 'notificationTriggerCount') ? undefined : json['notificationTriggerCount'],
        'notificationResetCount': !exists(json, 'notificationResetCount') ? undefined : json['notificationResetCount'],
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'siteAdminIds': !exists(json, 'siteAdminIds') ? undefined : json['siteAdminIds'],
        'userIds': !exists(json, 'userIds') ? undefined : json['userIds'],
        'displayIds': !exists(json, 'displayIds') ? undefined : json['displayIds'],
    };
}

export function VUpdateClinicRequestParamsToJSON(value?: VUpdateClinicRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'address': value.address,
        'ipAddress': value.ipAddress,
        'timezone': value.timezone,
        'offlineThreshold': value.offlineThreshold,
        'mutePeriod': value.mutePeriod,
        'notificationRetriggerTimeout': value.notificationRetriggerTimeout,
        'notificationTriggerCount': value.notificationTriggerCount,
        'notificationResetCount': value.notificationResetCount,
        'accessToken': value.accessToken,
        'active': value.active,
        'siteAdminIds': value.siteAdminIds,
        'userIds': value.userIds,
        'displayIds': value.displayIds,
    };
}

