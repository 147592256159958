import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import apiServices, { VCurrentUser } from '../services';

const ProtectedRoutes: React.FC = () => {
  const location = useLocation();
  // The full path is to let browsers redirect to the path after users
  // logging in the app successfully, which is a good user experience.
  // encodeURIComponent is to handle special characters in the full path.
  const fullPath = encodeURIComponent(location.pathname + location.search);
  const currentUser: VCurrentUser | null = apiServices.getCurrentUser();
  return (
    currentUser ? <Outlet /> : <Navigate to={ `/login?from=${fullPath}` } />
  );
};

export default ProtectedRoutes;
