/* eslint-disable no-param-reassign */
// https://hackmd.io/@barrystone/redux_redux-toolkit-update-state-in-slice
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import apiServices, {
  VCaddy,
  VClinic,
  VNotification,
  VNotificationStatusEnum,
  VLiteReading,
  VTank,
} from '../../services';
import { EnumRightBarType, IDashboardFilter, ILoadingStatus } from '../../common/models';
import appConfig from '../../config.app';
import { RootState } from '../../store';
import { getDiffDaysStrDate, getStartOrEndOfDay, getStrCurrTimePlus } from '../../common/utils';

interface ClinicDashboardState {
  clinic: VClinic | null;
  tank: VTank | null;
  caddy: VCaddy | null;
  tanks: VTank[];
  notifications: VNotification[];
  newNotifications: VNotification[];
  readings: VLiteReading[];
  filteredTanks: VTank[];
  statusFilter: IDashboardFilter;
  startDateFilter: string | null;
  endDateFilter: string | null;
  rightBarType: EnumRightBarType | null;
  error: string | null;
  tanksLoadingStatus: ILoadingStatus | null;
  tankLoadingStatus: ILoadingStatus | null;
}

const initialState: ClinicDashboardState = {
  clinic: null,
  tank: null,
  caddy: null,
  tanks: [],
  notifications: [],
  newNotifications: [],
  readings: [],
  filteredTanks: [],
  statusFilter: {
    normal: {
      name: appConfig.statusCategories.normal.name,
      color: appConfig.statusCategories.normal.color,
      icon: appConfig.statusCategories.normal.icon,
      isSelected: false,
      count: 0,
    },
    alarm: {
      name: appConfig.statusCategories.alarming.name,
      color: appConfig.statusCategories.alarming.color,
      icon: appConfig.statusCategories.alarming.icon,
      isSelected: false,
      count: 0,
    },
    warning: {
      name: appConfig.statusCategories.warning.name,
      color: appConfig.statusCategories.warning.color,
      icon: appConfig.statusCategories.warning.icon,
      isSelected: false,
      count: 0,
    },
    muted: {
      name: appConfig.statusCategories.muted.name,
      color: appConfig.statusCategories.muted.color,
      icon: appConfig.statusCategories.muted.icon,
      isSelected: false,
      count: 0,
    },
    inactive: {
      name: appConfig.statusCategories.inactive.name,
      color: appConfig.statusCategories.inactive.color,
      icon: appConfig.statusCategories.inactive.icon,
      isSelected: false,
      count: 0,
    },
  },
  startDateFilter: getDiffDaysStrDate(-30),
  endDateFilter: getDiffDaysStrDate(),
  rightBarType: EnumRightBarType.Settings,
  error: null,
  tanksLoadingStatus: null,
  tankLoadingStatus: null,
};

function filterTanks(tanks: VTank[], statusFilter: IDashboardFilter) {
  const filteredTanks: VTank[] = [];
  tanks.forEach((tank) => {
    const isInInactive = appConfig.statusCategories.inactive.check(tank);
    if (!statusFilter.normal.isSelected
      && !statusFilter.alarm.isSelected
      && !statusFilter.warning.isSelected
      && !statusFilter.muted.isSelected
      && !statusFilter.inactive.isSelected
      && !isInInactive) {
      filteredTanks.push(tank);
      return;
    }
    if (
      (statusFilter.normal.isSelected
        && appConfig.statusCategories.normal.check(tank))
      || (statusFilter.alarm.isSelected
        && appConfig.statusCategories.alarming.check(tank))
      || (statusFilter.warning.isSelected
        && appConfig.statusCategories.warning.check(tank))
      || (statusFilter.muted.isSelected
        && appConfig.statusCategories.muted.check(tank))
      || (statusFilter.inactive.isSelected
        && isInInactive)
    ) {
      filteredTanks.push(tank);
    }
  });
  return filteredTanks;
}

function categorizeTanks(tanks: VTank[], statusFilter: IDashboardFilter) {
  statusFilter.normal.count = 0;
  statusFilter.alarm.count = 0;
  statusFilter.warning.count = 0;
  statusFilter.muted.count = 0;
  statusFilter.inactive.count = 0;
  tanks.forEach((tank) => {
    if (appConfig.statusCategories.normal.check(tank)) {
      statusFilter.normal.count++;
    }
    if (appConfig.statusCategories.alarming.check(tank)) {
      statusFilter.alarm.count++;
    }
    if (appConfig.statusCategories.warning.check(tank)) {
      statusFilter.warning.count++;
    }
    if (appConfig.statusCategories.muted.check(tank)) {
      statusFilter.muted.count++;
    }
    if (appConfig.statusCategories.inactive.check(tank)) {
      statusFilter.inactive.count++;
    }
  });
}

function getNewNotifications(notifications: VNotification[], comingNotifications: VNotification[]) {
  return comingNotifications.filter(
    (notification) => !notifications.find((n) => (n.id === notification.id)),
  );
}

// Handle actions in your reducers
const clinicDashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    fetchClinic: (state, action: PayloadAction<VClinic>) => {
      const clinic = action.payload;
      state.clinic = clinic;
    },

    fetchTanks: (state, action: PayloadAction<VTank[]>) => {
      state.tanks = action.payload;
      categorizeTanks(state.tanks, state.statusFilter);
      state.filteredTanks = filterTanks(state.tanks, state.statusFilter);
    },

    fetchTank: (state, action: PayloadAction<VTank>) => {
      state.tank = action.payload;
    },

    fetchCaddy: (state, action: PayloadAction<VCaddy>) => {
      state.caddy = action.payload;
    },

    fetchNotifications: (state, action: PayloadAction<VNotification[]>) => {
      // If already having notifications, need to check if there is new notifications
      state.newNotifications = [];
      if (state.notifications.length > 0 && action.payload.length > 0) {
        state.newNotifications = getNewNotifications(state.notifications, action.payload);
      }
      state.notifications = action.payload;
    },

    removeNewNotification: (state, action: PayloadAction<string>) => {
      state.newNotifications = state.newNotifications.filter((n) => n.id !== action.payload);
    },

    fetchNotification: (state, action: PayloadAction<VNotification>) => {
      const newNotification = action.payload;
      const notification = state.notifications.find((n) => n.id === newNotification.id);
      if (notification) notification.status = newNotification.status;
    },

    fetchReadings: (state, action: PayloadAction<VLiteReading[]>) => {
      state.readings = action.payload;
    },

    toggleFilter: (state, action: PayloadAction<string>) => {
      state.statusFilter[
        action.payload
      ].isSelected = !state.statusFilter[
        action.payload
      ].isSelected;
      state.filteredTanks = filterTanks(state.tanks, state.statusFilter);
    },

    clearFilter: (state) => {
      Object.keys(state.statusFilter).forEach((key) => {
        state.statusFilter[key].isSelected = false;
      });
      state.filteredTanks = filterTanks(state.tanks, state.statusFilter);
    },

    changeStartDate: (state, action: PayloadAction<string | undefined | null>) => {
      state.startDateFilter = action.payload ? action.payload : null;
    },

    changeEndDate: (state, action: PayloadAction<string | undefined | null>) => {
      state.endDateFilter = action.payload ? action.payload : null;
    },

    changeRightBarType: (state, action: PayloadAction<EnumRightBarType>) => {
      state.rightBarType = action.payload === state.rightBarType ? null : action.payload;
    },

    updateTanksLoadingStatus: (state, action: PayloadAction<ILoadingStatus | null>) => {
      state.tanksLoadingStatus = action.payload;
    },

    updateTankLoadingStatus: (state, action: PayloadAction<ILoadingStatus | null>) => {
      state.tankLoadingStatus = action.payload;
    },

    reset: (state) => {
      state.clinic = null;
      state.tank = null;
      state.caddy = null;
      state.tanks = [];
      state.notifications = [];
      state.newNotifications = [];
      state.readings = [];
      state.filteredTanks = [];
      state.tanksLoadingStatus = null;
      state.tankLoadingStatus = null;
      state.statusFilter.normal.count = 0;
      state.statusFilter.alarm.count = 0;
      state.statusFilter.warning.count = 0;
      state.statusFilter.muted.count = 0;
      state.statusFilter.inactive.count = 0;
      state.startDateFilter = getDiffDaysStrDate(-30);
      state.endDateFilter = getDiffDaysStrDate();
      state.error = null;
      state.tanksLoadingStatus = null;
      state.tankLoadingStatus = null;
    },

    fail: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const fetchClinic = createAsyncThunk<void, { clinicId: string }>(
  'dashboard/fetchClinic',
  async (params, thunkAPI) => {
    try {
      const clinic = await apiServices.getClinicApi().getClinic({ clinicId: params.clinicId });
      thunkAPI.dispatch(clinicDashboardSlice.actions.fetchClinic(clinic));
    } catch (err) {
      thunkAPI.dispatch(clinicDashboardSlice.actions.fail(
        (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR,
      ));
    }
  },
);

export const fetchTanks = createAsyncThunk<void, { clinicId: string, needLoading?: boolean }>(
  'dashboard/fetchTanks',
  async (params, thunkAPI) => {
    try {
      if (params.needLoading) {
        thunkAPI.dispatch(clinicDashboardSlice.actions.updateTanksLoadingStatus('loading'));
      }
      const tanks = await apiServices.getTankApi().queryTanks({
        vQueryTanksRequestParams: {
          clinicId: params.clinicId,
        },
      });
      thunkAPI.dispatch(clinicDashboardSlice.actions.fetchTanks(tanks));
      thunkAPI.dispatch(clinicDashboardSlice.actions.updateTanksLoadingStatus('success'));
    } catch (err) {
      thunkAPI.dispatch(clinicDashboardSlice.actions.fail(
        (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR,
      ));
      thunkAPI.dispatch(clinicDashboardSlice.actions.updateTanksLoadingStatus('error'));
    }
  },
);

export const fetchNotifications = createAsyncThunk<void, { clinicId?: string, tankId?: string }>(
  'dashboard/fetchNotifications',
  async (params, thunkAPI) => {
    try {
      const notifications = await apiServices.getNotificationApi().queryNotifications({
        vQueryNotificationsRequestParams: {
          clinicId: params.clinicId,
          tankId: params.tankId,
        },
      });
      thunkAPI.dispatch(clinicDashboardSlice.actions.fetchNotifications(notifications));
    } catch (err) {
      thunkAPI.dispatch(clinicDashboardSlice.actions.fail(
        (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR,
      ));
    }
  },
);

export const fetchReadings = createAsyncThunk<void, { tankId?: string }>(
  'dashboard/fetchReadings',
  async (params, thunkAPI) => {
    try {
      if (!params.tankId) return;
      const clinicState = (
        thunkAPI.getState() as RootState
      ).dashboard as ClinicDashboardState;
      const readings = await apiServices.getReadingApi().queryLiteReadings({
        vQueryReadingsRequestParams: {
          tankId: params.tankId,
          startDate: getStartOrEndOfDay(true, clinicState.startDateFilter) ?? undefined,
          endDate: getStartOrEndOfDay(false, clinicState.endDateFilter) ?? undefined,
        },
      });
      thunkAPI.dispatch(clinicDashboardSlice.actions.fetchReadings(readings));
    } catch (err) {
      thunkAPI.dispatch(clinicDashboardSlice.actions.fail(
        (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR,
      ));
    }
  },
);

export const updateNotification = createAsyncThunk<void, {
  notificationId: string, status: VNotificationStatusEnum }
>(
  'dashboard/updateNotification',
  async (params, thunkAPI) => {
    try {
      const notification = await apiServices.getNotificationApi().updateNotification({
        notificationId: params.notificationId,
        vUpdateNotificationRequestParams: {
          status: params.status,
        },
      });
      thunkAPI.dispatch(clinicDashboardSlice.actions.fetchNotification(notification));
    } catch (err) {
      thunkAPI.dispatch(clinicDashboardSlice.actions.fail(
        (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR,
      ));
    }
  },
);

export const fetchTankAndClinic = createAsyncThunk<
  void,
  { tankId?: string, needLoading?: boolean }
>(
  'dashboard/fetchTankAndClinic',
  async (params, thunkAPI) => {
    try {
      if (!params.tankId) return;
      if (params.needLoading) {
        thunkAPI.dispatch(clinicDashboardSlice.actions.updateTankLoadingStatus('loading'));
      }
      const tank = await apiServices.getTankApi().getTank({ tankId: params.tankId });
      thunkAPI.dispatch(clinicDashboardSlice.actions.fetchTank(tank));
      const clinic = await apiServices.getClinicApi().getClinic({ clinicId: tank.clinicId });
      thunkAPI.dispatch(clinicDashboardSlice.actions.fetchClinic(clinic));
      if (tank.caddyId) {
        const caddy = await apiServices.getCaddyApi().getCaddy({ caddyId: tank.caddyId });
        thunkAPI.dispatch(clinicDashboardSlice.actions.fetchCaddy(caddy));
      }
      thunkAPI.dispatch(clinicDashboardSlice.actions.updateTankLoadingStatus('success'));
    } catch (err) {
      thunkAPI.dispatch(clinicDashboardSlice.actions.fail(
        (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR,
      ));
      thunkAPI.dispatch(clinicDashboardSlice.actions.updateTankLoadingStatus('error'));
    }
  },
);

export const muteTank = createAsyncThunk(
  'dashboard/muteTank',
  async (_, thunkAPI) => {
    try {
      const clinicState = (
        thunkAPI.getState() as RootState
      ).dashboard as ClinicDashboardState;
      if (clinicState.tank && clinicState.clinic && clinicState.clinic.mutePeriod) {
        const tank = await apiServices.getTankApi().updateTank({
          tankId: clinicState.tank.id,
          vUpdateTankRequestParams: {
            muteUntilTime: getStrCurrTimePlus(clinicState.clinic.mutePeriod),
          },
        });
        thunkAPI.dispatch(clinicDashboardSlice.actions.fetchTank(tank));
      }
    } catch (err) {
      thunkAPI.dispatch(clinicDashboardSlice.actions.fail(
        (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR,
      ));
    }
  },
);

export const unmuteTank = createAsyncThunk(
  'dashboard/unmuteTank',
  async (_, thunkAPI) => {
    try {
      const clinicState = (
        thunkAPI.getState() as RootState
      ).dashboard as ClinicDashboardState;
      if (clinicState.tank && clinicState.clinic) {
        const tank = await apiServices.getTankApi().updateTank({
          tankId: clinicState.tank.id,
          vUpdateTankRequestParams: {
            muteUntilTime: '',
          },
        });
        thunkAPI.dispatch(clinicDashboardSlice.actions.fetchTank(tank));
      }
    } catch (err) {
      thunkAPI.dispatch(clinicDashboardSlice.actions.fail(
        (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR,
      ));
    }
  },
);

export const {
  toggleFilter,
  clearFilter,
  removeNewNotification,
  changeStartDate,
  changeEndDate,
  changeRightBarType,
  reset,
} = clinicDashboardSlice.actions;
export default clinicDashboardSlice.reducer;
