import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { VNotificationStatusEnum } from '../../services';
import { useAppDispatch, useAppSelector } from '../../hook';
import {
  changeEndDate,
  changeStartDate,
  fetchNotifications,
  fetchReadings,
  fetchTankAndClinic,
  updateNotification,
  muteTank,
  unmuteTank,
  changeRightBarType,
} from './_slice';
import TankStats from './features/tankStats';
import TankSettings from './features/tankSettings';
import NotificationList from './features/notificationList';
import ReadingsChart from './features/readingsChart';
import Layout3 from '../../layouts/layout3';
import TankTopBar from './features/tankTopBar';
import Breadcrumbs from '../../components/breadcrumbs';
import appConfig from '../../config.app';
import { EnumRightBarType } from '../../common/models';

interface RouteParams {
  tankId: string;
  [key: string]: string;
}

const TankDashboard: React.FC = () => {
  const dashboardState = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  const { tankId } = useParams<RouteParams>();

  useEffect(() => {
    dispatch(fetchTankAndClinic({ tankId, needLoading: true }));
    dispatch(fetchReadings({ tankId }));
    dispatch(fetchNotifications({ tankId }));

    const intervalId = setInterval(() => {
      dispatch(fetchTankAndClinic({ tankId }));
      dispatch(fetchReadings({ tankId }));
      dispatch(fetchNotifications({ tankId }));
    }, appConfig.pollIntervalSeconds * 1000);

    return () => clearInterval(intervalId);
  }, [dispatch, tankId]);

  const getBreadcrumbs = () => {
    const breadcrumbs = [{ label: 'Clinics', path: '/clinics' }];
    if (dashboardState.clinic) {
      breadcrumbs.push({
        label: dashboardState.clinic?.name,
        path: `/clinic-dashboard/${dashboardState.clinic?.id}`,
      });
    }
    return breadcrumbs;
  };

  return (
    <Layout3
      breadcrumbsSlot={
        <Breadcrumbs
          breadcrumbs={getBreadcrumbs()}
          title={dashboardState.tank ? dashboardState.tank.name : ''}
        />
      }
      contentTopSlot={
        <TankStats
          clinic={dashboardState.clinic}
          tank={dashboardState.tank}
          caddy={dashboardState.caddy}
          status={dashboardState.tankLoadingStatus}
          onMute={() => dispatch(muteTank())}
          onUnmute={() => dispatch(unmuteTank())}
        />
      }
      contentSlot={
        <ReadingsChart
          tank={dashboardState.tank}
          readings={dashboardState.readings}
          startDate={dashboardState.startDateFilter}
          endDate={dashboardState.endDateFilter}
          onChangeStartDate={(newDate) => dispatch(changeStartDate(newDate))}
          onChangeEndDate={(newDate) => dispatch(changeEndDate(newDate))}
          onFitlerReadings={
            () => dispatch(
              fetchReadings({ tankId }),
            )
          }
        />
      }
      topSlot={
        <TankTopBar
          tank={ dashboardState.tank }
          rightBarType={dashboardState.rightBarType}
          notifications={dashboardState.notifications}
          onChangeRightBarType={(x) => dispatch(changeRightBarType(x))}
        />
      }
      rightSlot1Hide={ dashboardState.rightBarType !== EnumRightBarType.History }
      rightSlot1={
        <NotificationList
          onDismiss={
            (notificationId) => dispatch(
              updateNotification({
                notificationId,
                status: VNotificationStatusEnum.Dismissed,
              }),
            )
          }
          onSwitchStatus={
            (notificationId, status) => dispatch(
              updateNotification({
                notificationId,
                status,
              }),
            )
          }
          style={{}}
          notifications={dashboardState.notifications}
        />
      }
      rightSlot2Hide={ dashboardState.rightBarType !== EnumRightBarType.Settings }
      rightSlot2={
        <TankSettings
          clinic={ dashboardState.clinic }
          tank={ dashboardState.tank }
          caddy={ dashboardState.caddy }
        />
      }
    />
  );
};

export default TankDashboard;
