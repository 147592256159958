import {
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
} from '@fluentui/react-components';
import * as React from 'react';
import appConfig from '../config.app';

interface ToastBoxProps {
  message: string | undefined | null;
  isSuccess: boolean;
}

const ToastBox: React.FC<ToastBoxProps> = ({
  message,
  isSuccess,
}) => {
  const toasterId = useId('toaster');
  const { dispatchToast } = useToastController(toasterId);

  React.useEffect(() => {
    if (message) {
      dispatchToast(
        <Toast>
          <ToastTitle>{ message }</ToastTitle>
        </Toast>,
        {
          timeout: appConfig.toastTimeoutSeconds * 1000,
          intent: isSuccess ? 'success' : 'warning',
        },
      );
    }
  }, [dispatchToast, message, isSuccess]);

  return (
    <Toaster toasterId={toasterId} />
  );
};

export default ToastBox;
