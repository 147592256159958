import React from 'react';
import { Card, InfoLabel, Text } from '@fluentui/react-components';
import { OptionEntity } from '../common/models';
import BetterDropdown from './betterDropdown';

interface ISelectBarProps {
  title: string;
  placeholder: string;
  options: OptionEntity[];
  selectedId?: string;
  onChange: (id: string) => void;
  noneOption?: string;
  defaultValue?: string;
  integrated?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

const SelectBar: React.FC<ISelectBarProps> = (props) => {
  const optionsWithNone = props.noneOption
    ? [
      {
        id: '',
        label: props.noneOption,
      },
      ...props.options,
    ]
    : props.options;

  const selectMain = () => <div style={{
    display: 'flex',
  }}>
    <div style={{
      flex: 1,
      display: 'flex',
      alignItems: 'center',
    }}>
      <Text weight="bold" size={props.integrated ? 300 : 400}>
        {props.title} { props.tooltip ? <InfoLabel info={props.tooltip}></InfoLabel> : null }
      </Text>
    </div>
    <div style={{
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    }}>
      <BetterDropdown
        title={props.title}
        placeholder={props.placeholder}
        options={optionsWithNone}
        selectedOptionId={props.selectedId}
        onOptionSelect={(x) => props.onChange(x)}
        disabled={props.disabled}
      />
    </div>
  </div>;

  if (props.integrated) {
    return (
      <>
        {selectMain()}
      </>
    );
  }
  return (
    <Card style={{
      padding: 20,
      overflow: 'visible',
    }}>
      {selectMain()}
    </Card>
  );
};

export default SelectBar;
