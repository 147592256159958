/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VUserClinic } from './VUserClinic';
import {
    VUserClinicFromJSON,
    VUserClinicFromJSONTyped,
    VUserClinicToJSON,
} from './VUserClinic';

/**
 * 
 * @export
 * @interface VCurrentUser
 */
export interface VCurrentUser {
    /**
     * User ID
     * @type {string}
     * @memberof VCurrentUser
     */
    id: string;
    /**
     * Name of the display (Only useful for displays)
     * @type {string}
     * @memberof VCurrentUser
     */
    name: string;
    /**
     * First name of the user
     * @type {string}
     * @memberof VCurrentUser
     */
    firstName: string;
    /**
     * Surname of the user
     * @type {string}
     * @memberof VCurrentUser
     */
    surname: string;
    /**
     * Email of the user
     * @type {string}
     * @memberof VCurrentUser
     */
    email: string;
    /**
     * The user is an admin or not
     * @type {boolean}
     * @memberof VCurrentUser
     */
    isSuperAdmin: boolean;
    /**
     * The user is a display or not
     * @type {boolean}
     * @memberof VCurrentUser
     */
    isDisplay: boolean;
    /**
     * The user is active or not
     * @type {boolean}
     * @memberof VCurrentUser
     */
    active: boolean;
    /**
     * Access token of the user
     * @type {string}
     * @memberof VCurrentUser
     */
    accessToken: string;
    /**
     * Refresh token of the user for getting a new access token
     * @type {string}
     * @memberof VCurrentUser
     */
    refreshToken: string;
    /**
     * The user can access full feature if true
     * @type {boolean}
     * @memberof VCurrentUser
     */
    isCloudRuntime: boolean;
    /**
     * Server label
     * @type {string}
     * @memberof VCurrentUser
     */
    serverLabel?: string;
    /**
     * Api version
     * @type {string}
     * @memberof VCurrentUser
     */
    apiVersion: string;
    /**
     * Clinics the user belongs to
     * @type {Array<VUserClinic>}
     * @memberof VCurrentUser
     */
    clinics: Array<VUserClinic>;
}

/**
 * Check if a given object implements the VCurrentUser interface.
 */
export function instanceOfVCurrentUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "surname" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "isSuperAdmin" in value;
    isInstance = isInstance && "isDisplay" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "accessToken" in value;
    isInstance = isInstance && "refreshToken" in value;
    isInstance = isInstance && "isCloudRuntime" in value;
    isInstance = isInstance && "apiVersion" in value;
    isInstance = isInstance && "clinics" in value;

    return isInstance;
}

export function VCurrentUserFromJSON(json: any): VCurrentUser {
    return VCurrentUserFromJSONTyped(json, false);
}

export function VCurrentUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): VCurrentUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'email': json['email'],
        'isSuperAdmin': json['isSuperAdmin'],
        'isDisplay': json['isDisplay'],
        'active': json['active'],
        'accessToken': json['accessToken'],
        'refreshToken': json['refreshToken'],
        'isCloudRuntime': json['isCloudRuntime'],
        'serverLabel': !exists(json, 'serverLabel') ? undefined : json['serverLabel'],
        'apiVersion': json['apiVersion'],
        'clinics': ((json['clinics'] as Array<any>).map(VUserClinicFromJSON)),
    };
}

export function VCurrentUserToJSON(value?: VCurrentUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'firstName': value.firstName,
        'surname': value.surname,
        'email': value.email,
        'isSuperAdmin': value.isSuperAdmin,
        'isDisplay': value.isDisplay,
        'active': value.active,
        'accessToken': value.accessToken,
        'refreshToken': value.refreshToken,
        'isCloudRuntime': value.isCloudRuntime,
        'serverLabel': value.serverLabel,
        'apiVersion': value.apiVersion,
        'clinics': ((value.clinics as Array<any>).map(VUserClinicToJSON)),
    };
}

