/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VTank
 */
export interface VTank {
    /**
     * Name of the tank
     * @type {string}
     * @memberof VTank
     */
    name: string;
    /**
     * Serial of the tank
     * @type {string}
     * @memberof VTank
     */
    serial: string;
    /**
     * Manufacturer of the tank
     * @type {string}
     * @memberof VTank
     */
    manufacturer?: string;
    /**
     * Model of the tank
     * @type {string}
     * @memberof VTank
     */
    modelType?: string;
    /**
     * Capacity (L) of the tank
     * @type {number}
     * @memberof VTank
     */
    capacity: number;
    /**
     * Empty weight (kg) of the tank
     * @type {number}
     * @memberof VTank
     */
    minWeight: number;
    /**
     * Full weight (kg) of the tank
     * @type {number}
     * @memberof VTank
     */
    maxWeight: number;
    /**
     * Minimum evaporation rate (kg/h) of the tank
     * @type {number}
     * @memberof VTank
     */
    minEvaporationRate: number;
    /**
     * Maximum evaporation rate (kg/h) of the tank
     * @type {number}
     * @memberof VTank
     */
    maxEvaporationRate: number;
    /**
     * Fast weight loss rate of tank for elevated alarming
     * @type {number}
     * @memberof VTank
     */
    weightLossFastRate: number;
    /**
     * Critical weight loss rate of tank for elevated alarming
     * @type {number}
     * @memberof VTank
     */
    weightLossCriticalRate: number;
    /**
     * Apex reset weight (kg) threshold
     * @type {number}
     * @memberof VTank
     */
    apexResetWeight: number;
    /**
     * If current time is less than muteUntilTime, muteCaddy will be set to true in queryConfiguration api
     * @type {string}
     * @memberof VTank
     */
    muteUntilTime?: string;
    /**
     * Id of clinic the tank belongs to
     * @type {string}
     * @memberof VTank
     */
    clinicId: string;
    /**
     * Id of caddy the tank is installed
     * @type {string}
     * @memberof VTank
     */
    caddyId: string;
    /**
     * Ids of displays
     * @type {Array<string>}
     * @memberof VTank
     */
    displayIds: Array<string>;
    /**
     * The tank is active or not
     * @type {boolean}
     * @memberof VTank
     */
    active: boolean;
    /**
     * Tank ID
     * @type {string}
     * @memberof VTank
     */
    id: string;
    /**
     * The tank is alarming or not
     * @type {boolean}
     * @memberof VTank
     */
    isAlarming: boolean;
    /**
     * The tank is warning or not
     * @type {boolean}
     * @memberof VTank
     */
    isWarning: boolean;
    /**
     * The tank is muted or not
     * @type {boolean}
     * @memberof VTank
     */
    isMuted: boolean;
    /**
     * The tank haven't received any readings for a long time
     * @type {boolean}
     * @memberof VTank
     */
    isOffline: boolean;
    /**
     * The tank weight is low or not
     * @type {boolean}
     * @memberof VTank
     */
    isWeightLow?: boolean;
    /**
     * The tank weight is high or not
     * @type {boolean}
     * @memberof VTank
     */
    isWeightHigh?: boolean;
    /**
     * The tank evaporation rate is low or not
     * @type {boolean}
     * @memberof VTank
     */
    isEvaporationRateLow?: boolean;
    /**
     * The tank is evaporation high is high or not
     * @type {boolean}
     * @memberof VTank
     */
    isEvaporationRateHigh?: boolean;
    /**
     * The tank weight loss is fast or not
     * @type {boolean}
     * @memberof VTank
     */
    isWeightLossFast?: boolean;
    /**
     * The tank weight loss is critical or not
     * @type {boolean}
     * @memberof VTank
     */
    isWeightLossCritical?: boolean;
    /**
     * Weight (kg) of the tank
     * @type {number}
     * @memberof VTank
     */
    weight?: number;
    /**
     * Evaporation rate (kg/h) of the tank
     * @type {number}
     * @memberof VTank
     */
    evaporationRate?: number;
    /**
     * Last reading time
     * @type {string}
     * @memberof VTank
     */
    lastReadingTime?: string;
    /**
     * After this time, will check lastReadingTime to trigger alarms
     * @type {string}
     * @memberof VTank
     */
    resetReadingTime?: string;
    /**
     * Last apex reset time
     * @type {string}
     * @memberof VTank
     */
    lastApexResetTime?: string;
    /**
     * Creating or updating time of the tank (Will be set to null once new config synced to its caddy)
     * @type {string}
     * @memberof VTank
     */
    confTime?: string;
}

/**
 * Check if a given object implements the VTank interface.
 */
export function instanceOfVTank(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "serial" in value;
    isInstance = isInstance && "capacity" in value;
    isInstance = isInstance && "minWeight" in value;
    isInstance = isInstance && "maxWeight" in value;
    isInstance = isInstance && "minEvaporationRate" in value;
    isInstance = isInstance && "maxEvaporationRate" in value;
    isInstance = isInstance && "weightLossFastRate" in value;
    isInstance = isInstance && "weightLossCriticalRate" in value;
    isInstance = isInstance && "apexResetWeight" in value;
    isInstance = isInstance && "clinicId" in value;
    isInstance = isInstance && "caddyId" in value;
    isInstance = isInstance && "displayIds" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isAlarming" in value;
    isInstance = isInstance && "isWarning" in value;
    isInstance = isInstance && "isMuted" in value;
    isInstance = isInstance && "isOffline" in value;

    return isInstance;
}

export function VTankFromJSON(json: any): VTank {
    return VTankFromJSONTyped(json, false);
}

export function VTankFromJSONTyped(json: any, ignoreDiscriminator: boolean): VTank {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'serial': json['serial'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'modelType': !exists(json, 'modelType') ? undefined : json['modelType'],
        'capacity': json['capacity'],
        'minWeight': json['minWeight'],
        'maxWeight': json['maxWeight'],
        'minEvaporationRate': json['minEvaporationRate'],
        'maxEvaporationRate': json['maxEvaporationRate'],
        'weightLossFastRate': json['weightLossFastRate'],
        'weightLossCriticalRate': json['weightLossCriticalRate'],
        'apexResetWeight': json['apexResetWeight'],
        'muteUntilTime': !exists(json, 'muteUntilTime') ? undefined : json['muteUntilTime'],
        'clinicId': json['clinicId'],
        'caddyId': json['caddyId'],
        'displayIds': json['displayIds'],
        'active': json['active'],
        'id': json['id'],
        'isAlarming': json['isAlarming'],
        'isWarning': json['isWarning'],
        'isMuted': json['isMuted'],
        'isOffline': json['isOffline'],
        'isWeightLow': !exists(json, 'isWeightLow') ? undefined : json['isWeightLow'],
        'isWeightHigh': !exists(json, 'isWeightHigh') ? undefined : json['isWeightHigh'],
        'isEvaporationRateLow': !exists(json, 'isEvaporationRateLow') ? undefined : json['isEvaporationRateLow'],
        'isEvaporationRateHigh': !exists(json, 'isEvaporationRateHigh') ? undefined : json['isEvaporationRateHigh'],
        'isWeightLossFast': !exists(json, 'isWeightLossFast') ? undefined : json['isWeightLossFast'],
        'isWeightLossCritical': !exists(json, 'isWeightLossCritical') ? undefined : json['isWeightLossCritical'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'evaporationRate': !exists(json, 'evaporationRate') ? undefined : json['evaporationRate'],
        'lastReadingTime': !exists(json, 'lastReadingTime') ? undefined : json['lastReadingTime'],
        'resetReadingTime': !exists(json, 'resetReadingTime') ? undefined : json['resetReadingTime'],
        'lastApexResetTime': !exists(json, 'lastApexResetTime') ? undefined : json['lastApexResetTime'],
        'confTime': !exists(json, 'confTime') ? undefined : json['confTime'],
    };
}

export function VTankToJSON(value?: VTank | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'serial': value.serial,
        'manufacturer': value.manufacturer,
        'modelType': value.modelType,
        'capacity': value.capacity,
        'minWeight': value.minWeight,
        'maxWeight': value.maxWeight,
        'minEvaporationRate': value.minEvaporationRate,
        'maxEvaporationRate': value.maxEvaporationRate,
        'weightLossFastRate': value.weightLossFastRate,
        'weightLossCriticalRate': value.weightLossCriticalRate,
        'apexResetWeight': value.apexResetWeight,
        'muteUntilTime': value.muteUntilTime,
        'clinicId': value.clinicId,
        'caddyId': value.caddyId,
        'displayIds': value.displayIds,
        'active': value.active,
        'id': value.id,
        'isAlarming': value.isAlarming,
        'isWarning': value.isWarning,
        'isMuted': value.isMuted,
        'isOffline': value.isOffline,
        'isWeightLow': value.isWeightLow,
        'isWeightHigh': value.isWeightHigh,
        'isEvaporationRateLow': value.isEvaporationRateLow,
        'isEvaporationRateHigh': value.isEvaporationRateHigh,
        'isWeightLossFast': value.isWeightLossFast,
        'isWeightLossCritical': value.isWeightLossCritical,
        'weight': value.weight,
        'evaporationRate': value.evaporationRate,
        'lastReadingTime': value.lastReadingTime,
        'resetReadingTime': value.resetReadingTime,
        'lastApexResetTime': value.lastApexResetTime,
        'confTime': value.confTime,
    };
}

