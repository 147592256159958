/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  VAddClinicRequestParams,
  VClinic,
  VUpdateClinicRequestParams,
} from '../models/index';
import {
    VAddClinicRequestParamsFromJSON,
    VAddClinicRequestParamsToJSON,
    VClinicFromJSON,
    VClinicToJSON,
    VUpdateClinicRequestParamsFromJSON,
    VUpdateClinicRequestParamsToJSON,
} from '../models/index';

export interface AddClinicRequest {
    vAddClinicRequestParams: VAddClinicRequestParams;
}

export interface GenerateTokenRequest {
    clinicId: string;
}

export interface GetClinicRequest {
    clinicId: string;
}

export interface UpdateClinicRequest {
    clinicId: string;
    vUpdateClinicRequestParams: VUpdateClinicRequestParams;
}

/**
 * 
 */
export class ClinicApi extends runtime.BaseAPI {

    /**
     * Add a new clinic
     * Add a new clinic
     */
    async addClinicRaw(requestParameters: AddClinicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VClinic>> {
        if (requestParameters.vAddClinicRequestParams === null || requestParameters.vAddClinicRequestParams === undefined) {
            throw new runtime.RequiredError('vAddClinicRequestParams','Required parameter requestParameters.vAddClinicRequestParams was null or undefined when calling addClinic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clinic`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VAddClinicRequestParamsToJSON(requestParameters.vAddClinicRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VClinicFromJSON(jsonValue));
    }

    /**
     * Add a new clinic
     * Add a new clinic
     */
    async addClinic(requestParameters: AddClinicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VClinic> {
        const response = await this.addClinicRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gen an access token for the clinic
     * Gen an access token
     */
    async generateTokenRaw(requestParameters: GenerateTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VClinic>> {
        if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
            throw new runtime.RequiredError('clinicId','Required parameter requestParameters.clinicId was null or undefined when calling generateToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clinic/generateToken/{clinicId}`.replace(`{${"clinicId"}}`, encodeURIComponent(String(requestParameters.clinicId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VClinicFromJSON(jsonValue));
    }

    /**
     * Gen an access token for the clinic
     * Gen an access token
     */
    async generateToken(requestParameters: GenerateTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VClinic> {
        const response = await this.generateTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a clinic by ID
     * Get a clinic
     */
    async getClinicRaw(requestParameters: GetClinicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VClinic>> {
        if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
            throw new runtime.RequiredError('clinicId','Required parameter requestParameters.clinicId was null or undefined when calling getClinic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clinic/{clinicId}`.replace(`{${"clinicId"}}`, encodeURIComponent(String(requestParameters.clinicId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VClinicFromJSON(jsonValue));
    }

    /**
     * Get a clinic by ID
     * Get a clinic
     */
    async getClinic(requestParameters: GetClinicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VClinic> {
        const response = await this.getClinicRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of clinics
     * Get a list of clinics
     */
    async getClinicsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VClinic>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clinic`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VClinicFromJSON));
    }

    /**
     * Get a list of clinics
     * Get a list of clinics
     */
    async getClinics(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VClinic>> {
        const response = await this.getClinicsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update a clinic by ID
     * Update a clinic
     */
    async updateClinicRaw(requestParameters: UpdateClinicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VClinic>> {
        if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
            throw new runtime.RequiredError('clinicId','Required parameter requestParameters.clinicId was null or undefined when calling updateClinic.');
        }

        if (requestParameters.vUpdateClinicRequestParams === null || requestParameters.vUpdateClinicRequestParams === undefined) {
            throw new runtime.RequiredError('vUpdateClinicRequestParams','Required parameter requestParameters.vUpdateClinicRequestParams was null or undefined when calling updateClinic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clinic/{clinicId}`.replace(`{${"clinicId"}}`, encodeURIComponent(String(requestParameters.clinicId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VUpdateClinicRequestParamsToJSON(requestParameters.vUpdateClinicRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VClinicFromJSON(jsonValue));
    }

    /**
     * Update a clinic by ID
     * Update a clinic
     */
    async updateClinic(requestParameters: UpdateClinicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VClinic> {
        const response = await this.updateClinicRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
