import React from 'react';
import Header from '../features/header';
import './layout2.css';

// TODO: Will use layouts and slots
const Layout2: React.FC<{
  breadcrumbsSlot: React.ReactNode,
  contentSlot: React.ReactNode,
  topSlot: React.ReactNode,
  leftSlot: React.ReactNode,
  rightSlot: React.ReactNode,
  rightSlotHide?: boolean,
}> = ({
  breadcrumbsSlot,
  contentSlot,
  topSlot,
  leftSlot,
  rightSlot,
  rightSlotHide,
}) => (
  <>
    <div className='lyt2-header'>
      <Header />
    </div>
    <main className='lyt2-main'>
      <div className='lyt2-breadcrumbs'>
        {breadcrumbsSlot}
      </div>
      <div className='lyt2-top-bar'>
        {topSlot}
      </div>
      <div className='lyt2-main-content'>
        <div className='lyt2-main-left'>
          <div className='lyt2-main-left-inner'>
            {leftSlot}
          </div>
        </div>
        <div className='lyt2-main-center'>
          <div className='lyt2-main-center-inner'>
            {contentSlot}
          </div>
        </div>
        {
          !rightSlotHide
            ? <div className='lyt2-main-right'>
                <div className='lyt2-main-right-inner'>
                  {rightSlot}
                </div>
              </div> : null
        }
      </div>
      <div className='lyt2-footer-bar'></div>
    </main>
  </>
);

export default Layout2;
