/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VFileTypeEnum } from './VFileTypeEnum';
import {
    VFileTypeEnumFromJSON,
    VFileTypeEnumFromJSONTyped,
    VFileTypeEnumToJSON,
} from './VFileTypeEnum';

/**
 * 
 * @export
 * @interface VFile
 */
export interface VFile {
    /**
     * Title of the file
     * @type {string}
     * @memberof VFile
     */
    title: string;
    /**
     * 
     * @type {VFileTypeEnum}
     * @memberof VFile
     */
    type?: VFileTypeEnum;
    /**
     * File name of the file
     * @type {string}
     * @memberof VFile
     */
    filename: string;
    /**
     * Description of the file
     * @type {string}
     * @memberof VFile
     */
    description?: string;
    /**
     * File Id
     * @type {string}
     * @memberof VFile
     */
    id: string;
    /**
     * Time of uploading or updating the file
     * @type {string}
     * @memberof VFile
     */
    time: string;
}

/**
 * Check if a given object implements the VFile interface.
 */
export function instanceOfVFile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "time" in value;

    return isInstance;
}

export function VFileFromJSON(json: any): VFile {
    return VFileFromJSONTyped(json, false);
}

export function VFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): VFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'type': !exists(json, 'type') ? undefined : VFileTypeEnumFromJSON(json['type']),
        'filename': json['filename'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
        'time': json['time'],
    };
}

export function VFileToJSON(value?: VFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'type': VFileTypeEnumToJSON(value.type),
        'filename': value.filename,
        'description': value.description,
        'id': value.id,
        'time': value.time,
    };
}

