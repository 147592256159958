/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VUpdateFirmwareRequestParams
 */
export interface VUpdateFirmwareRequestParams {
    /**
     * Caddy serials that require firmware updates
     * @type {Array<string>}
     * @memberof VUpdateFirmwareRequestParams
     */
    caddySerials: Array<string>;
    /**
     * the Id of the target firmware file
     * @type {string}
     * @memberof VUpdateFirmwareRequestParams
     */
    targetVersionId: string;
}

/**
 * Check if a given object implements the VUpdateFirmwareRequestParams interface.
 */
export function instanceOfVUpdateFirmwareRequestParams(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "caddySerials" in value;
    isInstance = isInstance && "targetVersionId" in value;

    return isInstance;
}

export function VUpdateFirmwareRequestParamsFromJSON(json: any): VUpdateFirmwareRequestParams {
    return VUpdateFirmwareRequestParamsFromJSONTyped(json, false);
}

export function VUpdateFirmwareRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VUpdateFirmwareRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caddySerials': json['caddySerials'],
        'targetVersionId': json['targetVersionId'],
    };
}

export function VUpdateFirmwareRequestParamsToJSON(value?: VUpdateFirmwareRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caddySerials': value.caddySerials,
        'targetVersionId': value.targetVersionId,
    };
}

