import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Text } from '@fluentui/react-components';
import { ChevronDown20Regular, ChevronRight20Regular } from '@fluentui/react-icons';
import apiServices, { VClinic, VNotification } from '../../../services';
import BetterIcon from '../../../components/betterIcon';
import { EnumRightBarType } from '../../../common/models';

const ClinicTopBar: React.FC<{
  clinic?: VClinic | null,
  notifications?: VNotification[],
  rightBarType: EnumRightBarType | null,
  onChangeRightBarType: (t: EnumRightBarType) => void,
}> = ({
  clinic,
  rightBarType,
  onChangeRightBarType,
}) => {
  const navigate = useNavigate();
  return (
    <div style={{
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 10px',
    }} >
      <div style={{
        display: 'flex',
        alignItems: 'flex-end',
      }} >
        <div style={{ display: 'flex', alignItems: 'flex-end', gap: 10 }}>
          <div><Text weight='bold' size={500}>{clinic?.name}</Text></div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BetterIcon name='Location24Regular' style={{ width: 14, height: 14 }} />
            <Text weight='semibold' size={300} style={{
              maxWidth: 600,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: 'block',
              whiteSpace: 'nowrap',
            }}>
              {clinic?.address}
            </Text>
          </div>
        </div>
      </div>
      <div style={{ position: 'relative', display: 'inline-block' }} >
        {
          clinic
          && apiServices.isCloudRuntime()
          && (apiServices.isSuperAdmin() || apiServices.isSiteAdmin())
            ? <Button appearance='primary' onClick={() => navigate(`/clinic/${clinic.id}/add-tank`)}>Add Tank</Button>
            : null
        }
        <Button
          onClick={() => onChangeRightBarType(EnumRightBarType.History)}
          icon={
            rightBarType === EnumRightBarType.History
              ? <ChevronDown20Regular/> : <ChevronRight20Regular/>
          }
          iconPosition="after"
          style={{ marginLeft: 20 }}
        >
          {EnumRightBarType.History}
        </Button>
      </div>
    </div>
  );
};

export default ClinicTopBar;
