type DataUpdateCallback<T> = (data: T) => void;

export const defaultPollInterval = 10000;

export type FetchFunction<T> = () => Promise<T>;

export default function usePoll<T>(
  fetchFunction: FetchFunction<T>,
  pollingInterval: number, // ms
): (onDataUpdate: DataUpdateCallback<T>) => () => void {
  return (onDataUpdate) => {
    const fetchData = async () => {
      const newData = await fetchFunction();
      onDataUpdate(newData);
    };

    fetchData();

    const intervalId = setInterval(fetchData, pollingInterval);

    return () => clearInterval(intervalId);
  };
}
