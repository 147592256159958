import React from 'react';
import Header from '../features/header';
import './layout3.css';

const Layout3: React.FC<{
  breadcrumbsSlot: React.ReactNode,
  contentTopSlot: React.ReactNode,
  contentSlot: React.ReactNode,
  topSlot: React.ReactNode,
  rightSlot1: React.ReactNode,
  rightSlot2: React.ReactNode,
  rightSlot1Hide?: boolean,
  rightSlot2Hide?: boolean,
}> = ({
  breadcrumbsSlot,
  contentTopSlot,
  contentSlot,
  topSlot,
  rightSlot1,
  rightSlot2,
  rightSlot1Hide,
  rightSlot2Hide,
}) => (
  <>
    <div className='lyt3-header'>
      <Header />
    </div>
    <main className='lyt3-main'>
      <div className='lyt3-breadcrumbs'>
        {breadcrumbsSlot}
      </div>
      <div className='lyt3-top-bar'>
        {topSlot}
      </div>
      <div className='lyt3-main-content'>
        <div className='lyt3-main-center'>
          <div className='lyt3-main-center-inner'>
            <div className='lyt3-main-center-top'>
              {contentTopSlot}
            </div>
            <div className='lyt3-main-center-major'>
              <div className='lyt3-main-center-major-inner'>
                {contentSlot}
              </div>
            </div>
          </div>
        </div>
        {
          !rightSlot1Hide
            ? <div className='lyt3-main-right'>
                <div className='lyt3-main-right-inner'>
                  {rightSlot1}
                </div>
              </div> : null
        }
        {
          !rightSlot2Hide
            ? <div className='lyt3-main-right'>
              <div className='lyt3-main-right-inner'>
                {rightSlot2}
              </div>
            </div> : null
        }
      </div>
      <div className='lyt3-footer-bar'></div>
    </main>
  </>
);

export default Layout3;
