/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VNotificationCategoryEnum } from './VNotificationCategoryEnum';
import {
    VNotificationCategoryEnumFromJSON,
    VNotificationCategoryEnumFromJSONTyped,
    VNotificationCategoryEnumToJSON,
} from './VNotificationCategoryEnum';
import type { VNotificationStatusEnum } from './VNotificationStatusEnum';
import {
    VNotificationStatusEnumFromJSON,
    VNotificationStatusEnumFromJSONTyped,
    VNotificationStatusEnumToJSON,
} from './VNotificationStatusEnum';
import type { VNotificationTypeEnum } from './VNotificationTypeEnum';
import {
    VNotificationTypeEnumFromJSON,
    VNotificationTypeEnumFromJSONTyped,
    VNotificationTypeEnumToJSON,
} from './VNotificationTypeEnum';

/**
 * 
 * @export
 * @interface VNotification
 */
export interface VNotification {
    /**
     * 
     * @type {VNotificationCategoryEnum}
     * @memberof VNotification
     */
    category: VNotificationCategoryEnum;
    /**
     * 
     * @type {VNotificationTypeEnum}
     * @memberof VNotification
     */
    type: VNotificationTypeEnum;
    /**
     * Clinic Id of the notification
     * @type {string}
     * @memberof VNotification
     */
    clinicId: string;
    /**
     * Tank Id of the notification
     * @type {string}
     * @memberof VNotification
     */
    tankId: string;
    /**
     * Tank name of the notification
     * @type {string}
     * @memberof VNotification
     */
    tankName: string;
    /**
     * Caddy Id of the notification
     * @type {string}
     * @memberof VNotification
     */
    caddyId: string;
    /**
     * Caddy serial of the notification
     * @type {string}
     * @memberof VNotification
     */
    caddySerial: string;
    /**
     * User Id of the notification
     * @type {string}
     * @memberof VNotification
     */
    userId: string;
    /**
     * Description of the notification
     * @type {string}
     * @memberof VNotification
     */
    description: string;
    /**
     * Creation time of the notification
     * @type {string}
     * @memberof VNotification
     */
    time: string;
    /**
     * 
     * @type {VNotificationStatusEnum}
     * @memberof VNotification
     */
    status: VNotificationStatusEnum;
    /**
     * Notification ID
     * @type {string}
     * @memberof VNotification
     */
    id: string;
}

/**
 * Check if a given object implements the VNotification interface.
 */
export function instanceOfVNotification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "clinicId" in value;
    isInstance = isInstance && "tankId" in value;
    isInstance = isInstance && "tankName" in value;
    isInstance = isInstance && "caddyId" in value;
    isInstance = isInstance && "caddySerial" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function VNotificationFromJSON(json: any): VNotification {
    return VNotificationFromJSONTyped(json, false);
}

export function VNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): VNotification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': VNotificationCategoryEnumFromJSON(json['category']),
        'type': VNotificationTypeEnumFromJSON(json['type']),
        'clinicId': json['clinicId'],
        'tankId': json['tankId'],
        'tankName': json['tankName'],
        'caddyId': json['caddyId'],
        'caddySerial': json['caddySerial'],
        'userId': json['userId'],
        'description': json['description'],
        'time': json['time'],
        'status': VNotificationStatusEnumFromJSON(json['status']),
        'id': json['id'],
    };
}

export function VNotificationToJSON(value?: VNotification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': VNotificationCategoryEnumToJSON(value.category),
        'type': VNotificationTypeEnumToJSON(value.type),
        'clinicId': value.clinicId,
        'tankId': value.tankId,
        'tankName': value.tankName,
        'caddyId': value.caddyId,
        'caddySerial': value.caddySerial,
        'userId': value.userId,
        'description': value.description,
        'time': value.time,
        'status': VNotificationStatusEnumToJSON(value.status),
        'id': value.id,
    };
}

