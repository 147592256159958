import {
  BatteryCheckmark24Regular,
  BatteryWarning24Regular,
  Circle24Filled,
  Circle24Regular,
  DismissCircle24Filled,
  ErrorCircle24Filled,
  ErrorCircle24Regular,
  Warning24Filled,
  SpeakerMute24Filled,
  SpeakerOff24Filled,
  Speaker224Filled,
  Wifi124Regular,
  Wifi224Regular,
  Wifi324Regular,
  Wifi424Regular,
  WifiWarning24Regular,
  Wrench24Filled,
  Wrench20Filled,
  Wrench16Filled,
  ArrowSyncCheckmark24Filled,
  Database24Filled,
  CheckmarkCircle24Filled,
  PresenceAvailable24Filled,
  ChevronDown24Regular,
  ChevronUp24Regular,
  EditSettings24Regular,
  CalendarSettings24Regular,
  Settings24Regular,
  Prohibited24Filled,
  Location24Regular,
  InkStroke24Regular,
  TopSpeed24Regular,
  Temperature24Regular,
  Scales24Regular,
  EraserTool24Filled,
  Info24Regular,
  Clipboard24Regular,
  Router24Regular,
  History24Regular,
  DrinkBottle32Regular,
} from '@fluentui/react-icons';
import * as React from 'react';

// You can add more icons if necessary
export type BetterIconName =
  'Circle24Filled' |
  'CheckmarkCircle24Filled' |
  'PresenceAvailable24Filled' |
  'ErrorCircle24Filled' |
  'ErrorCircle24Regular'|
  'Warning24Filled' |
  'SpeakerMute24Filled' |
  'SpeakerOff24Filled' |
  'Speaker224Filled' |
  'Wrench24Filled' |
  'Wrench20Filled' |
  'Wrench16Filled' |
  'Circle24Regular' |
  'DismissCircle24Filled' |
  'BatteryCheckmark24Regular' |
  'BatteryWarning24Regular' |
  'WifiWarning24Regular' |
  'Wifi424Regular' |
  'Wifi324Regular' |
  'Wifi224Regular' |
  'Wifi124Regular' |
  'ArrowSyncCheckmark24Filled' |
  'Database24Filled' |
  'ChevronDown24Regular' |
  'ChevronUp24Regular' |
  'EditSettings24Regular' |
  'CalendarSettings24Regular' |
  'Settings24Regular' |
  'Prohibited24Filled' |
  'Location24Regular' |
  'InkStroke24Regular' |
  'TopSpeed24Regular' |
  'Temperature24Regular' |
  'Scales24Regular' |
  'EraserTool24Filled' |
  'Info24Regular' |
  'Clipboard24Regular' |
  'Router24Regular' |
  'History24Regular' |
  'DrinkBottle32Regular';

const BetterIcon: React.FC<{
  name: BetterIconName,
  color?: string,
  style?: React.CSSProperties,
}> = ({
  name,
  color,
  style,
}) => {
  switch (name) {
    case 'Circle24Filled':
      return <Circle24Filled color={color} style={style} />;
    case 'CheckmarkCircle24Filled':
      return <CheckmarkCircle24Filled color={color} style={style} />;
    case 'PresenceAvailable24Filled':
      return <PresenceAvailable24Filled color={color} style={style} />;
    case 'ErrorCircle24Filled':
      return <ErrorCircle24Filled color={color} style={style} />;
    case 'ErrorCircle24Regular':
      return <ErrorCircle24Regular color={color} style={style} />;
    case 'Warning24Filled':
      return <Warning24Filled color={color} style={style} />;
    case 'SpeakerMute24Filled':
      return <SpeakerMute24Filled color={color} style={style} />;
    case 'SpeakerOff24Filled':
      return <SpeakerOff24Filled color={color} style={style} />;
    case 'Speaker224Filled':
      return <Speaker224Filled color={color} style={style} />;
    case 'Wrench16Filled':
      return <Wrench16Filled color={color} style={style} />;
    case 'Wrench20Filled':
      return <Wrench20Filled color={color} style={style} />;
    case 'Wrench24Filled':
      return <Wrench24Filled color={color} style={style} />;
    case 'Circle24Regular':
      return <Circle24Regular color={color} style={style} />;
    case 'DismissCircle24Filled':
      return <DismissCircle24Filled color={color} style={style} />;
    case 'BatteryCheckmark24Regular':
      return <BatteryCheckmark24Regular color={color} style={style} />;
    case 'BatteryWarning24Regular':
      return <BatteryWarning24Regular color={color} style={style} />;
    case 'WifiWarning24Regular':
      return <WifiWarning24Regular color={color} style={style} />;
    case 'Wifi424Regular':
      return <Wifi424Regular color={color} style={style} />;
    case 'Wifi324Regular':
      return <Wifi324Regular color={color} style={style} />;
    case 'Wifi224Regular':
      return <Wifi224Regular color={color} style={style} />;
    case 'Wifi124Regular':
      return <Wifi124Regular color={color} style={style} />;
    case 'ArrowSyncCheckmark24Filled':
      return <ArrowSyncCheckmark24Filled color={color} style={style} />;
    case 'Database24Filled':
      return <Database24Filled color={color} style={style} />;
    case 'ChevronDown24Regular':
      return <ChevronDown24Regular color={color} style={style} />;
    case 'ChevronUp24Regular':
      return <ChevronUp24Regular color={color} style={style} />;
    case 'EditSettings24Regular':
      return <EditSettings24Regular color={color} style={style} />;
    case 'CalendarSettings24Regular':
      return <CalendarSettings24Regular color={color} style={style} />;
    case 'Settings24Regular':
      return <Settings24Regular color={color} style={style} />;
    case 'Prohibited24Filled':
      return <Prohibited24Filled color={color} style={style} />;
    case 'Location24Regular':
      return <Location24Regular color={color} style={style} />;
    case 'InkStroke24Regular':
      return <InkStroke24Regular color={color} style={style} />;
    case 'TopSpeed24Regular':
      return <TopSpeed24Regular color={color} style={style} />;
    case 'Temperature24Regular':
      return <Temperature24Regular color={color} style={style} />;
    case 'Scales24Regular':
      return <Scales24Regular color={color} style={style} />;
    case 'EraserTool24Filled':
      return <EraserTool24Filled color={color} style={style} />;
    case 'Info24Regular':
      return <Info24Regular color={color} style={style} />;
    case 'Clipboard24Regular':
      return <Clipboard24Regular color={color} style={style} />;
    case 'Router24Regular':
      return <Router24Regular color={color} style={style} />;
    case 'History24Regular':
      return <History24Regular color={color} style={style} />;
    case 'DrinkBottle32Regular':
      return <DrinkBottle32Regular color={color} style={style} />;
    default:
      return <></>;
  }
};

export default BetterIcon;
