import * as React from 'react';
import { Text } from '@fluentui/react-components';
import BetterIcon, { BetterIconName } from './betterIcon';
import { lightThemeColors } from '../common/themes';
import { correctNumber } from '../common/utils';

export interface IStatWidgetProps {
  title: string;
  value?: string | number;
  desc?: string | number;
  descMore?: string | number;
  unit?: string;
  icon?: BetterIconName;
  status: string;
  statusIcon: BetterIconName;
  color: string;
}

const StatWidget: React.FC<IStatWidgetProps> = ({
  title,
  value,
  desc,
  descMore,
  unit,
  icon,
  status,
  statusIcon,
  color,
}) => {
  const [showMore, setShowMore] = React.useState<boolean>(false);
  const showMoreEnable = !!descMore;
  return (
    <div
      style={{
        boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.2)',
        borderRadius: 4,
        padding: 10,
        width: 190,
        minWidth: 190,
        height: '100%',
        display: 'inline-flex',
        flexDirection: 'column',
        gap: 4,
        marginRight: 20,
      }}
      onMouseEnter={() => showMoreEnable && setShowMore(true)}
      onMouseLeave={() => showMoreEnable && setShowMore(false)}
    >
      <div style={{ textAlign: 'center' }}>
        <Text size={300} weight='bold'>{title}</Text>
      </div>
      { desc
        ? <div style={{
          flex: 1,
          textAlign: 'center',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}>
          <Text size={300} weight='bold' style={{ color }} >{ showMore ? descMore : desc }</Text>
        </div>
        : <div style={{
          flex: 1,
          textAlign: 'center',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}>
          {
            icon
              ? <BetterIcon name={icon} color={color} style={{ width: 38, height: 38 }} />
              : <>
                <Text size={700} weight='bold' style={{ color }}>{correctNumber(3, value)}</Text> {unit}
              </>
          }
        </div>
      }
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        {
          statusIcon
            ? <BetterIcon
                name={statusIcon}
                color={color ?? lightThemeColors.normal}
                style={{ width: 16, height: 16, marginRight: 4 }}
              />
            : null
        }
        <Text
          size={200}
          weight='bold'
          style={{ color }}
        >
          {status}
        </Text>
      </div>
    </div>
  );
};

export default StatWidget;
