import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  ButtonProps,
  Card,
  Input,
  Label,
  Spinner,
  Text,
  tokens,
} from '@fluentui/react-components';
import { EyeRegular, EyeOffRegular } from '@fluentui/react-icons';
import { useAppSelector, useAppDispatch } from '../../hook';
import logo from '../../assets/images/logo.png';
import MinimalLayout from '../../layouts/minimalLayout';
import {
  changePassword, changeUsername, login, showPassword,
} from './_slice';
import { Result } from '../../common/models';

const Login: React.FC = () => {
  const loginState = useAppSelector((state) => state.login);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const ShowHidePasswordButton: React.FC<ButtonProps> = (props) => (
    <Button
      {...props}
      appearance="transparent"
      icon={loginState.isPasswordShown ? <EyeOffRegular /> : <EyeRegular />}
      size="small"
      onClick={() => dispatch(showPassword())}
    />
  );

  const onLogin = async () => {
    const dispatchResult = await dispatch(login());
    if ((dispatchResult.payload as Result).result) {
      // After logging in successfully, should redirect to the `from` path
      // If no path, should redirect to `/`
      navigate(searchParams.get('from') || '/', { replace: true });
    }
  };

  return (
    <MinimalLayout>
      <Card style={{
        maxWidth: 600,
        width: '100%',
        height: 420,
        padding: 30,
        margin: 10,
      }}>
        <img src={logo} alt="logo" />

        <Label size="small" >Username</Label>
        <Input
          value={loginState.username}
          onChange={(e) => dispatch(changeUsername(e.target.value))}
        />
        <Label size="small" >Password</Label>
        <Input
          contentAfter={<ShowHidePasswordButton aria-label={`${loginState.isPasswordShown ? 'Hide' : 'Show'} Password`} />}
          value={loginState.password}
          type={loginState.isPasswordShown ? 'text' : 'password'}
          onChange={(e) => dispatch(changePassword(e.target.value))}
        />
        <Text style={{ height: 24, color: tokens.colorPaletteRedBorderActive }}>
          {loginState.errorMessage}
        </Text>

        <Button
          onClick={loginState.isSubmitting ? undefined : onLogin}
          appearance="primary"
          disabled={loginState.isSubmitting}
          style={{ height: 32 }}
        >
          {
            loginState.isSubmitting
              ? <Spinner size="small" appearance="primary" label="Sign In" />
              : 'Sign In'
          }
        </Button>
      </Card>
    </MinimalLayout>
  );
};

export default Login;
