import React from 'react';
import {
  Card, Divider, Text,
} from '@fluentui/react-components';
import { useNavigate } from 'react-router-dom';
import apiServices, { VCaddy, VClinic, VTank } from '../../../services';
import SimpleInfoCard from '../../../components/simpleInfoCard';
import {
  formatValue,
} from '../../../common/utils';
import appConfig from '../../../config.app';
import Message from '../../../components/message';

const TankSettings: React.FC<{
  clinic?: VClinic | null,
  tank?: VTank | null,
  caddy?: VCaddy | null,
}> = ({
  tank,
  caddy,
}) => {
  const navigate = useNavigate();

  return (
    <div style={{
      width: '100%',
      padding: 20,
    }}>
      {
        tank?.confTime
          ? <div style={{ marginBottom: 20 }}>
            <Message
              message={appConfig.message.CONF_SYNCING}
              status='loading'
              horizontal={true}
            />
          </div> : null
      }
      <SimpleInfoCard
        title='Tank Settings'
        icon='DrinkBottle32Regular'
        items={[
          {
            label: 'Min Safe Weight',
            value: formatValue(tank?.minWeight, 'kg'),
          },
          {
            label: 'Max Safe Weight',
            value: formatValue(tank?.maxWeight, 'kg'),
          },
          {
            label: 'Apex Reset Threshold',
            value: formatValue(tank?.apexResetWeight, 'kg'),
          },
          {
            label: 'Min Evaporation Rate',
            labelWidth: 200,
            value: formatValue(tank?.minEvaporationRate, 'kg/h'),
          },
          {
            label: 'Max Evaporation Rate',
            labelWidth: 200,
            value: formatValue(tank?.maxEvaporationRate, 'kg/h'),
          },
          {
            label: 'Weight Loss Fast Rate',
            labelWidth: 200,
            value: formatValue(tank?.weightLossFastRate, 'kg/h'),
          },
          {
            label: 'Weight Loss Critical Rate',
            labelWidth: 200,
            value: formatValue(tank?.weightLossCriticalRate, 'kg/h'),
          },
          {
            label: 'Capacity',
            value: formatValue(tank?.capacity, 'kg'),
          },
          {
            label: 'Serial',
            value: formatValue(tank?.serial),
          },
          {
            label: 'Manufacturer',
            value: formatValue(tank?.manufacturer),
          },
          {
            label: 'Model',
            value: formatValue(tank?.modelType),
          },
        ]}
        defaultShowCount={15}
        onClickTopBtn={
          apiServices.isCloudRuntime()
          && (apiServices.isSuperAdmin() || apiServices.isSiteAdmin() || apiServices.isUser())
            ? () => navigate(`/tank/${tank?.id}/manage-tank`)
            : undefined
          }
      />
      {
        caddy
          ? <SimpleInfoCard
          title='Assigned Caddy Settings'
          icon='Router24Regular'
          items={[
            {
              label: 'Serial',
              labelWidth: 100,
              value: formatValue(caddy?.serial),
              tooltip: 'A unique identifier for a caddy',
            },
            {
              label: 'Battery Voltage',
              labelWidth: 200,
              value: formatValue(caddy?.batteryVoltage, 'v', appConfig.precisionLow),
            },
            {
              label: 'WIFI Signal Strength',
              labelWidth: 200,
              value: formatValue(caddy?.signalStrength, 'dBm', appConfig.precisionLow),
            },
            {
              label: 'Current Firmware Version',
              labelWidth: 200,
              value: formatValue(caddy?.firmwareVersion),
            },
          ]}
          defaultShowCount={10}
          onClickTopBtn={
            apiServices.isCloudRuntime()
            && (apiServices.isSuperAdmin() || apiServices.isSiteAdmin())
              ? () => navigate(`/manage-caddies/${tank?.caddyId}`)
              : undefined
          }
        /> : null
      }
      <Card
        style={{
          width: '100%',
          marginBottom: 12,
          padding: 20,
          overflow: 'auto',
          overflowX: 'hidden',
          display: 'none', // TODO: Hide temperarily
        }}
      >
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <Text weight='semibold' size={300}>
            Last apex reset
          </Text>
          <div style={{
            display: 'flex',
            flexFlow: 'column',
            flexWrap: 'wrap',
            gap: 6,
            alignItems: 'flex-end',
          }}>
            <Text size={300}>
              {'---'} {/* { dateTime.toRelative() || 'N/A' } */}
            </Text>
            <Divider/>
            <Text size={300}>
            {'---'} {/* { dateTime.toFormat('h:mm a') || 'N/A' } */}
            </Text>
            <Text size={300}>
              {'---'} {/* { dateTime.toFormat('d/MM/y') || 'N/A' } */}
            </Text>
          </div>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: 30,
        }}>
          <Text weight='semibold' size={300}>
            Avg time between <br/>apex resets
          </Text>
          <Text size={300}>
            {'---'} hrs
          </Text>
        </div>
      </Card>
    </div>
  );
};

export default TankSettings;
