import * as React from 'react';
import { Dropdown, Option } from '@fluentui/react-components';

export interface IBetterDropdownOption {
  id: string;
  label: string;
}

export interface BetterDropdownProps {
  title?: string;
  placeholder?: string;
  options: Array<IBetterDropdownOption>;
  selectedOptionId?: string;
  disabled?: boolean;
  onOptionSelect?: (optionValue: string) => void,
}

const BetterDropdown: React.FC<BetterDropdownProps> = ({
  title,
  placeholder,
  options,
  selectedOptionId,
  disabled,
  onOptionSelect,
}) => {
  const selectedOption = options?.find((x) => x.id === selectedOptionId);
  return (
    <Dropdown
      aria-labelledby={title}
      placeholder={placeholder}
      value={selectedOption?.label ?? ''}
      onOptionSelect={(_, item) => {
        if (item?.optionValue !== undefined && onOptionSelect) {
          onOptionSelect(item.optionValue);
        }
      }}
      disabled={disabled}
    >
      {options.map((option) => (
        <Option
          key={option.id}
          value={option.id}
          text={option.label}
          checkIcon={null}
        >
          {option.label}
        </Option>
      ))}
    </Dropdown>
  );
};

export default BetterDropdown;
