/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  VCurrentUser,
  VLoginRequestParams,
  VRefreshRequestParams,
  VResponse,
} from '../models/index';
import {
    VCurrentUserFromJSON,
    VCurrentUserToJSON,
    VLoginRequestParamsFromJSON,
    VLoginRequestParamsToJSON,
    VRefreshRequestParamsFromJSON,
    VRefreshRequestParamsToJSON,
    VResponseFromJSON,
    VResponseToJSON,
} from '../models/index';

export interface LoginRequest {
    vLoginRequestParams: VLoginRequestParams;
}

export interface RefreshRequest {
    vRefreshRequestParams: VRefreshRequestParams;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Get info about system health
     * Get info about system health
     */
    async getHealthRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/health`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VResponseFromJSON(jsonValue));
    }

    /**
     * Get info about system health
     * Get info about system health
     */
    async getHealth(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VResponse> {
        const response = await this.getHealthRaw(initOverrides);
        return await response.value();
    }

    /**
     * Login with email and password
     * Login
     */
    async loginRaw(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VCurrentUser>> {
        if (requestParameters.vLoginRequestParams === null || requestParameters.vLoginRequestParams === undefined) {
            throw new runtime.RequiredError('vLoginRequestParams','Required parameter requestParameters.vLoginRequestParams was null or undefined when calling login.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VLoginRequestParamsToJSON(requestParameters.vLoginRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VCurrentUserFromJSON(jsonValue));
    }

    /**
     * Login with email and password
     * Login
     */
    async login(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VCurrentUser> {
        const response = await this.loginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a new access token with a valid refresh token
     * Refresh
     */
    async refreshRaw(requestParameters: RefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VCurrentUser>> {
        if (requestParameters.vRefreshRequestParams === null || requestParameters.vRefreshRequestParams === undefined) {
            throw new runtime.RequiredError('vRefreshRequestParams','Required parameter requestParameters.vRefreshRequestParams was null or undefined when calling refresh.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VRefreshRequestParamsToJSON(requestParameters.vRefreshRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VCurrentUserFromJSON(jsonValue));
    }

    /**
     * Get a new access token with a valid refresh token
     * Refresh
     */
    async refresh(requestParameters: RefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VCurrentUser> {
        const response = await this.refreshRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
