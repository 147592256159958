import { DateTime } from 'luxon';
import appConfig from '../config.app';

export function getStrCurrTimePlus(plusSeconds: number): string {
  return DateTime.now().plus({ seconds: plusSeconds }).toISO() as string;
}

export function strToJSDate(strDateTime?: string | null): Date | null {
  if (!strDateTime) return null;
  const dt = DateTime.fromISO(strDateTime);
  return dt.isValid ? dt.toJSDate() : null;
}

export function getDiffDaysStrDate(days?: number): string | null {
  if (!days) return DateTime.now().toISO();
  return DateTime.now().plus({ days }).toISO();
}

export function getStartOrEndOfDay(
  isStart: boolean,
  strDateTime?: string | null,
): string | null {
  if (!strDateTime) return null;
  const dt = DateTime.fromISO(strDateTime);
  if (!dt.isValid) return null;
  return isStart ? dt.startOf('day').toISO() : dt.endOf('day').toISO();
}

export function secondsToMins(seconds: number): number {
  return Math.floor(seconds / 60);
}

export function isPastTime(strDateTime?: string | null): boolean {
  const jsDate = strToJSDate(strDateTime);
  if (!jsDate) return true;
  return DateTime.now().toMillis() > jsDate.getTime();
}

export function strDateToLocal(strDateTime?: string): string | null {
  const jsDate = strToJSDate(strDateTime);
  if (!jsDate) return null;
  return jsDate.toLocaleString(appConfig.locale);
}

export function strDateToBetterLocal(strDateTime?: string): string | null {
  if (!strDateTime) return null;
  const dt = DateTime.fromISO(strDateTime);
  if (!dt.isValid) return null;
  const now = DateTime.now();
  if (dt.toISODate() === now.toISODate()) {
    return `Today ${dt.toJSDate().toLocaleTimeString(appConfig.locale)}`;
  }

  if (dt.year === now.year) {
    return dt.toJSDate().toLocaleString(
      appConfig.locale,
      {
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      },
    );
  }
  return dt.toJSDate().toLocaleString(
    appConfig.locale,
    {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
  );
}

export function strDateToBriefLocal(strDateTime?: string): string | null {
  if (!strDateTime) return null;
  const dt = DateTime.fromISO(strDateTime);
  if (!dt.isValid) return null;
  const now = DateTime.now();
  if (dt.toISODate() === now.toISODate()) {
    return dt.toJSDate().toLocaleString(
      appConfig.locale,
      {
        hour: 'numeric',
        minute: '2-digit',
      },
    );
  }

  if (dt.year === now.year) {
    return dt.toJSDate().toLocaleString(
      appConfig.locale,
      {
        month: 'short',
        day: '2-digit',
      },
    );
  }
  return dt.toJSDate().toLocaleString(
    appConfig.locale,
    {
      year: 'numeric',
      month: 'short',
    },
  );
}

export function strDateTimeToHHMM(strDateTime?: string): string | null {
  if (!strDateTime) return null;
  const dt = DateTime.fromISO(strDateTime);
  if (!dt.isValid) return null;
  return dt.toJSDate().toLocaleString(
    appConfig.locale,
    {
      hour: 'numeric',
      minute: '2-digit',
    },
  );
}

export function durationToHHMMSS(durationSec?: number): string {
  if (durationSec) {
    const hours = Math.floor(durationSec / 3600);
    const minutes = Math.floor((durationSec % 3600) / 60);
    const seconds = Math.floor(durationSec % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  return '00:00:00';
}

export function strDateToDuration(strDateTime?: string): string {
  if (!strDateTime) return '';
  const dt = DateTime.fromISO(strDateTime);
  if (!dt.isValid) return '';
  const now = DateTime.now();
  if (dt < now) {
    const duration = now.diff(dt, ['seconds']).toObject()?.seconds;
    return `${durationToHHMMSS(duration)} ago`;
  }
  const duration = dt.diff(now, ['seconds']).toObject()?.seconds;
  return `Remaining ${durationToHHMMSS(duration)}`;
}

export function correctNumber(fractionDigits: number, val?: string | number | null) {
  const num = Number(val);
  return Number.isNaN(num) ? val : parseFloat(num.toFixed(fractionDigits));
}

export function strToNumberOrUndefined(val?: string | null): number | undefined {
  if (typeof val !== 'string') return undefined;
  const trimedVal = val.trim();
  if (trimedVal === '') return undefined;
  const num = Number(trimedVal);
  if (Number.isNaN(num)) return undefined;
  return num;
}

export function numberToString(num: number | undefined | null): string {
  if (num === undefined || num === null) return '';
  return num.toString();
}

export function formatValue(
  value?: string | number | null,
  unit?: string,
  fixedCount?: number,
): string {
  if (value === undefined || value === null) {
    return appConfig.noDataSign;
  }
  let result = value;
  if (fixedCount) {
    result = typeof value === 'number' ? value.toFixed(fixedCount) : value;
  }
  return unit ? `${result} ${unit}` : `${result}`;
}

export function getDayStartOfTime(givenTime: string) {
  const givenDateTime = DateTime.fromISO(givenTime);
  return givenDateTime.startOf('day').toISO();
}

export function getNextDayStartOfTime(givenTime: string) {
  const givenDateTime = DateTime.fromISO(givenTime);
  return givenDateTime.plus({ days: 1 }).startOf('day').toISO();
}

export function isTheSameDay(strTime1: string, strTime2: string): boolean {
  const dt1 = DateTime.fromISO(strTime1);
  const dt2 = DateTime.fromISO(strTime2);
  return dt1.toISODate() === dt2.toISODate();
}
