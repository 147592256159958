/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VUserClinicRoleEnum } from './VUserClinicRoleEnum';
import {
    VUserClinicRoleEnumFromJSON,
    VUserClinicRoleEnumFromJSONTyped,
    VUserClinicRoleEnumToJSON,
} from './VUserClinicRoleEnum';

/**
 * 
 * @export
 * @interface VUserClinic
 */
export interface VUserClinic {
    /**
     * User ID
     * @type {string}
     * @memberof VUserClinic
     */
    userId?: string;
    /**
     * Clinic ID
     * @type {string}
     * @memberof VUserClinic
     */
    clinicId?: string;
    /**
     * 
     * @type {VUserClinicRoleEnum}
     * @memberof VUserClinic
     */
    role: VUserClinicRoleEnum;
}

/**
 * Check if a given object implements the VUserClinic interface.
 */
export function instanceOfVUserClinic(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function VUserClinicFromJSON(json: any): VUserClinic {
    return VUserClinicFromJSONTyped(json, false);
}

export function VUserClinicFromJSONTyped(json: any, ignoreDiscriminator: boolean): VUserClinic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'clinicId': !exists(json, 'clinicId') ? undefined : json['clinicId'],
        'role': VUserClinicRoleEnumFromJSON(json['role']),
    };
}

export function VUserClinicToJSON(value?: VUserClinic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'clinicId': value.clinicId,
        'role': VUserClinicRoleEnumToJSON(value.role),
    };
}

