import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TankList from './features/tankList';
import {
  clearFilter,
  fetchClinic,
  fetchNotifications,
  fetchTanks,
  toggleFilter,
  updateNotification,
  reset,
  changeRightBarType,
} from './_slice';
import { useAppDispatch, useAppSelector } from '../../hook';
import appConfig from '../../config.app';
import { VNotificationStatusEnum } from '../../services';
import NotificationList from './features/notificationList';
import TankFilter from './features/tankFilter';
import Layout2 from '../../layouts/layout2';
import ClinicTopBar from './features/clinicTopBar';
import Breadcrumbs from '../../components/breadcrumbs';
import { EnumRightBarType } from '../../common/models';

interface RouteParams {
  clinicId: string;
  [key: string]: string;
}

const ClinicDashboard: React.FC = () => {
  const dashboardState = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  const { clinicId } = useParams<RouteParams>();

  useEffect(() => {
    dispatch(reset());
    dispatch(fetchClinic({ clinicId: clinicId as string }));
    dispatch(fetchTanks({ clinicId: clinicId as string, needLoading: true }));
    dispatch(fetchNotifications({ clinicId: clinicId as string }));
    const intervalId = setInterval(() => {
      dispatch(fetchTanks({ clinicId: clinicId as string }));
      dispatch(fetchNotifications({ clinicId: clinicId as string }));
    }, appConfig.pollIntervalSeconds * 1000);

    return () => clearInterval(intervalId);
  }, [clinicId, dispatch]);

  return (
    <Layout2
      breadcrumbsSlot={
        <Breadcrumbs
          breadcrumbs={[{ label: 'Clinics', path: '/clinics' }]}
          title={dashboardState.clinic ? dashboardState.clinic.name : ''}
        />
      }
      contentSlot={
        <TankList
          clinicId={clinicId}
          tanks={dashboardState.filteredTanks}
          status={dashboardState.tanksLoadingStatus}
        />
      }
      topSlot={
        <ClinicTopBar
          clinic={ dashboardState.clinic }
          rightBarType={ dashboardState.rightBarType }
          notifications={ dashboardState.notifications }
          onChangeRightBarType={(x) => dispatch(changeRightBarType(x))}
        />
      }
      leftSlot={
        <TankFilter
          total={dashboardState.tanks.length}
          statusFilter={ dashboardState.statusFilter }
          onToggleFilter={ (key) => { dispatch(toggleFilter(key)); } }
          onClearFilter={ () => { dispatch(clearFilter()); } }
        />
      }
      rightSlotHide={dashboardState.rightBarType !== EnumRightBarType.History}
      rightSlot={
        <NotificationList
          onDismiss={
            (notificationId) => dispatch(
              updateNotification({
                notificationId,
                status: VNotificationStatusEnum.Dismissed,
              }),
            )
          }
          onSwitchStatus={
            (notificationId, status) => dispatch(
              updateNotification({
                notificationId,
                status,
              }),
            )
          }
          style={{}}
          notifications={dashboardState.notifications}
        />
      }
    />
  );
};

export default ClinicDashboard;
