/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const VNotificationTypeEnum = {
    TankWeightLow: 'Tank Weight Low',
    TankWeightHigh: 'Tank Weight High',
    TankWeightLossFast: 'Tank Weight Loss Fast',
    TankWeightLossCritical: 'Tank Weight Loss Critical',
    TankEvaporationRateLow: 'Tank Evaporation Rate Low',
    TankEvaporationRateHigh: 'Tank Evaporation Rate High',
    TankMuted: 'Tank Muted',
    TankOffline: 'Tank Offline',
    CaddyBatteryLow: 'Caddy Battery Low',
    CaddyBatteryCritical: 'Caddy Battery Critical',
    CaddyTemperatureLow: 'Caddy Temperature Low',
    TankConfigSynced: 'Tank Config Synced',
    CaddyConfigSynced: 'Caddy Config Synced'
} as const;
export type VNotificationTypeEnum = typeof VNotificationTypeEnum[keyof typeof VNotificationTypeEnum];


export function VNotificationTypeEnumFromJSON(json: any): VNotificationTypeEnum {
    return VNotificationTypeEnumFromJSONTyped(json, false);
}

export function VNotificationTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): VNotificationTypeEnum {
    return json as VNotificationTypeEnum;
}

export function VNotificationTypeEnumToJSON(value?: VNotificationTypeEnum | null): any {
    return value as any;
}

