import * as React from 'react';
import { Text } from '@fluentui/react-components';
import BetterIcon, { BetterIconName } from './betterIcon';
import { lightThemeColors } from '../common/themes';

export interface IBetterBadgeProps {
  icon: BetterIconName;
  label?: string;
  color: string;
  extended?: boolean;
}

const BetterBadge: React.FC<IBetterBadgeProps> = ({
  icon,
  label,
  color,
  extended,
}) => {
  const [status, setStatus] = React.useState<boolean>(!!extended);
  const onStatus = (newStatus: boolean) => {
    if (!extended) {
      setStatus(newStatus);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: status && label ? color : lightThemeColors.borderColor,
        borderStyle: 'solid',
        borderRadius: 20,
        padding: 3,
        paddingRight: status && label ? 8 : 'unset',
        gap: 4,
        transition: 'all .1s ease-in-out',
        cursor: 'default',
      }}
      onMouseEnter={() => onStatus(true)}
      onMouseLeave={() => onStatus(false)}
    >
      <BetterIcon name={icon} color={color} style={{ width: 20, height: 20 }}/>
      <Text size={200} weight='bold' style={{ color, whiteSpace: 'nowrap' }}>{status ? label : ''}</Text>
    </div>
  );
};

export default BetterBadge;
