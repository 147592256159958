/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  VLiteReading,
  VQueryReadingsRequestParams,
  VReading,
} from '../models/index';
import {
    VLiteReadingFromJSON,
    VLiteReadingToJSON,
    VQueryReadingsRequestParamsFromJSON,
    VQueryReadingsRequestParamsToJSON,
    VReadingFromJSON,
    VReadingToJSON,
} from '../models/index';

export interface QueryLiteReadingsRequest {
    vQueryReadingsRequestParams: VQueryReadingsRequestParams;
}

export interface QueryReadingsRequest {
    vQueryReadingsRequestParams: VQueryReadingsRequestParams;
}

/**
 * 
 */
export class ReadingApi extends runtime.BaseAPI {

    /**
     * Query readings including only some fields by all kinds of params, like start date, end date
     * Query lite readings
     */
    async queryLiteReadingsRaw(requestParameters: QueryLiteReadingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VLiteReading>>> {
        if (requestParameters.vQueryReadingsRequestParams === null || requestParameters.vQueryReadingsRequestParams === undefined) {
            throw new runtime.RequiredError('vQueryReadingsRequestParams','Required parameter requestParameters.vQueryReadingsRequestParams was null or undefined when calling queryLiteReadings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reading/queryLiteReadings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VQueryReadingsRequestParamsToJSON(requestParameters.vQueryReadingsRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VLiteReadingFromJSON));
    }

    /**
     * Query readings including only some fields by all kinds of params, like start date, end date
     * Query lite readings
     */
    async queryLiteReadings(requestParameters: QueryLiteReadingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VLiteReading>> {
        const response = await this.queryLiteReadingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Query readings by all kinds of params, like start date, end date
     * Query readings
     */
    async queryReadingsRaw(requestParameters: QueryReadingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VReading>>> {
        if (requestParameters.vQueryReadingsRequestParams === null || requestParameters.vQueryReadingsRequestParams === undefined) {
            throw new runtime.RequiredError('vQueryReadingsRequestParams','Required parameter requestParameters.vQueryReadingsRequestParams was null or undefined when calling queryReadings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reading/queryReadings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VQueryReadingsRequestParamsToJSON(requestParameters.vQueryReadingsRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VReadingFromJSON));
    }

    /**
     * Query readings by all kinds of params, like start date, end date
     * Query readings
     */
    async queryReadings(requestParameters: QueryReadingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VReading>> {
        const response = await this.queryReadingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
