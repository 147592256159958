/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VUpdateUserRequestParams
 */
export interface VUpdateUserRequestParams {
    /**
     * Name of the display (Only useful for displays)
     * @type {string}
     * @memberof VUpdateUserRequestParams
     */
    name?: string;
    /**
     * First name of the user
     * @type {string}
     * @memberof VUpdateUserRequestParams
     */
    firstName?: string;
    /**
     * Surname of the user
     * @type {string}
     * @memberof VUpdateUserRequestParams
     */
    surname?: string;
    /**
     * Email of the user
     * @type {string}
     * @memberof VUpdateUserRequestParams
     */
    email?: string;
    /**
     * The user is an admin or not
     * @type {boolean}
     * @memberof VUpdateUserRequestParams
     */
    isSuperAdmin?: boolean;
    /**
     * The user is a display or not
     * @type {boolean}
     * @memberof VUpdateUserRequestParams
     */
    isDisplay?: boolean;
    /**
     * The user is active or not
     * @type {boolean}
     * @memberof VUpdateUserRequestParams
     */
    active?: boolean;
    /**
     * Plain password of the user
     * @type {string}
     * @memberof VUpdateUserRequestParams
     */
    password?: string;
}

/**
 * Check if a given object implements the VUpdateUserRequestParams interface.
 */
export function instanceOfVUpdateUserRequestParams(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VUpdateUserRequestParamsFromJSON(json: any): VUpdateUserRequestParams {
    return VUpdateUserRequestParamsFromJSONTyped(json, false);
}

export function VUpdateUserRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VUpdateUserRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'isSuperAdmin': !exists(json, 'isSuperAdmin') ? undefined : json['isSuperAdmin'],
        'isDisplay': !exists(json, 'isDisplay') ? undefined : json['isDisplay'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'password': !exists(json, 'password') ? undefined : json['password'],
    };
}

export function VUpdateUserRequestParamsToJSON(value?: VUpdateUserRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'firstName': value.firstName,
        'surname': value.surname,
        'email': value.email,
        'isSuperAdmin': value.isSuperAdmin,
        'isDisplay': value.isDisplay,
        'active': value.active,
        'password': value.password,
    };
}

