/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const VFileTypeEnum = {
    Version: 'Version',
    Manual: 'Manual'
} as const;
export type VFileTypeEnum = typeof VFileTypeEnum[keyof typeof VFileTypeEnum];


export function VFileTypeEnumFromJSON(json: any): VFileTypeEnum {
    return VFileTypeEnumFromJSONTyped(json, false);
}

export function VFileTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): VFileTypeEnum {
    return json as VFileTypeEnum;
}

export function VFileTypeEnumToJSON(value?: VFileTypeEnum | null): any {
    return value as any;
}

