/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import apiServices from '../../services';
import { Result } from '../../common/models';
import appConfig from '../../config.app';
import { RootState } from '../../store';

interface LoginState {
  username: string;
  password: string;
  isPasswordShown: boolean;
  errorMessage: string;
  isSubmitting: boolean;
}

const initialState: LoginState = {
  username: '',
  password: '',
  isPasswordShown: false,
  errorMessage: '',
  isSubmitting: false,
};

// Handle actions in your reducers
const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    changeUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    changePassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    showPassword: (state) => {
      state.isPasswordShown = !state.isPasswordShown;
    },
    setSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
    },
    loginFail: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
  },
});

export const login = createAsyncThunk<Result>(
  'login',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(loginSlice.actions.setSubmitting(true));
    try {
      const loginState = (thunkAPI.getState() as RootState).login as LoginState;
      await apiServices.login(loginState.username, loginState.password);
      return { result: true, message: '' };
    } catch (err) {
      const errMsg = (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR;
      thunkAPI.dispatch(loginSlice.actions.loginFail(errMsg));
      thunkAPI.dispatch(loginSlice.actions.setSubmitting(false));
      return { result: false, message: errMsg };
    }
  },
);

export const {
  changeUsername,
  changePassword,
  showPassword,
  setSubmitting,
  loginFail,
} = loginSlice.actions;
export default loginSlice.reducer;
