/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const VUserClinicRoleEnum = {
    SuperAdmin: 'Super Admin',
    SiteAdmin: 'Site Admin',
    User: 'User',
    Display: 'Display'
} as const;
export type VUserClinicRoleEnum = typeof VUserClinicRoleEnum[keyof typeof VUserClinicRoleEnum];


export function VUserClinicRoleEnumFromJSON(json: any): VUserClinicRoleEnum {
    return VUserClinicRoleEnumFromJSONTyped(json, false);
}

export function VUserClinicRoleEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): VUserClinicRoleEnum {
    return json as VUserClinicRoleEnum;
}

export function VUserClinicRoleEnumToJSON(value?: VUserClinicRoleEnum | null): any {
    return value as any;
}

