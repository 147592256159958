/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  VAddUserRequestParams,
  VQueryUsersRequestParams,
  VUpdateUserRequestParams,
  VUser,
} from '../models/index';
import {
    VAddUserRequestParamsFromJSON,
    VAddUserRequestParamsToJSON,
    VQueryUsersRequestParamsFromJSON,
    VQueryUsersRequestParamsToJSON,
    VUpdateUserRequestParamsFromJSON,
    VUpdateUserRequestParamsToJSON,
    VUserFromJSON,
    VUserToJSON,
} from '../models/index';

export interface AddUserRequest {
    vAddUserRequestParams: VAddUserRequestParams;
}

export interface GetUserRequest {
    userId: string;
}

export interface QueryUsersRequest {
    vQueryUsersRequestParams: VQueryUsersRequestParams;
}

export interface UpdateUserRequest {
    userId: string;
    vUpdateUserRequestParams: VUpdateUserRequestParams;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Add a new user
     * Add a new user
     */
    async addUserRaw(requestParameters: AddUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VUser>> {
        if (requestParameters.vAddUserRequestParams === null || requestParameters.vAddUserRequestParams === undefined) {
            throw new runtime.RequiredError('vAddUserRequestParams','Required parameter requestParameters.vAddUserRequestParams was null or undefined when calling addUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VAddUserRequestParamsToJSON(requestParameters.vAddUserRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VUserFromJSON(jsonValue));
    }

    /**
     * Add a new user
     * Add a new user
     */
    async addUser(requestParameters: AddUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VUser> {
        const response = await this.addUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of active users excluding super admins and displays
     * Get a list of active users excluding super admins and displays
     */
    async getNormalUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VUser>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/getNormalUsers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VUserFromJSON));
    }

    /**
     * Get a list of active users excluding super admins and displays
     * Get a list of active users excluding super admins and displays
     */
    async getNormalUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VUser>> {
        const response = await this.getNormalUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a user by ID
     * Get a user
     */
    async getUserRaw(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VUser>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VUserFromJSON(jsonValue));
    }

    /**
     * Get a user by ID
     * Get a user
     */
    async getUser(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VUser> {
        const response = await this.getUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of active users
     * Get a list of active users
     */
    async getUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VUser>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VUserFromJSON));
    }

    /**
     * Get a list of active users
     * Get a list of active users
     */
    async getUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VUser>> {
        const response = await this.getUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Query users by all kinds of params
     * Query users
     */
    async queryUsersRaw(requestParameters: QueryUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VUser>>> {
        if (requestParameters.vQueryUsersRequestParams === null || requestParameters.vQueryUsersRequestParams === undefined) {
            throw new runtime.RequiredError('vQueryUsersRequestParams','Required parameter requestParameters.vQueryUsersRequestParams was null or undefined when calling queryUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/queryUsers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VQueryUsersRequestParamsToJSON(requestParameters.vQueryUsersRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VUserFromJSON));
    }

    /**
     * Query users by all kinds of params
     * Query users
     */
    async queryUsers(requestParameters: QueryUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VUser>> {
        const response = await this.queryUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a user by ID
     * Update a user.
     */
    async updateUserRaw(requestParameters: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VUser>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUser.');
        }

        if (requestParameters.vUpdateUserRequestParams === null || requestParameters.vUpdateUserRequestParams === undefined) {
            throw new runtime.RequiredError('vUpdateUserRequestParams','Required parameter requestParameters.vUpdateUserRequestParams was null or undefined when calling updateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VUpdateUserRequestParamsToJSON(requestParameters.vUpdateUserRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VUserFromJSON(jsonValue));
    }

    /**
     * Update a user by ID
     * Update a user.
     */
    async updateUser(requestParameters: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VUser> {
        const response = await this.updateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
