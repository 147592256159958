/* eslint-disable no-param-reassign */
import {
  createSlice, PayloadAction, createAsyncThunk, current,
} from '@reduxjs/toolkit';
import apiServices, { VCaddy, VClinic, VFile } from '../../services';
import appConfig from '../../config.app';
import { RootState } from '../../store';
import { formatErrorMsg } from '../../common/helper';

interface CaddyInput {
  serial: string;
  firmwareVersion: string;
  targetFirmwareVersionId: string;
  firmwareVersionTitle: string;
  clinicId: string;
  clinicName: string;
  confTime?: string;
}

interface CaddyState {
  caddies: VCaddy[];
  clinics: VClinic[];
  firmwareVersions: VFile[];
  selectedClinicId: string;
  selectedCaddyId: string;
  selectedCaddySerial: string;
  caddyInput: CaddyInput;
  savable: boolean;
  message: string | null;
  isSuccess: boolean;
}

const initialState: CaddyState = {
  caddies: [],
  clinics: [],
  firmwareVersions: [],
  selectedClinicId: '',
  selectedCaddyId: '',
  selectedCaddySerial: '',
  caddyInput: {
    serial: '',
    firmwareVersion: '',
    targetFirmwareVersionId: '',
    firmwareVersionTitle: '',
    clinicId: '',
    clinicName: '',
    confTime: '',
  },
  savable: false,
  message: null,
  isSuccess: false,
};

// Handle actions in your reducers
const caddySlice = createSlice({
  name: 'caddyManage',
  initialState,
  reducers: {
    fetchCaddies: (state, action: PayloadAction<VCaddy[]>) => {
      state.caddies = action.payload?.sort((a, b) => a.serial.localeCompare(b.serial));
      state.selectedCaddyId = '';
      state.selectedCaddySerial = '';
      const clinics = current(state.clinics);
      const mapClinics: { [key:string]: string } = {};
      clinics.forEach((x) => {
        mapClinics[x.id] = x.name;
      });
    },
    fetchClinics: (state, action: PayloadAction<VClinic[]>) => {
      state.clinics = action.payload;
      state.caddyInput.clinicId = '';
    },
    fetchFirmwareVersions: (state, action: PayloadAction<VFile[]>) => {
      state.firmwareVersions = action.payload;
    },
    changeCaddySerial: (state, action: PayloadAction<string>) => {
      state.caddyInput.serial = action.payload;
      state.savable = true;
    },
    changeCaddyClinicId: (state, action: PayloadAction<string>) => {
      const clinics = current(state.clinics);
      const clinicId = action.payload;
      const clinic = clinics.find((x) => x.id === clinicId);
      state.caddyInput.clinicId = clinicId;
      state.caddyInput.clinicName = clinic ? clinic.name : appConfig.label.NOT_SELECTED;
      state.savable = true;
    },
    changeCaddyFirmwareVersionId: (state, action: PayloadAction<string>) => {
      const firmwareVersions = current(state.firmwareVersions);
      const versionId = action.payload;
      const version = firmwareVersions.find((x) => x.id === versionId);
      state.caddyInput.targetFirmwareVersionId = versionId;
      state.caddyInput.firmwareVersionTitle = version
        ? version.title
        : appConfig.label.NOT_SELECTED;
      state.savable = true;
    },
    changeFilterClinicId: (state, action: PayloadAction<string>) => {
      state.selectedClinicId = action.payload;
      state.selectedCaddyId = '';
    },
    changeCaddyId: (state, action: PayloadAction<string>) => {
      const caddies = current(state.caddies);
      const clinics = current(state.clinics);
      const firmwareVersions = current(state.firmwareVersions);
      const caddyId = action.payload;
      // Check if the caddy exists
      const caddy = caddies.find((c) => c.id === caddyId);
      if (!caddy) return;
      const clinic = clinics.find((x) => x.id === caddy.clinicId);
      const firmwareVersion = firmwareVersions.find((x) => x.id === caddy.targetFirmwareVersionId);
      // Set basic info
      state.selectedCaddyId = caddyId;
      state.selectedCaddySerial = caddy.serial;
      state.caddyInput.serial = caddy.serial;
      state.caddyInput.clinicId = caddy.clinicId ?? '';
      state.caddyInput.clinicName = clinic?.name ?? '';
      state.caddyInput.firmwareVersion = caddy.firmwareVersion ?? '';
      state.caddyInput.targetFirmwareVersionId = caddy.targetFirmwareVersionId ?? '';
      state.caddyInput.firmwareVersionTitle = firmwareVersion?.title ?? '';
      state.caddyInput.confTime = caddy.confTime;
      state.savable = false;
    },
    reset: (state) => {
      state.message = '';
      state.isSuccess = false;
      state.selectedClinicId = '';
      state.caddyInput.serial = '';
      state.caddyInput.targetFirmwareVersionId = '';
      state.caddyInput.firmwareVersionTitle = '';
      state.caddyInput.clinicId = '';
      state.caddyInput.clinicName = '';
      state.caddyInput.confTime = '';
      state.savable = false;
    },
    resetCaddySerial: (state) => {
      state.caddyInput.serial = '';
      state.savable = false;
    },
    success: (state, action: PayloadAction<string | null>) => {
      state.message = action.payload;
      state.isSuccess = true;
    },
    fail: (state, action: PayloadAction<string | null>) => {
      state.message = formatErrorMsg(action.payload);
      state.isSuccess = false;
    },
  },
});

export const fetchData = createAsyncThunk<void, { defaultCaddyId?: string }>(
  'caddyManage/fetchData',
  async (params, thunkAPI) => {
    try {
      const result = await Promise.all([
        apiServices.getCaddyApi().getCaddies(),
        apiServices.getClinicApi().getClinics(),
        apiServices.getFileApi().queryFiles({
          vQueryFilesRequestParams: { type: 'Version' },
        }),
      ]);
      thunkAPI.dispatch(caddySlice.actions.fetchClinics(result[1]));
      thunkAPI.dispatch(caddySlice.actions.fetchCaddies(result[0]));
      thunkAPI.dispatch(caddySlice.actions.fetchFirmwareVersions(result[2]));
      if (params.defaultCaddyId) {
        thunkAPI.dispatch(caddySlice.actions.changeCaddyId(params.defaultCaddyId));
      }
    } catch (err) {
      thunkAPI.dispatch(caddySlice.actions.fail(
        (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR,
      ));
    }
  },
);

export const addCaddy = createAsyncThunk(
  'caddyManage/addCaddy',
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(caddySlice.actions.success(null));
      const caddyState = (thunkAPI.getState() as RootState).caddyManage as CaddyState;
      await apiServices.getCaddyApi().addCaddy({
        vAddCaddyRequestParams: {
          serial: caddyState.caddyInput.serial,
          targetFirmwareVersionId: caddyState.caddyInput.targetFirmwareVersionId ?? undefined,
          clinicId: caddyState.caddyInput.clinicId ?? undefined,
        },
      });
      thunkAPI.dispatch(caddySlice.actions.success(appConfig.message.CADDY_ADDED));
      thunkAPI.dispatch(caddySlice.actions.resetCaddySerial());
    } catch (err) {
      const errMsg = (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR;
      thunkAPI.dispatch(caddySlice.actions.fail(errMsg));
    }
  },
);

export const updateCaddy = createAsyncThunk(
  'caddyManage/updateCaddy',
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(caddySlice.actions.success(null));
      const caddyState = (thunkAPI.getState() as RootState).caddyManage as CaddyState;
      await apiServices.getCaddyApi().updateCaddy({
        caddyId: caddyState.selectedCaddyId,
        vUpdateCaddyRequestParams: {
          serial: caddyState.caddyInput.serial,
          targetFirmwareVersionId: caddyState.caddyInput.targetFirmwareVersionId,
          clinicId: caddyState.caddyInput.clinicId,
        },
      });
      thunkAPI.dispatch(fetchData({ defaultCaddyId: caddyState.selectedCaddyId }));
      thunkAPI.dispatch(caddySlice.actions.success(appConfig.message.CADDY_UPDATED));
    } catch (err) {
      const errMsg = (err instanceof Error) ? err.message : appConfig.errMsg.UNEXPECTED_ERROR;
      thunkAPI.dispatch(caddySlice.actions.fail(errMsg));
    }
  },
);

export const {
  changeCaddySerial,
  changeCaddyClinicId,
  changeCaddyId,
  changeFilterClinicId,
  changeCaddyFirmwareVersionId,
  reset,
} = caddySlice.actions;
export default caddySlice.reducer;
