import React from 'react';
import {
  Button,
  Card,
  Dropdown,
  InfoLabel,
  Option,
  Text,
} from '@fluentui/react-components';
import { Dismiss12Regular } from '@fluentui/react-icons';
import { OptionEntity } from '../common/models';

interface ISelectMultipleEntitiesProps {
  title: string;
  entities: OptionEntity[]; // Add further interfaces as required
  selectedEntities: OptionEntity[];
  disabled: boolean;
  onSelectEntity: (id: string) => void;
  onUnselectEntity: (id: string) => void;
  placeholder: string;
  tooltip?: string;
}

/**
 * Allows the selection of multiple entities, e.g., choosing displays for a tank
 */
const SelectMultipleEntities: React.FC<ISelectMultipleEntitiesProps> = (props) => {
  const onSelectEntity = (userId: string) => {
    props.onSelectEntity(userId);
  };
  const onUnselectEntity = (userId: string) => {
    props.onUnselectEntity(userId);
  };

  return (
    <Card style={{
      padding: 20,
      overflow: 'visible',
    }}>
      <div style={{
        display: 'flex',
      }}>
        <div style={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
        }}>
          <Text weight="bold" size={400}>
            {props.title} { props.tooltip ? <InfoLabel info={props.tooltip}></InfoLabel> : null }
          </Text>
        </div>
        <div style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
          <Dropdown
            aria-labelledby="entity"
            placeholder={props.placeholder}
            onOptionSelect={(_, item) => {
              if (item?.optionValue) onSelectEntity(item.optionValue);
              else onSelectEntity(''); // For the None option
            }}
            selectedOptions={[]}
            disabled={props.disabled}
          >
            {props.entities.map((entity) => (
              <Option
                key={entity.id}
                value={entity.id}
                text={entity.label}
                checkIcon={null}
              >
                {entity.label}
              </Option>
            ))}
          </Dropdown>
        </div>
      </div>
      <div style={{
        height: '20px',
      }}>
        <ul style={{
          listStyle: 'none',
          padding: 0,
          margin: 0,
          display: 'flex',
          flexWrap: 'wrap',
        }}>
          {props.selectedEntities.map((entity) => (
            <li
              key={entity.id}
              style={{
                marginRight: 4,
                marginBottom: 4,
              }}
            >
              <Button
                style={{
                  borderRadius: 20,
                  paddingRight: 4,
                }}
                shape="circular"
                appearance="primary"
                size='small'
                iconPosition='after'
                icon={ <Dismiss12Regular /> }
                onClick={ () => onUnselectEntity(entity.id) }
              >
                {entity.label}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </Card>
  );
};

export default SelectMultipleEntities;
