import { BetterIconName } from '../components/betterIcon';
import { IStatWidgetProps } from '../components/statWidget';

export interface Result {
  result: boolean;
  message: string;
}

export interface IDashboardFilter {
  [key: string]: {
    name: string;
    color: string;
    icon: BetterIconName;
    isSelected: boolean;
    count: number;
  };
}

export interface ILevel {
  level: number;
  icon: BetterIconName;
  color: string;
  status?: string;
  statusIcon?: BetterIconName;
}

export interface OptionEntity {
  id: string;
  label: string;
}

export interface IStatusCheck {
  prop: string;
  value: boolean;
}

export interface IStatusCategory {
  name: string;
  icon: BetterIconName;
  color: string;
  desc: string;
  check: (tank: object) => boolean;
}

export interface IEventCategory {
  name: string;
  icon: BetterIconName;
  color: string;
  desc: string;
}

export interface IStatusWidget {
  title: string;
  unit?: string;
  check: (obj: object) => IStatWidgetProps | null;
}

export interface IChartCategory {
  name: string;
  label: string;
  color: string;
  icon: BetterIconName;
}

export type IWidgetName = 'weight' | 'evaporationRate' | 'batteryVoltage' | 'lastReadingTime' | 'temperature';

export type ILoadingStatus = 'loading' | 'success' | 'error';

export const EnumRightBarType = {
  History: 'History',
  Settings: 'Settings',
} as const;
export type EnumRightBarType = typeof EnumRightBarType[keyof typeof EnumRightBarType];
