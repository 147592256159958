/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VFileTypeEnum } from './VFileTypeEnum';
import {
    VFileTypeEnumFromJSON,
    VFileTypeEnumFromJSONTyped,
    VFileTypeEnumToJSON,
} from './VFileTypeEnum';

/**
 * 
 * @export
 * @interface VQueryFilesRequestParams
 */
export interface VQueryFilesRequestParams {
    /**
     * 
     * @type {VFileTypeEnum}
     * @memberof VQueryFilesRequestParams
     */
    type: VFileTypeEnum;
}

/**
 * Check if a given object implements the VQueryFilesRequestParams interface.
 */
export function instanceOfVQueryFilesRequestParams(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function VQueryFilesRequestParamsFromJSON(json: any): VQueryFilesRequestParams {
    return VQueryFilesRequestParamsFromJSONTyped(json, false);
}

export function VQueryFilesRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VQueryFilesRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': VFileTypeEnumFromJSON(json['type']),
    };
}

export function VQueryFilesRequestParamsToJSON(value?: VQueryFilesRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': VFileTypeEnumToJSON(value.type),
    };
}

