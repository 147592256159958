import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeftRegular, Person20Filled, DocumentArrowDown20Regular } from '@fluentui/react-icons';
import {
  Avatar, Button, Menu, MenuPopover, MenuTrigger, Text,
} from '@fluentui/react-components';
import logo from '../assets/images/logo.png';
import apiServices from '../services';
import { toDisplayName } from '../common/helper';

const Header: React.FC<{ back?: boolean }> = ({ back = true }) => {
  const navigate = useNavigate();
  const currentUser = apiServices.getCurrentUser();

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 20px',
      height: '100%',
    }}>
      {back && (
        <div
          style={{ cursor: 'pointer', marginRight: '20px' }}
          onClick={() => navigate(-1)}
        >
          <ArrowLeftRegular fontSize={24} />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          flex: '1',
        }}
      >
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/home')}
        >
          <img src={logo} style={{ height: 56, display: 'block' }} alt="logo" />
        </div>
        {
          !apiServices.isCloudRuntime()
            ? <div
            style={{
              backgroundColor: '#0093d0',
              borderRadius: 16,
              color: 'white',
              padding: 4,
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'bold',
            }}
          >
            <Text size={100} weight='semibold'>Onsite</Text>
          </div> : null
        }
        {
          apiServices.getServerLabel()
            ? <div
            style={{
              backgroundColor: '#ff6600',
              borderRadius: 16,
              color: 'white',
              padding: 4,
              display: 'flex',
              alignItems: 'center',
              marginLeft: 4,
              fontWeight: 'bold',
            }}
          >
            <Text size={100} weight='semibold'>{apiServices.getServerLabel()}</Text>
          </div> : null
        }
      </div>
      <div style={{ margin: 10, display: 'flex', alignItems: 'center' }}>
        <DocumentArrowDown20Regular />
        <Link to={'/documents'}>
          Documents
        </Link>
      </div>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <Avatar
            name={toDisplayName(currentUser)}
            size={48}
            color={ apiServices.isSuperAdmin() || apiServices.isSiteAdmin() ? 'dark-green' : 'colorful' }
            style={{ cursor: 'pointer' }}
          />
        </MenuTrigger>
        <MenuPopover style={{ padding: 16 }}>
          <div style={{ marginBottom: 16 }}>
            <div style={{
              textAlign: 'center',
              paddingBottom: 6,
              marginBottom: 6,
              display: 'flex',
              alignItems: 'center',
            }}>
              <Person20Filled />
              <Text size={400} style={{ fontWeight: 'bold' }}>
                {currentUser?.firstName} {currentUser?.surname}
              </Text>
            </div>
            <div>Role: {apiServices.getUserRoles()}</div>
            <div>{currentUser?.email}</div>
          </div>
          <Button style={{ width: '100%' }} onClick={() => apiServices.logout()}>Sign Out</Button>
        </MenuPopover>
      </Menu>
    </div>
  );
};

export default Header;
