import React from 'react';
import Header from '../features/header';
import './layout1.css';

const Layout1: React.FC<{
  children: React.ReactNode,
  breadcrumbsSlot?: React.ReactNode,
}> = ({
  children,
  breadcrumbsSlot,
}) => (
  <div>
    <div className='lyt1-header'>
      <Header />
    </div>
    <div className='lyt1-breadcrumbs'>
      {breadcrumbsSlot || null}
    </div>
    <main className='lyt1-main'>
      {children}
    </main>
  </div>
);

export default Layout1;
