import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-luxon';
import {
  Button,
  Text,
  TabList,
  Tab,
} from '@fluentui/react-components';
import { ChartConfiguration } from 'chart.js';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { VLiteReading, VTank } from '../../../services';
import {
  getDayStartOfTime,
  getNextDayStartOfTime,
  isTheSameDay,
  strToJSDate,
} from '../../../common/utils';
import appConfig from '../../../config.app';
import './readingChart.css';
import { IChartCategory } from '../../../common/models';
import BetterIcon, { BetterIconName } from '../../../components/betterIcon';
import IconInfo from '../../../components/iconInfo';

function genLabelsAndDatasets(
  chartCategory: IChartCategory,
  readings: VLiteReading[],
  tank?: VTank | null,
) {
  if (!tank || !readings) {
    return { labels: [], datasets: [] };
  }
  const labels: Array<string | null> = [];
  const values: Array<number | null> = [];
  const minValues: Array<number | null> = [];
  const maxValues: Array<number | null> = [];
  const apexResetValues: Array<number | null> = [];

  if (readings.length > 0) {
    if (chartCategory.name === appConfig.chartCategories[1].name) { // Evaporation Rate
      // Start point
      labels.push(getDayStartOfTime(readings[0].time));
      values.push(null);
      minValues.push(tank.minEvaporationRate);
      maxValues.push(tank.maxEvaporationRate);
      // Readings
      for (let i = 0; i < readings.length; i++) {
        labels.push(readings[i].time);
        values.push(readings[i].tankEvaporationRate);
        minValues.push(tank.minEvaporationRate);
        maxValues.push(tank.maxEvaporationRate);
      }
      // End point
      labels.push(getNextDayStartOfTime(readings[readings.length - 1].time));
      values.push(null);
      minValues.push(tank.minEvaporationRate);
      maxValues.push(tank.maxEvaporationRate);
    } else if (chartCategory.name === appConfig.chartCategories[2].name) { // Ambient Temperature
      // Start point
      labels.push(getDayStartOfTime(readings[0].time));
      values.push(null);
      // Readings
      for (let i = 0; i < readings.length; i++) {
        labels.push(readings[i].time);
        values.push(parseFloat(readings[i].caddyTemperature.toFixed(appConfig.precisionLow)));
      }
      // End point
      labels.push(getNextDayStartOfTime(readings[readings.length - 1].time));
      values.push(null);
    } else { // Tank Weight
      // Start point
      labels.push(getDayStartOfTime(readings[0].time));
      values.push(null);
      minValues.push(tank.minWeight);
      maxValues.push(tank.maxWeight);
      apexResetValues.push(tank.apexResetWeight);
      // Readings
      for (let i = 0; i < readings.length; i++) {
        labels.push(readings[i].time);
        values.push(parseFloat(readings[i].tankWeight.toFixed(appConfig.precisionLow)));
        minValues.push(tank.minWeight);
        maxValues.push(tank.maxWeight);
        apexResetValues.push(tank.apexResetWeight);
      }
      // End point
      labels.push(getNextDayStartOfTime(readings[readings.length - 1].time));
      values.push(null);
      minValues.push(tank.minWeight);
      maxValues.push(tank.maxWeight);
      apexResetValues.push(tank.apexResetWeight);
    }
  }

  const pointRadius = readings.length > 100 ? 2 : 4;
  const pointHoverRadius = pointRadius + 2;
  return {
    labels,
    datasets: [
      {
        label: chartCategory.name,
        data: values,
        borderColor: chartCategory.color,
        backgroundColor: chartCategory.color,
        borderWidth: 1,
        fill: false,
        tension: 0.2,
        pointRadius,
        pointStyle: 'circle',
        pointHoverRadius,
        yAxisID: 'y',
        lineTension: 0.0,
      },
      {
        label: 'Minimum Threshold',
        data: minValues,
        borderDash: [5, 5],
        borderColor: appConfig.chartThresholdColor,
        backgroundColor: appConfig.chartThresholdColor,
        borderWidth: 2,
        fill: false,
        tension: 0.2,
        pointRadius: 0,
        yAxisID: 'y',
        lineTension: 0.0,
      },
      {
        label: 'Maximum Threshold',
        data: maxValues,
        borderDash: [5, 5],
        borderColor: appConfig.chartThresholdColor,
        backgroundColor: appConfig.chartThresholdColor,
        borderWidth: 2,
        fill: false,
        tension: 0.2,
        pointRadius: 0,
        yAxisID: 'y',
        lineTension: 0.0,
      },
      {
        label: 'Apex Reset Threshold',
        data: apexResetValues,
        borderDash: [5, 5],
        borderColor: appConfig.chartApexResetThresholdColor,
        backgroundColor: appConfig.chartApexResetThresholdColor,
        borderWidth: 2,
        fill: false,
        tension: 0.2,
        pointRadius: 0,
        yAxisID: 'y',
        lineTension: 0.0,
      },
    ],
  };
}

interface ReadingChartProps {
  tank?: VTank | null;
  readings: VLiteReading[];
  startDate: string | null;
  endDate: string | null;
  onChangeStartDate: (startDate: string | undefined | null) => void;
  onChangeEndDate: (endDate: string | undefined | null) => void;
  onFitlerReadings: () => void;
}

const ReadingChart: React.FC<ReadingChartProps> = ({
  tank,
  readings,
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  onFitlerReadings,
}) => {
  const chartRef = useRef<Chart | null>(null);
  const [chartCategory, setChartCategory] = React.useState<
    IChartCategory
  >(appConfig.chartCategories[0]);

  useEffect(() => {
    const beginAtZero = chartCategory.name === appConfig.chartCategories[2].name;
    const isSameDay = readings.length > 0
      ? isTheSameDay(readings[0].time, readings[readings.length - 1].time)
      : false;
    const chartOptions: ChartConfiguration['options'] = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      layout: {
        padding: 10,
      },
      scales: {
        x: {
          type: 'time',
          time: {
            unit: isSameDay ? 'hour' : 'day',
          },
          grid: {
            display: true,
          },
          title: {
            display: true,
            text: 'Time',
            font: {
              size: 14,
              weight: 'bold',
            },
            padding: { top: 10 },
          },
          border: {
            width: 2,
          },
        },
        y: {
          title: {
            display: true,
            text: chartCategory.label,
            font: {
              size: 14,
              weight: 'bold',
            },
            padding: { bottom: 10 },
          },
          border: {
            width: 2,
          },
          beginAtZero,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
      },
    };
    if (chartRef.current) {
      const lablesAndDatasets = genLabelsAndDatasets(chartCategory, readings, tank);
      chartRef.current.data.labels = lablesAndDatasets.labels;
      chartRef.current.data.datasets = lablesAndDatasets.datasets;
      chartRef.current.options = chartOptions;
      chartRef.current.update();
    } else {
      const lablesAndDatasets = genLabelsAndDatasets(chartCategory, readings, tank);
      const ctx = document.getElementById('line-chart') as HTMLCanvasElement;
      if (ctx) {
        chartRef.current = new Chart(ctx, {
          type: 'line',
          data: {
            labels: lablesAndDatasets.labels,
            datasets: lablesAndDatasets.datasets,
          },
          options: chartOptions,
        });
      }
    }
  }, [readings, tank, chartCategory]);

  const onChangeCategory = (chartName: string) => {
    const category = appConfig.chartCategories.find((x) => x.name === chartName);
    if (category) {
      setChartCategory(category);
    }
  };

  return (
    <div style={{ height: '100%' }}>
      <div className='topBar'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            flexWrap: 'wrap',
            gap: 10,
          }}
        >
          <div>
            <TabList
              selectedValue={chartCategory.name}
              onTabSelect={(_, d) => onChangeCategory(d.value as string)}
            >
              {
                appConfig.chartCategories.map(
                  (category) => (
                    <Tab
                      key={category.name}
                      value={category.name}
                      icon={<BetterIcon name={category.icon as BetterIconName} />}
                    >
                      <Text size={400} weight='bold'>{category.name}</Text>
                    </Tab>
                  ),
                )
              }
            </TabList>
          </div>
          <div style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 10,
          }}>
            <Text weight='semibold'>From</Text>
            <DatePicker
              allowTextInput
              placeholder="Select a start date..."
              value={strToJSDate(startDate)}
              onSelectDate={(selectedDate) => onChangeStartDate(selectedDate?.toISOString())}
              style={{ width: 170 }}
            />
            <Text weight='semibold'>To</Text>
            <DatePicker
              allowTextInput
              placeholder="Select an end date..."
              value={strToJSDate(endDate)}
              onSelectDate={(selectedDate) => onChangeEndDate(selectedDate?.toISOString())}
              style={{ width: 170 }}
            />
            <Button onClick={onFitlerReadings}>Update</Button>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          padding: 20,
          paddingTop: 0,
          paddingRight: 20,
          position: 'relative',
        }}
        className='chart'
      >
        <canvas id="line-chart" />
        <div className='readingCount'>
          <IconInfo size='small' info={`The period has ${readings.length} readings`} />
        </div>
      </div>
    </div>
  );
};

export default ReadingChart;
