import * as React from 'react';
import { VCaddy, VClinic, VTank } from '../../../services';
import appConfig from '../../../config.app';
import Message from '../../../components/message';
import StatWidget from '../../../components/statWidget';
import { mapToWidgetPropsList } from '../../../common/helper';
import { ILoadingStatus } from '../../../common/models';
import TankMute from './tankMute';

interface TankStatsProps {
  clinic?: VClinic | null;
  tank?: VTank | null;
  caddy?: VCaddy | null;
  status?: ILoadingStatus | null;
  onMute: () => void,
  onUnmute: () => void,
}

const TankStats: React.FC<TankStatsProps> = ({
  clinic,
  tank,
  caddy,
  status,
  onMute,
  onUnmute,
}) => {
  if (status === 'loading') {
    return <Message
      message=''
      status='loading'
    />;
  }
  if (!tank) {
    return <Message
      message={appConfig.errMsg.NOT_FOUND_TANK}
      status='error'
    />;
  }

  const widgetPropsList = mapToWidgetPropsList(tank, caddy);
  if (!widgetPropsList.length) {
    return <Message
      message={appConfig.errMsg.NOT_RECEIVED_READINGS}
      status='error'
    />;
  }

  return (
    <div style={{
      height: '100%',
      paddingRight: 140,
      position: 'relative',
    }}>
      <div style={{
        padding: 20,
        paddingRight: 0,
        height: '100%',
        whiteSpace: 'nowrap',
        overflowX: 'auto',
      }}>
        {
          widgetPropsList.map((item) => (
            <StatWidget
              key={item.title}
              title={item.title}
              desc={item.desc}
              descMore={item.descMore}
              value={item.value}
              unit={item.unit}
              icon={item.icon}
              status={item.status}
              statusIcon={item.statusIcon}
              color={item.color}
            />
          ))
        }
      </div>
      <div style={{
        position: 'absolute',
        width: 140,
        height: '100%',
        top: 0,
        right: 0,
      }}>
        <TankMute
          clinic={clinic}
          tank={tank}
          caddy={caddy}
          onMute={onMute}
          onUnmute={onUnmute}
        />
      </div>
    </div>
  );
};

export default TankStats;
