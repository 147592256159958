/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VUser
 */
export interface VUser {
    /**
     * Name of the display (Only useful for displays)
     * @type {string}
     * @memberof VUser
     */
    name: string;
    /**
     * First name of the user
     * @type {string}
     * @memberof VUser
     */
    firstName: string;
    /**
     * Surname of the user
     * @type {string}
     * @memberof VUser
     */
    surname: string;
    /**
     * Email of the user
     * @type {string}
     * @memberof VUser
     */
    email: string;
    /**
     * The user is an admin or not
     * @type {boolean}
     * @memberof VUser
     */
    isSuperAdmin: boolean;
    /**
     * The user is a display or not
     * @type {boolean}
     * @memberof VUser
     */
    isDisplay: boolean;
    /**
     * The user is active or not
     * @type {boolean}
     * @memberof VUser
     */
    active: boolean;
    /**
     * User ID
     * @type {string}
     * @memberof VUser
     */
    id: string;
}

/**
 * Check if a given object implements the VUser interface.
 */
export function instanceOfVUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "surname" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "isSuperAdmin" in value;
    isInstance = isInstance && "isDisplay" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function VUserFromJSON(json: any): VUser {
    return VUserFromJSONTyped(json, false);
}

export function VUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): VUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'email': json['email'],
        'isSuperAdmin': json['isSuperAdmin'],
        'isDisplay': json['isDisplay'],
        'active': json['active'],
        'id': json['id'],
    };
}

export function VUserToJSON(value?: VUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'firstName': value.firstName,
        'surname': value.surname,
        'email': value.email,
        'isSuperAdmin': value.isSuperAdmin,
        'isDisplay': value.isDisplay,
        'active': value.active,
        'id': value.id,
    };
}

