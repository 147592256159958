import * as React from 'react';
import { Card, Text } from '@fluentui/react-components';
import { Link } from 'react-router-dom';
import { VTank } from '../../../services';
import BadgeGroup from '../../../components/badgeGroup';
import { mapToBadgePropsList } from '../../../common/helper';
import { lightThemeColors } from '../../../common/themes';
import tankImg from '../../../assets/images/tank.png';

const TankCard: React.FC<{
  tank: VTank,
}> = ({ tank }) => (
  <Link to={`/tank-dashboard/${tank.id}`} style={{ textDecoration: 'none' }}>
    <Card
      style={{
        display: 'flex',
        width: 220,
        height: 90,
        margin: '10px',
        borderRadius: 8,
        backgroundImage: `url(${tankImg})`,
        backgroundPosition: 'right -20px bottom -10px',
        backgroundSize: '80px 80px',
        backgroundRepeat: 'no-repeat',
        transition: 'all .1s ease-in-out',
      }}
    >
      <div style={{
        textAlign: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}>
        <Text
          weight='bold'
          size={400}
          style={{
            color: tank.active ? '' : lightThemeColors.unavailable,
            whiteSpace: 'nowrap',
          }}
        >
          {tank.name}
        </Text>
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start' }}>
        <BadgeGroup badges={mapToBadgePropsList(tank)} />
      </div>
    </Card>
  </Link>
);

export default TankCard;
