/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  useId,
  Text,
  useToastController,
  ToastTitle,
  Toast,
  Card,
  Switch,
  Button,
  Toaster,
  Spinner,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  DialogActions,
  Label,
  InfoLabel,
} from '@fluentui/react-components';
import { useAppSelector, useAppDispatch } from '../../hook';
import {
  addTank,
  updateTank,
  changeTankActive,
  changeTankCaddyId,
  changeTankCapacity,
  changeTankManufacturer,
  changeTankMaxEvaporationRate,
  changeTankMaxWeight,
  changeTankMinEvaporationRate,
  changeTankMinWeight,
  changeTankModel,
  changeTankName,
  changeTankSerial,
  changeTankWeightLossCriticalRate,
  changeTankWeightLossFastRate,
  changeTankApexResetWeight,
  reset,
  fetchAllByClinicId,
  fetchAllByTankId,
} from './_slice';
import { Result } from '../../common/models';
import Layout1 from '../../layouts/layout1';
import SelectBar from '../../components/selectBar';
import Breadcrumbs from '../../components/breadcrumbs';
import Message from '../../components/message';
import appConfig from '../../config.app';
import LabelInput from '../../components/labelInput';
import apiServices from '../../services';

interface RouteParams {
  tankId: string;
  clinicId: string;
  [key: string]: string;
}

interface IManageTankProps {
  isUpdate: boolean;
}

const labelInputStyle = {
  width: '33%',
  padding: 10,
};

const TankManage: React.FC<IManageTankProps> = ({ isUpdate }) => {
  const tankState = useAppSelector((state) => state.tankManage);
  const dispatch = useAppDispatch();
  const toasterId = useId('toaster');
  const { dispatchToast } = useToastController(toasterId);
  const { tankId, clinicId } = useParams<RouteParams>();

  const refreshTank = () => {
    dispatch(reset(true));
    if (clinicId) {
      dispatch(fetchAllByClinicId({ clinicId }));
    } else if (tankId) {
      dispatch(fetchAllByTankId({ tankId }));
    }
  };

  useEffect(() => {
    refreshTank();
  }, [tankId, clinicId]);

  const showToast = (result: Result) => {
    dispatchToast(
      <Toast>
        <ToastTitle>{ result.message }</ToastTitle>
      </Toast>,
      {
        timeout: appConfig.toastTimeoutSeconds * 1000,
        intent: result.result ? 'success' : 'warning',
      },
    );
  };

  const onSave = async () => {
    if (isUpdate) { // For updating
      const dispatchResult = await dispatch(updateTank());
      const ret = dispatchResult.payload as Result;
      if (dispatchResult) showToast(ret);
      if (ret.result) refreshTank(); // Only refresh for successful operation
    } else { // For adding
      const dispatchResult = await dispatch(addTank());
      const ret = dispatchResult.payload as Result;
      if (dispatchResult) showToast(ret);
      if (ret.result) dispatch(reset(false)); // Only refresh for successful operation
    }
  };

  if (tankState.error) {
    return (
      <Layout1
        breadcrumbsSlot={
          <Breadcrumbs
            breadcrumbs={[{ label: 'Clinics', path: '/clinics' }]}
            title={isUpdate ? 'Manage Tank' : 'Add Tank'}
          />
        }
      >
        <Message message={tankState.error} status='error' />
      </Layout1>
    );
  }

  const assignCaddy = () => <>
    {
      tankState.tankInput?.caddyId
        ? <Card style={{ padding: 20 }}>
            <div style={{
              display: 'flex',
            }}>
              <div style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
              }}>
                <Text weight="bold" size={400}>
                  Assigned Caddy: {
                    tankState.caddies.find(
                      (caddy) => caddy.id === tankState.tankInput.caddyId,
                    )?.serial
                  }
                </Text>
              </div>
              <div style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
                <Dialog>
                  <DialogTrigger disableButtonEnhancement>
                    <Button>Unassign Caddy</Button>
                  </DialogTrigger>
                  <DialogSurface>
                    <DialogBody>
                      <DialogTitle>Unassign Caddy</DialogTitle>
                      <DialogContent>
                        Are you sure unassign the caddy from the tank?
                      </DialogContent>
                      <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                          <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                          <Button appearance="primary" onClick={() => dispatch(changeTankCaddyId(''))}>Confirm</Button>
                        </DialogTrigger>
                      </DialogActions>
                    </DialogBody>
                  </DialogSurface>
                </Dialog>
              </div>
            </div>
          </Card> : <SelectBar
            title='Assigned Caddy'
            placeholder='Select a caddy for this tank'
            options={tankState.caddies.map((x) => ({ id: x.id, label: x.serial }))}
            selectedId={tankState.tankInput?.caddyId || ''}
            onChange={(id) => dispatch(changeTankCaddyId(id))}
            noneOption='No caddy selected'
            defaultValue={
              tankState.caddies.find((
                caddy,
              ) => caddy.id === tankState.tankInput.caddyId)?.serial
            }
          />
    }</>;

  return (
    <Layout1
      breadcrumbsSlot={
        isUpdate
          ? <Breadcrumbs
              breadcrumbs={[
                { label: 'Clinics', path: '/clinics' },
                { label: tankState.clinic ? tankState.clinic.name : 'Clinic', path: `/clinic-dashboard/${tankState.clinicId}` },
                { label: tankState.tank ? tankState.tank.name : 'Tank', path: `/tank-dashboard/${tankState.tank?.id}` },
              ]}
              title='Manage Tank'
            />
          : <Breadcrumbs
              breadcrumbs={[
                { label: 'Clinics', path: '/clinics' },
                { label: tankState.clinic ? tankState.clinic.name : 'Clinic', path: `/clinic-dashboard/${tankState.clinicId}` },
              ]}
              title='Add Tank'
            />
      }
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
        gap: 20,
      }}>
        <div>
          <Text weight="bold" size={400}>
            {isUpdate ? 'Manage Tank' : 'Add Tank'}
          </Text>
        </div>
        <Card style={{
          padding: 20,
          overflow: 'auto',
        }}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={labelInputStyle}>
              <LabelInput
                id='name'
                label='Name *'
                value={tankState.tankInput.name}
                placeholder={appConfig.message.SERIAL_PLACEHOLDER.replace('{0}', appConfig.serialMaxLen.toString())}
                onChange={(newValue) => dispatch(changeTankName(newValue))}
                disabled={isUpdate && !tankState.tank}
                maxLength={appConfig.serialMaxLen}
              />
            </div>
            <div style={labelInputStyle}>
              <LabelInput
                id='serial'
                label='Serial'
                value={tankState.tankInput.serial}
                placeholder={appConfig.message.NAME_PLACEHOLDER.replace('{0}', appConfig.nameMaxLen.toString())}
                onChange={(newValue) => dispatch(changeTankSerial(newValue))}
                disabled={isUpdate && !tankState.tank}
                maxLength={appConfig.nameMaxLen}
              />
            </div>
            <div style={labelInputStyle}>
              <LabelInput
                id='manufacturer'
                label='Manufacturer'
                value={tankState.tankInput.manufacturer}
                placeholder={appConfig.message.NAME_PLACEHOLDER.replace('{0}', appConfig.nameMaxLen.toString())}
                onChange={(newValue) => dispatch(changeTankManufacturer(newValue))}
                disabled={isUpdate && !tankState.tank}
                maxLength={appConfig.nameMaxLen}
              />
            </div>
            <div style={labelInputStyle}>
              <LabelInput
                id='model'
                label='Model'
                value={tankState.tankInput.modelType}
                placeholder={appConfig.message.NAME_PLACEHOLDER.replace('{0}', appConfig.nameMaxLen.toString())}
                onChange={(newValue) => dispatch(changeTankModel(newValue))}
                disabled={isUpdate && !tankState.tank}
                maxLength={appConfig.nameMaxLen}
              />
            </div>
            <div style={labelInputStyle}>
              <LabelInput
                id='tankCapacity'
                label='Capacity (kg)'
                value={tankState.tankInput.capacity}
                placeholder={appConfig.message.WEIGHT_PLACEHOLDER}
                onChange={(newValue) => dispatch(changeTankCapacity(newValue))}
                disabled={isUpdate && !tankState.tank}
                type='number'
              />
            </div>
            <div style={labelInputStyle}>
              <LabelInput
                id='minWeight'
                label='Min Safe Weight (kg) *'
                value={tankState.tankInput.minWeight}
                placeholder={appConfig.message.WEIGHT_PLACEHOLDER}
                onChange={(newValue) => dispatch(changeTankMinWeight(newValue))}
                disabled={isUpdate && !tankState.tank}
                type='number'
              />
            </div>
            <div style={labelInputStyle}>
              <LabelInput
                id='maxWeight'
                label='Max Safe Weight (kg) *'
                value={tankState.tankInput.maxWeight}
                placeholder={appConfig.message.WEIGHT_PLACEHOLDER}
                onChange={(newValue) => dispatch(changeTankMaxWeight(newValue))}
                disabled={isUpdate && !tankState.tank}
                type='number'
              />
            </div>
            <div style={labelInputStyle}>
              <LabelInput
                id='apexResetWeight'
                label='Apex Reset Threshold (kg) *'
                value={tankState.tankInput.apexResetWeight}
                placeholder={appConfig.message.WEIGHT_PLACEHOLDER}
                onChange={(newValue) => dispatch(changeTankApexResetWeight(newValue))}
                disabled={isUpdate && !tankState.tank}
                type='number'
              />
            </div>
            <div style={labelInputStyle}>
              <LabelInput
                id='tankMinEvapRate'
                label='Min Evaporation Rate (kg/h) *'
                value={tankState.tankInput.minEvaporationRate}
                placeholder={appConfig.message.MIN_RATE_PLACEHOLDER}
                onChange={(newValue) => dispatch(changeTankMinEvaporationRate(newValue))}
                disabled={isUpdate && !tankState.tank}
                type='number'
              />
            </div>
            <div style={labelInputStyle}>
              <LabelInput
                id='tankMaxEvapRate'
                label='Max Evaporation Rate (kg/h) *'
                value={tankState.tankInput.maxEvaporationRate}
                placeholder={appConfig.message.RATE_PLACEHOLDER}
                onChange={(newValue) => dispatch(changeTankMaxEvaporationRate(newValue))}
                disabled={isUpdate && !tankState.tank}
                type='number'
              />
            </div>
            <div style={labelInputStyle}>
              <LabelInput
                id='weightLossFastRate'
                label='Weight Loss Fast Rate (kg/h) *'
                value={tankState.tankInput.weightLossFastRate}
                placeholder={appConfig.message.RATE_PLACEHOLDER}
                onChange={(newValue) => dispatch(changeTankWeightLossFastRate(newValue))}
                disabled={isUpdate && !tankState.tank}
                type='number'
              />
            </div>
            <div style={labelInputStyle}>
              <LabelInput
                id='weightLossCriticalRate'
                label='Weight Loss Critical Rate (kg/h) *'
                value={tankState.tankInput.weightLossCriticalRate}
                placeholder={appConfig.message.RATE_PLACEHOLDER}
                onChange={(newValue) => dispatch(changeTankWeightLossCriticalRate(newValue))}
                disabled={isUpdate && !tankState.tank}
                type='number'
              />
            </div>
            <div style={labelInputStyle}>
            <Label
              size='medium'
              weight='semibold'
              style={{ display: 'flex', fontWeight: 'bold' }}
            >
              Active
            </Label>
              <div>
                <Switch
                  checked={tankState.tankInput.active}
                  onChange={(e) => dispatch(changeTankActive(e.target.checked))}
                  label=""
                />
              </div>
            </div>
          </div>
        </Card>
        { (apiServices.isSuperAdmin() || apiServices.isSiteAdmin()) && assignCaddy() }
        <div style={{ display: 'flex' }}>
          <div style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 6,
          }}>
            {
              tankState.tankInput?.confTime
                ? <><Spinner size='tiny' /> { appConfig.message.CONF_SYNCING }</>
                : <InfoLabel info={appConfig.message.TIP_MANAGE_TANK}></InfoLabel>
            }
          </div>
          <div>
            {
              // TODO: This needs to be extended to include the other config values?
              // E.g. weightLossFastRateand weightLossCriticalRate?
            }
            <Button
              onClick={onSave}
              appearance="primary"
              disabled={!(
                (tankState.clinicId
                  && tankState.tankInput.name
                  && tankState.tankInput.minWeight
                  && tankState.tankInput.maxWeight
                  && tankState.tankInput.minEvaporationRate
                  && tankState.tankInput.maxEvaporationRate
                  && tankState.tankInput.weightLossFastRate
                  && tankState.tankInput.weightLossCriticalRate
                  && tankState.tankInput.apexResetWeight
                  && typeof tankState.tankInput.active === 'boolean'
                  && tankState.savable)
                || (!isUpdate
                  && tankState.tankInput.name
                  && tankState.tankInput.minWeight
                  && tankState.tankInput.maxWeight
                  && tankState.tankInput.minEvaporationRate
                  && tankState.tankInput.maxEvaporationRate
                  && tankState.tankInput.weightLossFastRate
                  && tankState.tankInput.weightLossCriticalRate
                  && tankState.tankInput.apexResetWeight
                  && typeof tankState.tankInput.active === 'boolean'
                )
              )}
            >
              Save
            </Button>
          </div>
          <div>
            {
              // TODO: If setting `overflow: visible` for Card above,
              // the Toaster (postion: absolute, height: 1) will
              // have a tricky issue: two scrollbars, so FluentUI
              // Toaster is not a good compoenent.
            }
            <Toaster toasterId={toasterId} />
          </div>
        </div>
      </div>
    </Layout1>
  );
};

export default TankManage;
