/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VUpdateCaddyRequestParams
 */
export interface VUpdateCaddyRequestParams {
    /**
     * Serial of the caddy
     * @type {string}
     * @memberof VUpdateCaddyRequestParams
     */
    serial?: string;
    /**
     * Clinic Id the caddy belongs to
     * @type {string}
     * @memberof VUpdateCaddyRequestParams
     */
    clinicId?: string;
    /**
     * Firmware version Id of the caddy
     * @type {string}
     * @memberof VUpdateCaddyRequestParams
     */
    targetFirmwareVersionId?: string;
    /**
     * Low battery threshold
     * @type {number}
     * @memberof VUpdateCaddyRequestParams
     */
    lowBatteryThreshold?: number;
    /**
     * Critical battery threshold
     * @type {number}
     * @memberof VUpdateCaddyRequestParams
     */
    criticalBatteryThreshold?: number;
}

/**
 * Check if a given object implements the VUpdateCaddyRequestParams interface.
 */
export function instanceOfVUpdateCaddyRequestParams(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VUpdateCaddyRequestParamsFromJSON(json: any): VUpdateCaddyRequestParams {
    return VUpdateCaddyRequestParamsFromJSONTyped(json, false);
}

export function VUpdateCaddyRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VUpdateCaddyRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serial': !exists(json, 'serial') ? undefined : json['serial'],
        'clinicId': !exists(json, 'clinicId') ? undefined : json['clinicId'],
        'targetFirmwareVersionId': !exists(json, 'targetFirmwareVersionId') ? undefined : json['targetFirmwareVersionId'],
        'lowBatteryThreshold': !exists(json, 'lowBatteryThreshold') ? undefined : json['lowBatteryThreshold'],
        'criticalBatteryThreshold': !exists(json, 'criticalBatteryThreshold') ? undefined : json['criticalBatteryThreshold'],
    };
}

export function VUpdateCaddyRequestParamsToJSON(value?: VUpdateCaddyRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serial': value.serial,
        'clinicId': value.clinicId,
        'targetFirmwareVersionId': value.targetFirmwareVersionId,
        'lowBatteryThreshold': value.lowBatteryThreshold,
        'criticalBatteryThreshold': value.criticalBatteryThreshold,
    };
}

