import * as React from 'react';
import BetterBadge, { IBetterBadgeProps } from './betterBadge';

const BadgeGroup: React.FC<{
  badges: IBetterBadgeProps[],
  extended?: boolean,
}> = ({
  badges,
  extended,
}) => (
  <div style={{
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 4,
  }}>
    {
      badges.map((badge) => (
        <BetterBadge
          key={`${badge.label}-${badge.icon}-${badge.color}`}
          icon={badge.icon}
          color={badge.color}
          label={badge.label}
          extended={extended || badge.extended}
        />
      ))
    }
  </div>
);

export default BadgeGroup;
