import * as React from 'react';
import { Text, Button } from '@fluentui/react-components';
import StatusBox from '../../../components/statusBox';
import { IDashboardFilter } from '../../../common/models';

interface DashboardFilterProps {
  total: number,
  statusFilter: IDashboardFilter;
  onToggleFilter: (filterKey: string) => void;
  onClearFilter: () => void;
}

const TankFilter: React.FC<DashboardFilterProps> = ({
  total,
  statusFilter,
  onToggleFilter,
  onClearFilter,
}) => (
  <div>
    <div style={{ padding: 10, textAlign: 'center' }}>
      <Text weight="bold" >
        Tank Status
      </Text>
    </div>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 10,
      paddingBottom: 20,
    }}>
      {
        Object.keys(statusFilter).map((key) => (
          <StatusBox
            key={ key }
            title={ statusFilter[key].name }
            color={ statusFilter[key].color }
            count={ statusFilter[key].count }
            isSelected={ statusFilter[key].isSelected as boolean }
            onToggle={ () => onToggleFilter(key) }
          />
        ))
      }
      <Button
        disabled={!Object.values(statusFilter).some((x) => x.isSelected)}
        onClick={onClearFilter}
      >
        Clear Filters
      </Button>
      <Text>Total: {total} Tanks</Text>
    </div>
  </div>
);

export default TankFilter;
