import * as React from 'react';
import { Text } from '@fluentui/react-components';
import { lightThemeColors } from '../common/themes';

interface StatusBoxProps {
  title: string;
  color: string;
  count: number;
  isSelected: boolean;
  onToggle: () => void;
}

const StatusBox: React.FC<StatusBoxProps> = ({
  title, color, count, isSelected, onToggle,
}) => (
  <div
    style={{
      backgroundColor: isSelected
        ? lightThemeColors.borderColor
        : lightThemeColors.backgroundColor,
      padding: 8,
      borderRadius: 8,
      cursor: 'pointer',
      boxShadow: isSelected ? 'inset 0px 0px 5px rgba(0, 0, 0, 0.5)' : '0px 0px 1px rgba(0, 0, 0, 0.3)',
    }}
    onClick={onToggle}
  >
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        height: 30,
      }}
    >
      <Text weight="semibold" align="center">
        {title}
      </Text>
    </div>
    <div
      style={{
        backgroundColor: count ? color : lightThemeColors.disabled,
        padding: 10,
        borderRadius: 8,
        minWidth: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 38,
      }}
    >
      <Text style={{ color: 'white', fontSize: 24, fontWeight: 'bolder' }}>
        {count}
      </Text>
    </div>
  </div>
);

export default StatusBox;
