/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VReading
 */
export interface VReading {
    /**
     * Stringified configuration
     * @type {string}
     * @memberof VReading
     */
    confString: string;
    /**
     * Globally uniqiue caddy serial
     * @type {string}
     * @memberof VReading
     */
    caddySerial: string;
    /**
     * Caddy firmware version
     * @type {string}
     * @memberof VReading
     */
    caddyFirmwareVersion: string;
    /**
     * Caddy battery voltage (V)
     * @type {number}
     * @memberof VReading
     */
    caddyBatteryVoltage: number;
    /**
     * Caddy surface temperature (C)
     * @type {number}
     * @memberof VReading
     */
    caddyTemperature: number;
    /**
     * Caddy network signal strength (0 ~ 1)
     * @type {number}
     * @memberof VReading
     */
    caddySignalStrength: number;
    /**
     * Tank weight (kg)
     * @type {number}
     * @memberof VReading
     */
    tankWeight: number;
    /**
     * Tank evaporation rate (kg/h)
     * @type {number}
     * @memberof VReading
     */
    tankEvaporationRate: number;
    /**
     * Cadd high CAL weight (kg) for debugging
     * @type {number}
     * @memberof VReading
     */
    caddyHighCalWeight?: number;
    /**
     * Caddy low CAL ADC value for debugging
     * @type {number}
     * @memberof VReading
     */
    caddyLowCalAdc?: number;
    /**
     * Caddy high CAL ADC value for debugging
     * @type {number}
     * @memberof VReading
     */
    caddyHighCalAdc?: number;
    /**
     * Tank raw weight ADC
     * @type {number}
     * @memberof VReading
     */
    tankRawWeightAdc?: number;
    /**
     * Tank raw weight (kg)
     * @type {number}
     * @memberof VReading
     */
    tankRawWeight?: number;
    /**
     * Tank dithered weight (kg)
     * @type {number}
     * @memberof VReading
     */
    tankDitheredWeight?: number;
    /**
     * Tank weight loss rate (fast) (kg/h)
     * @type {number}
     * @memberof VReading
     */
    tankWeightLossFast?: number;
    /**
     * Tank weight loss rate (critical) (kg/h)
     * @type {number}
     * @memberof VReading
     */
    tankWeightLossCritical?: number;
    /**
     * Caddy battery voltage is low or not
     * @type {boolean}
     * @memberof VReading
     */
    isCaddyBatteryLow: boolean;
    /**
     * Caddy battery voltage is critical (very low) or not
     * @type {boolean}
     * @memberof VReading
     */
    isCaddyBatteryCritical: boolean;
    /**
     * Caddy temperature is low or not
     * @type {boolean}
     * @memberof VReading
     */
    isCaddyTemperatureLow?: boolean;
    /**
     * Tank weight is low or not
     * @type {boolean}
     * @memberof VReading
     */
    isTankWeightLow: boolean;
    /**
     * Tank weight is high or not
     * @type {boolean}
     * @memberof VReading
     */
    isTankWeightHigh: boolean;
    /**
     * Tank evaporation rate is low or not
     * @type {boolean}
     * @memberof VReading
     */
    isTankEvaporationRateLow: boolean;
    /**
     * Tank evaporation rate is high or not
     * @type {boolean}
     * @memberof VReading
     */
    isTankEvaporationRateHigh: boolean;
    /**
     * Tank weight loss is fast or not
     * @type {boolean}
     * @memberof VReading
     */
    isTankWeightLossFast: boolean;
    /**
     * Tank weight loss is critical (very fast) or not
     * @type {boolean}
     * @memberof VReading
     */
    isTankWeightLossCritical: boolean;
    /**
     * Tank is peturbed or not
     * @type {boolean}
     * @memberof VReading
     */
    isTankPeturbed?: boolean;
    /**
     * Caddy is muted or not
     * @type {boolean}
     * @memberof VReading
     */
    isMuted: boolean;
    /**
     * Creation time of the reading
     * @type {string}
     * @memberof VReading
     */
    time: string;
    /**
     * Reading ID
     * @type {string}
     * @memberof VReading
     */
    id: string;
    /**
     * Clinic Id the reading is related to
     * @type {string}
     * @memberof VReading
     */
    clinicId: string;
    /**
     * Tank Id the reading is related to
     * @type {string}
     * @memberof VReading
     */
    tankId: string;
    /**
     * Caddy Id the reading is related to
     * @type {string}
     * @memberof VReading
     */
    caddyId: string;
}

/**
 * Check if a given object implements the VReading interface.
 */
export function instanceOfVReading(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "confString" in value;
    isInstance = isInstance && "caddySerial" in value;
    isInstance = isInstance && "caddyFirmwareVersion" in value;
    isInstance = isInstance && "caddyBatteryVoltage" in value;
    isInstance = isInstance && "caddyTemperature" in value;
    isInstance = isInstance && "caddySignalStrength" in value;
    isInstance = isInstance && "tankWeight" in value;
    isInstance = isInstance && "tankEvaporationRate" in value;
    isInstance = isInstance && "isCaddyBatteryLow" in value;
    isInstance = isInstance && "isCaddyBatteryCritical" in value;
    isInstance = isInstance && "isTankWeightLow" in value;
    isInstance = isInstance && "isTankWeightHigh" in value;
    isInstance = isInstance && "isTankEvaporationRateLow" in value;
    isInstance = isInstance && "isTankEvaporationRateHigh" in value;
    isInstance = isInstance && "isTankWeightLossFast" in value;
    isInstance = isInstance && "isTankWeightLossCritical" in value;
    isInstance = isInstance && "isMuted" in value;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "clinicId" in value;
    isInstance = isInstance && "tankId" in value;
    isInstance = isInstance && "caddyId" in value;

    return isInstance;
}

export function VReadingFromJSON(json: any): VReading {
    return VReadingFromJSONTyped(json, false);
}

export function VReadingFromJSONTyped(json: any, ignoreDiscriminator: boolean): VReading {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'confString': json['confString'],
        'caddySerial': json['caddySerial'],
        'caddyFirmwareVersion': json['caddyFirmwareVersion'],
        'caddyBatteryVoltage': json['caddyBatteryVoltage'],
        'caddyTemperature': json['caddyTemperature'],
        'caddySignalStrength': json['caddySignalStrength'],
        'tankWeight': json['tankWeight'],
        'tankEvaporationRate': json['tankEvaporationRate'],
        'caddyHighCalWeight': !exists(json, 'caddyHighCalWeight') ? undefined : json['caddyHighCalWeight'],
        'caddyLowCalAdc': !exists(json, 'caddyLowCalAdc') ? undefined : json['caddyLowCalAdc'],
        'caddyHighCalAdc': !exists(json, 'caddyHighCalAdc') ? undefined : json['caddyHighCalAdc'],
        'tankRawWeightAdc': !exists(json, 'tankRawWeightAdc') ? undefined : json['tankRawWeightAdc'],
        'tankRawWeight': !exists(json, 'tankRawWeight') ? undefined : json['tankRawWeight'],
        'tankDitheredWeight': !exists(json, 'tankDitheredWeight') ? undefined : json['tankDitheredWeight'],
        'tankWeightLossFast': !exists(json, 'tankWeightLossFast') ? undefined : json['tankWeightLossFast'],
        'tankWeightLossCritical': !exists(json, 'tankWeightLossCritical') ? undefined : json['tankWeightLossCritical'],
        'isCaddyBatteryLow': json['isCaddyBatteryLow'],
        'isCaddyBatteryCritical': json['isCaddyBatteryCritical'],
        'isCaddyTemperatureLow': !exists(json, 'isCaddyTemperatureLow') ? undefined : json['isCaddyTemperatureLow'],
        'isTankWeightLow': json['isTankWeightLow'],
        'isTankWeightHigh': json['isTankWeightHigh'],
        'isTankEvaporationRateLow': json['isTankEvaporationRateLow'],
        'isTankEvaporationRateHigh': json['isTankEvaporationRateHigh'],
        'isTankWeightLossFast': json['isTankWeightLossFast'],
        'isTankWeightLossCritical': json['isTankWeightLossCritical'],
        'isTankPeturbed': !exists(json, 'isTankPeturbed') ? undefined : json['isTankPeturbed'],
        'isMuted': json['isMuted'],
        'time': json['time'],
        'id': json['id'],
        'clinicId': json['clinicId'],
        'tankId': json['tankId'],
        'caddyId': json['caddyId'],
    };
}

export function VReadingToJSON(value?: VReading | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'confString': value.confString,
        'caddySerial': value.caddySerial,
        'caddyFirmwareVersion': value.caddyFirmwareVersion,
        'caddyBatteryVoltage': value.caddyBatteryVoltage,
        'caddyTemperature': value.caddyTemperature,
        'caddySignalStrength': value.caddySignalStrength,
        'tankWeight': value.tankWeight,
        'tankEvaporationRate': value.tankEvaporationRate,
        'caddyHighCalWeight': value.caddyHighCalWeight,
        'caddyLowCalAdc': value.caddyLowCalAdc,
        'caddyHighCalAdc': value.caddyHighCalAdc,
        'tankRawWeightAdc': value.tankRawWeightAdc,
        'tankRawWeight': value.tankRawWeight,
        'tankDitheredWeight': value.tankDitheredWeight,
        'tankWeightLossFast': value.tankWeightLossFast,
        'tankWeightLossCritical': value.tankWeightLossCritical,
        'isCaddyBatteryLow': value.isCaddyBatteryLow,
        'isCaddyBatteryCritical': value.isCaddyBatteryCritical,
        'isCaddyTemperatureLow': value.isCaddyTemperatureLow,
        'isTankWeightLow': value.isTankWeightLow,
        'isTankWeightHigh': value.isTankWeightHigh,
        'isTankEvaporationRateLow': value.isTankEvaporationRateLow,
        'isTankEvaporationRateHigh': value.isTankEvaporationRateHigh,
        'isTankWeightLossFast': value.isTankWeightLossFast,
        'isTankWeightLossCritical': value.isTankWeightLossCritical,
        'isTankPeturbed': value.isTankPeturbed,
        'isMuted': value.isMuted,
        'time': value.time,
        'id': value.id,
        'clinicId': value.clinicId,
        'tankId': value.tankId,
        'caddyId': value.caddyId,
    };
}

