/* tslint:disable */
/* eslint-disable */
/**
 * WeighMed Backend APIs - OpenAPI 3.0
 * You can find out more about Swagger at [https://editor.swagger.io/](https://editor.swagger.io/).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VAddTankRequestParams
 */
export interface VAddTankRequestParams {
    /**
     * Name of the tank
     * @type {string}
     * @memberof VAddTankRequestParams
     */
    name: string;
    /**
     * Serial of the tank
     * @type {string}
     * @memberof VAddTankRequestParams
     */
    serial?: string;
    /**
     * Manufacturer of the tank
     * @type {string}
     * @memberof VAddTankRequestParams
     */
    manufacturer?: string;
    /**
     * Model of the tank
     * @type {string}
     * @memberof VAddTankRequestParams
     */
    modelType?: string;
    /**
     * Capacity (L) of the tank
     * @type {number}
     * @memberof VAddTankRequestParams
     */
    capacity?: number;
    /**
     * Empty weight (kg) of the tank
     * @type {number}
     * @memberof VAddTankRequestParams
     */
    minWeight: number;
    /**
     * Full weight (kg) of the tank
     * @type {number}
     * @memberof VAddTankRequestParams
     */
    maxWeight: number;
    /**
     * Minimum evaporation rate (kg/h) of the tank
     * @type {number}
     * @memberof VAddTankRequestParams
     */
    minEvaporationRate: number;
    /**
     * Maximum evaporation rate (kg/h) of the tank
     * @type {number}
     * @memberof VAddTankRequestParams
     */
    maxEvaporationRate: number;
    /**
     * Fast weight loss rate of tank for elevated alarming
     * @type {number}
     * @memberof VAddTankRequestParams
     */
    weightLossFastRate: number;
    /**
     * Critical weight loss rate of tank for elevated alarming
     * @type {number}
     * @memberof VAddTankRequestParams
     */
    weightLossCriticalRate: number;
    /**
     * Apex reset weight (kg) threshold
     * @type {number}
     * @memberof VAddTankRequestParams
     */
    apexResetWeight: number;
    /**
     * If current time is less than muteUntilTime, muteCaddy will be set to true in queryConfiguration api
     * @type {string}
     * @memberof VAddTankRequestParams
     */
    muteUntilTime?: string;
    /**
     * Id of clinic the tank belongs to
     * @type {string}
     * @memberof VAddTankRequestParams
     */
    clinicId: string;
    /**
     * Id of caddy the tank is installed
     * @type {string}
     * @memberof VAddTankRequestParams
     */
    caddyId?: string;
    /**
     * Ids of displays
     * @type {Array<string>}
     * @memberof VAddTankRequestParams
     */
    displayIds?: Array<string>;
    /**
     * The tank is active or not
     * @type {boolean}
     * @memberof VAddTankRequestParams
     */
    active: boolean;
}

/**
 * Check if a given object implements the VAddTankRequestParams interface.
 */
export function instanceOfVAddTankRequestParams(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "minWeight" in value;
    isInstance = isInstance && "maxWeight" in value;
    isInstance = isInstance && "minEvaporationRate" in value;
    isInstance = isInstance && "maxEvaporationRate" in value;
    isInstance = isInstance && "weightLossFastRate" in value;
    isInstance = isInstance && "weightLossCriticalRate" in value;
    isInstance = isInstance && "apexResetWeight" in value;
    isInstance = isInstance && "clinicId" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function VAddTankRequestParamsFromJSON(json: any): VAddTankRequestParams {
    return VAddTankRequestParamsFromJSONTyped(json, false);
}

export function VAddTankRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VAddTankRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'serial': !exists(json, 'serial') ? undefined : json['serial'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'modelType': !exists(json, 'modelType') ? undefined : json['modelType'],
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'minWeight': json['minWeight'],
        'maxWeight': json['maxWeight'],
        'minEvaporationRate': json['minEvaporationRate'],
        'maxEvaporationRate': json['maxEvaporationRate'],
        'weightLossFastRate': json['weightLossFastRate'],
        'weightLossCriticalRate': json['weightLossCriticalRate'],
        'apexResetWeight': json['apexResetWeight'],
        'muteUntilTime': !exists(json, 'muteUntilTime') ? undefined : json['muteUntilTime'],
        'clinicId': json['clinicId'],
        'caddyId': !exists(json, 'caddyId') ? undefined : json['caddyId'],
        'displayIds': !exists(json, 'displayIds') ? undefined : json['displayIds'],
        'active': json['active'],
    };
}

export function VAddTankRequestParamsToJSON(value?: VAddTankRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'serial': value.serial,
        'manufacturer': value.manufacturer,
        'modelType': value.modelType,
        'capacity': value.capacity,
        'minWeight': value.minWeight,
        'maxWeight': value.maxWeight,
        'minEvaporationRate': value.minEvaporationRate,
        'maxEvaporationRate': value.maxEvaporationRate,
        'weightLossFastRate': value.weightLossFastRate,
        'weightLossCriticalRate': value.weightLossCriticalRate,
        'apexResetWeight': value.apexResetWeight,
        'muteUntilTime': value.muteUntilTime,
        'clinicId': value.clinicId,
        'caddyId': value.caddyId,
        'displayIds': value.displayIds,
        'active': value.active,
    };
}

